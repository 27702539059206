import React, { Component } from 'react';
import { connect } from 'react-redux';
import withUser from '../../../../store/hoc/withUser';

import Modal from 'react-modal';
import ListItem from 'grommet/components/ListItem';
import Toast from 'grommet/components/Toast';

import Icons from '../../../../components/UI/Icons/Icons';
import TrainersAddEditTrainer from '../TrainersAddEditTrainer/TrainersAddEditTrainer';

import * as actions from '../../../../store/actions/index';

import classes from './SingleTrainer.module.scss';
import { isFullAccessAdminOrTrainer } from '../../../../shared/userFunctions';

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym,
    locations: state.location.locations
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveSingleTrainer: (trainer, gymId, gymsList) => dispatch(actions.removeSingleTrainer(trainer, gymId, gymsList))
  };
};

export class SingleTrainer extends Component {
  state = {
    showConfirm: false,
    showModal: false
  };

  handleTrainerDelete = () => {
    if (!this.state.showConfirm) {
      return this.setState({
        showConfirm: true
      });
    }
    //TODO: delete trainer
    this.props.onRemoveSingleTrainer(
      this.props.trainer,
      this.props.adminFullAccess ? true : this.props.selectedGym._id,
      this.props.gymsList
    );
  };

  handleTrainerEdit = () => {
    this.setState({
      showModal: true
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false
    });
  };

  closeToast = () => {
    this.setState({
      showConfirm: false
    });
  };

  renderNotification = () => {
    if (this.state.showConfirm) {
      return (
        <Toast status="warning" onClose={this.closeToast}>
          Click once again to confirm the deletion of the trainer
        </Toast>
      );
    }
  };

  render() {
    return (
      <>
        {this.renderNotification()}

        <Modal onRequestClose={this.handleModalClose} isOpen={this.state.showModal} className="ss-modal">
          <div className="grommet">
            <TrainersAddEditTrainer
              editTrainer={this.props.trainer}
              closeModal={this.handleModalClose}
              gymsList={this.props.gymsList}
              trainerFullAccess={this.props.trainerFullAccess}
            />
          </div>
        </Modal>

        <ListItem direction="column" align="start" separator="horizontal">
          <div className={classes.singleTrainer}>
            <div className={classes.trainerStatus}>
              <span className={classes.trainerName}>
                {this.props.trainer.name} {this.props.trainer.last_name}
                {isFullAccessAdminOrTrainer(this.props.trainer) && <span> - gyms full access</span>}
              </span>
            </div>

            <Icons edit delete editClick={this.handleTrainerEdit} deleteClick={this.handleTrainerDelete} />
          </div>
        </ListItem>
      </>
    );
  }
}

export default withUser(connect(mapStateToProps, mapDispatchToProps)(SingleTrainer));
