import React, { useState } from 'react';
import ClientTypeFilter from './ClientTypeFilter';
import ClientProductFilter from './ClientProductFilter';
import ClientGymMembershipFilter from './ClientGymMembershipFilter';
import ClientNameFilter from './ClientNameFilter';
import ClientMembershipTypeFilter from './ClientMembershipTypeFilter';
import GymNameFilter from './GymNameFilter';
import ClientStatusesFilter from './ClientStatusesFilter';
import './Filters.css';

const Filters = ({
  classPasses,
  personalTrainings,
  gymMemberships,
  allClientStatuses,
  onFilter,
  membershipProducts,
  gymNameFilter,
  hideStatusFilter,
  membershipType
}) => {
  const [filter, setFilter] = useState({});

  const setProductFilter = (productFilter) => {
    const updatedFilter = {
      ...filter,
      productFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  const setMembershipsStatusFilter = (gymMembershipsStatusFilter) => {
    const updatedFilter = {
      ...filter,
      gymMembershipsStatusFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  const setGymMembershipFilter = (gymMembershipFilter) => {
    const updatedFilter = {
      ...filter,
      gymMembershipFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  const setClassPassFilter = (classPassFilter) => {
    const updatedFilter = {
      ...filter,
      classPassFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  const setPersonalTrainingsFilter = (personalTrainingsFilter) => {
    const updatedFilter = {
      ...filter,
      personalTrainingsFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  const setClientNameFilter = (nameFilter) => {
    const updatedFilter = {
      ...filter,
      nameFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  const setGymNameFilter = (gymNameFilter) => {
    const updatedFilter = {
      ...filter,
      gymNameFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  const setClientStatusesFilter = (statusFilter) => {
    const updatedFilter = {
      ...filter,
      statusFilter
    };
    setFilter(updatedFilter);
    onFilter(updatedFilter);
  };

  return (
    <div className="manage-clients__filters">
      <div className="manage-clients__filters__categories">
        <ClientGymMembershipFilter onFilter={setGymMembershipFilter} filters={gymMemberships} title="Gym Membership" />
        <ClientProductFilter onFilter={setClassPassFilter} filters={classPasses} title="Class Passes" />
        <ClientProductFilter
          onFilter={setPersonalTrainingsFilter}
          filters={personalTrainings}
          title="Personal Training"
        />
      </div>
      <div className="manage-clients__filters__search">
        {!hideStatusFilter && (
          <ClientStatusesFilter onFilter={setClientStatusesFilter} allClientStatuses={allClientStatuses} />
        )}
        {membershipProducts && <ClientTypeFilter onFilter={setProductFilter} />}
        {membershipType && <ClientMembershipTypeFilter onFilter={setMembershipsStatusFilter} />}
        <ClientNameFilter onFilter={setClientNameFilter} />
        {gymNameFilter && <GymNameFilter onFilter={setGymNameFilter} />}
      </div>
    </div>
  );
};

export default Filters;
