import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import QrReader from 'react-qr-reader';
import CheckBox from 'grommet/components/CheckBox';
import Status from 'grommet/components/icons/Status';
import NextIcon from 'grommet/components/icons/base/Next';
import Toast from 'grommet/components/Toast';

import notificationAudio from '../../../brandConfig/assets/notification-admin.mp3';

import classes from './AdminQrScanner.module.scss';

import { returnErrorFromResponse } from '../../../shared/utility';

class AdminQrScanner extends Component {
  state = {
    showPreview: true,
    switchOnScanner: true,
    smallPreview: true,
    error: null
  };

  handleError(error) {
    this.setState({
      error
    });
  }

  handleScan(data) {
    if (data) {
      new Audio(notificationAudio).play();
      this.setState({
        error: null
      });
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.props.history.push(`${this.props.match.url}/clients/${data}`);
    }
  }

  render() {
    let notification = null;
    if (this.state.error) {
      notification = (
        <Toast status="critical">{`QR scanner error: ${returnErrorFromResponse(this.state.error)}`}</Toast>
      );
    }

    let scanner = null;
    if (this.state.switchOnScanner) {
      scanner = (
        <div style={this.state.showPreview ? { display: 'block' } : { display: 'none' }}>
          <QrReader
            delay={1000}
            onError={(err) => this.handleError(err)}
            onScan={(data) => this.handleScan(data)}
            showViewFinder={false}
            style={{ width: '150px', marginRight: '20px' }}
          />
        </div>
      );
    }

    let scannerContainer = (
      <div
        className={classes.smallPreview}
        onClick={() =>
          this.setState({
            smallPreview: !this.state.smallPreview
          })
        }>
        QR SCANNER
        <Status
          style={{ marginLeft: '5px', zIndex: 1000 }}
          value={this.state.error ? 'critical' : this.state.switchOnScanner ? 'ok' : 'disabled'}
        />
        {this.state.switchOnScanner ? (
          <QrReader
            onError={(err) => this.handleError(err)}
            onScan={(data) => this.handleScan(data)}
            showViewFinder={false}
            style={{ display: 'none' }}
          />
        ) : null}
      </div>
    );
    if (!this.state.smallPreview) {
      scannerContainer = (
        <div className={classes.scannerBox}>
          <NextIcon
            className={classes.closeIcon}
            size="xsmall"
            onClick={() =>
              this.setState({
                smallPreview: !this.state.smallPreview
              })
            }
          />

          <div className={classes.scannerContent}>
            {scanner}

            <div className={classes.checkboxContainer}>
              <CheckBox
                className={classes.checkbox}
                disabled={this.state.error}
                label="Scanner"
                toggle={true}
                checked={this.state.switchOnScanner && !this.state.error}
                onChange={() =>
                  this.setState({
                    switchOnScanner: !this.state.switchOnScanner
                  })
                }
              />
              <CheckBox
                label="Preview"
                toggle={true}
                disabled={!this.state.switchOnScanner || this.state.error}
                checked={this.state.showPreview && !this.state.error}
                onChange={() =>
                  this.setState({
                    showPreview: !this.state.showPreview
                  })
                }
              />
            </div>
          </div>
        </div>
      );
    }

    if (this.props.isQRCodeAvailable) {
      return (
        <>
          {notification}
          {scannerContainer}
        </>
      );
    }

    return null;
  }
}

export default withRouter(AdminQrScanner);
