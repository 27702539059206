import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classes from './AddEditVideo.module.scss';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';
import Button from 'grommet/components/Button';
import TextInput from 'grommet/components/TextInput';
import FormCreator from '../../../../components/UI/FormCreator/FormCreator';
import FormField from 'grommet/components/FormField';
import UploadVideo from './UploadVideo';
import axios from '../../../../axios-global';
import Toast from 'grommet/components/Toast';
import Notification from 'grommet/components/Notification';
import { checkValidity, returnErrorTextForField } from '../../../../shared/utility';

const cloneDeep = require('lodash.clonedeep');

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym,

    scheduledClassesListLoading: state.admin.scheduledClassesListLoading,
    scheduledClassesList: state.admin.scheduledClassesList,
    scheduledClassesListError: state.admin.scheduledClassesListError,

    trainersList: state.admin.trainersList,
    trainersListLoading: state.admin.gymUsersListLoading,
    trainersListError: state.admin.gymUsersListError
  };
};
class AddEditVideo extends Component {
  state = {
    scheduledClassDetails: null,
    formIsValid: false,
    formValidated: false,
    allowUserToSave: false,
    error: null,
    videoTitle: '',
    file: {
      title: '',
      url: '',
      name: '',
      type: '',
      size: '',
      createdDate: '',
      createdBy: ''
    },
    videoForm: {},
    editMode: false
  };

  componentDidMount = () => {
    if (this.props.editVideo) {
      this.setState({
        editMode: true,
        allowUserToSave: true
      });
    } else {
      this.setState({
        allowUserToSave: false
      });
    }
    this.getAllCategory();
  };

  getAllCategory = async () => {
    try {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_DEV_ENV_API}`
          : `${process.env.REACT_APP_PROD_ENV_API}`;
      const response = await axios.get(`${baseUrl}/api/video-config/active`);

      if (response.status === 200) {
        this.setState({
          videoConfig: response.data
        });
        this.createForm();
      } else {
        this.showErrorToast('Failed to get categories');
      }
    } catch (error) {
      this.showErrorToast('Failed to get categories');
    }
  };

  createForm = () => {
    let formObj = {};
    this.state.videoConfig.forEach((config) => {
      formObj[config.name.replace(' ', '_')] = {
        label: config.name,
        type: 'select',
        options: config.options,
        placeholder: `Select ${config.name}`,
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      };
    });

    this.setState(
      {
        videoForm: formObj
      },
      () => {
        if (this.state.editMode) {
          this.setExistingValues();
        }
      }
    );
  };

  setExistingValues = () => {
    const copiedForm = cloneDeep(this.state.videoForm);
    this.props.editVideo.videoConfig.forEach((config) => {
      let configKey = Object.keys(config)[0];
      copiedForm[configKey].value = config[configKey];
    });

    this.setState({
      file: this.props.editVideo.file,
      videoTitle: this.props.editVideo.title,
      selectedTrainer: this.props.trainersList.filter((trainer) => trainer._id === this.props.editVideo.trainer)[0],
      videoForm: copiedForm
    });
  };

  showErrorToast = (message) => {
    this.setState({ errorMessage: message });
  };

  renderTrainerSelection = () => {
    const selected = (...args) =>
      this.setState({
        selectedTrainer: args[0].value,
        trainerSelectError: null
      });

    if (this.props.trainersList && this.props.trainersList.length > 0) {
      const trainersList = this.props.trainersList.map((elem) => {
        return {
          ...elem,
          label: elem.name + ' ' + elem.last_name,
          value: elem
        };
      });

      return (
        <div style={{ marginBottom: '20px' }}>
          <Label>Instructor</Label>
          <Select
            options={trainersList}
            value={
              this.state.selectedTrainer
                ? `${this.state.selectedTrainer.name} ${this.state.selectedTrainer.last_name}`
                : null
            }
            onChange={selected}
          />
          {this.state.trainerSelectError ? (
            <div
              style={{
                fontSize: '12px',
                color: '#ca0e0b'
              }}>
              {this.state.trainerSelectError}
            </div>
          ) : null}
        </div>
      );
    }
  };

  uploadVideo = async (formData) => {
    await axios
      .post(`/videos`, formData)
      .then((resp) => {
        const clonedFileData = cloneDeep(this.state.file);
        clonedFileData.url = resp.data.videoUrl;
        this.setState({
          file: clonedFileData,
          allowUserToSave: true
        });
      })
      .catch((err) => {
        this.setState({
          error: {
            title: 'Failed to upload video',
            code: err.statusCode,
            details: err
          }
        });
      });
  };

  saveVideoData = async () => {
    this.setState({
      allowUserToSave: false
    });

    const videoConfig = [];
    for (let input in this.state.videoForm) {
      let value = this.state.videoForm[input].value;
      if (typeof value === 'string' && value.toLowerCase() === 'yes') {
        value = true;
      }
      if (typeof value === 'string' && value.toLowerCase() === 'no') {
        value = false;
      }

      videoConfig.push({ [input]: value });
    }
    const videoFormData = {
      videoConfig: videoConfig,
      file: this.state.file,
      title: this.state.videoTitle,
      selectedTrainer: this.state.selectedTrainer._id
    };

    const apiUrl = this.state.editMode
      ? `/videos/editVideo/${this.props.editVideo._id}`
      : `/videos/${this.state.videoForm.Workout_Type.value}`;

    await axios
      .post(`${apiUrl}`, videoFormData)
      .then(() => this.props.onRequestClose(this.state.editMode ? true : null))
      .catch((err) => {
        this.setState({
          error: {
            title: 'Failed to save video details',
            code: err.statusCode,
            details: err
          }
        });
      });
  };

  valueChangedHandler = (newValue, identifier) => {
    const clonedVideoForm = cloneDeep(this.state.videoForm);

    clonedVideoForm[identifier] = {
      ...clonedVideoForm[identifier],
      value: newValue,
      valid: checkValidity(newValue, this.state.videoForm[identifier].validation),
      errorText: returnErrorTextForField(this.state.videoForm[identifier].validation),
      touched: true
    };

    this.setState({
      videoForm: clonedVideoForm
    });
  };

  handleTitleChange(e) {
    const newTitle = e.target.value;
    this.setState({ videoTitle: newTitle });
  }

  handleToastClose = () => {
    this.setState({ errorMessage: false });
  };

  renderNotification = () => {
    if (this.state.showConfirmToast) {
      return (
        <Toast status="warning" onClose={this.handleConfirmToastClose}>
          Click once again to confirm the deletion of the media category
        </Toast>
      );
    }
    if (this.state.errorMessage) {
      return (
        <Toast status="critical" onClose={this.handleToastClose}>
          {this.state.errorMessage}
        </Toast>
      );
    }

    if (!this.state.formIsValid && this.state.formValidated) {
      return (
        <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
      );
    }
  };

  checkFormValidity = () => {
    this.setState({ formValidated: true });
    if (this.state.videoTitle === undefined || this.state.videoTitle.length === 0) {
      document.getElementsByClassName('ss-modal')[0].scrollTop = 0;
      return this.setState({
        formIsValid: false
      });
    }
    if (this.state.selectedTrainer === undefined) {
      document.getElementsByClassName('ss-modal')[0].scrollTop = 0;
      return this.setState({
        formIsValid: false
      });
    }

    for (let input in this.state.videoForm) {
      if (!this.state.videoForm[input].valid) {
        const copiedForm = cloneDeep(this.state.videoForm);
        copiedForm[input] = {
          ...copiedForm[input],
          valid: checkValidity(copiedForm[input].value, copiedForm[input].validation),
          errorText: returnErrorTextForField(copiedForm[input].validation),
          touched: true
        };

        document.getElementsByClassName('ss-modal')[0].scrollTop = 0;

        return this.setState({
          formIsValid: false,
          videoForm: copiedForm
        });
      }
    }

    this.setState({ formIsValid: true });
    this.saveVideoData();
  };

  setVideoTitle = (event) => {
    const clonedFileData = cloneDeep(this.state.file);
    clonedFileData.title = event.value ? event.value : event.target.value;
    this.setState({
      file: clonedFileData
    });
  };

  render() {
    return (
      <>
        {this.renderNotification()}
        <div className={classes.videoModalContainer}>
          <div className={classes.videoUploadForm}>
            <UploadVideo uploadVideo={this.uploadVideo} state={this.state} video={this.props.editVideo} />
          </div>
          <div className={classes.videoForm}>
            <Label>Title</Label>
            <FormField onChange={this.setVideoTitle} className={classes.input}>
              <TextInput
                value={this.state.videoTitle}
                placeHolder="Enter title"
                onDOMChange={(evt) => this.handleTitleChange(evt)}
              />
            </FormField>
            {this.renderTrainerSelection()}
            <FormCreator formData={this.state.videoForm} valueChanged={this.valueChangedHandler} />
          </div>
        </div>
        <div className={classes.modalButtons}>
          <Button
            className={classes.btnCancel}
            label="Cancel"
            secondary={false}
            onClick={() => this.props.onRequestClose(false, this.state.file.name)}
          />
          <Button
            className={classes.btnSave}
            label="Save"
            secondary={true}
            onClick={this.state.allowUserToSave ? this.saveVideoData : null}
          />
        </div>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps)(AddEditVideo));
