import React, { useState } from 'react';
import { Select, Label } from 'grommet';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import { returnGymsPerActiveClassType } from '../../../shared/gymFunctions';
import { returnGymsByAccess } from '../../../shared/userFunctions';

const mapGymsAsOptions = (array) =>
  array.map((elem) => {
    return {
      label: elem.name,
      id: elem._id,
      gym: elem
    };
  });

const GymSelector = ({ locations, classType = null, userData, onSelect }) => {
  const gyms = mapGymsAsOptions(returnGymsPerActiveClassType(returnGymsByAccess(locations, userData), classType));
  const [filteredGyms, setFilteredGyms] = useState(gyms);
  const [selected, setSelected] = useState(null);

  const onSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredArray = gyms.filter((elem) => {
      return elem.name.toLowerCase().includes(searchText);
    });
    setFilteredGyms(filteredArray);
  };

  const onChange = (event) => {
    onSelect(event.option.gym);
    setSelected(event.option);
  };

  return (
    <>
      <div style={{ marginBottom: '25px' }}>
        <Label>Select gym</Label>
        <Select
          placeHolder="Please select gym name"
          options={filteredGyms}
          value={selected ? selected.label : ''}
          onSearch={onSearch}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default withAdminDashboard(GymSelector);
