import React, { Component } from 'react';
import classes from './SingleVideo.module.scss';
import Icons from '../../../../components/UI/Icons/Icons';
import moment from 'moment';

class SingleVideo extends Component {
  state = {
    videoUrl: '',
    thumbnailUrl: ''
  };
  componentDidMount = () => {
    this.videoUrl();
  };

  videoUrl = () => {
    const thumbnailMedia = this.props.video.media.filter((media) => media.type === 'Thumbnail')[0];
    this.setState({
      thumbnailUrl: thumbnailMedia.files[0].path
    });
  };
  render = () => {
    return (
      <>
        <div>
          <img className={classes.videoThumbnail} src={this.state.thumbnailUrl} alt="video" />
        </div>
        <div className={classes.videoInfo}>
          <div>
            <h4 className={classes.videoTitle}>{this.props.video.title}</h4>
            <span className={classes.lastUpdatedDate}>
              Last updated: {moment(this.props.video.updatedDate).format('DD/MM/YYYY')}
            </span>
          </div>
          <Icons edit delete editClick={this.props.handleVideoEdit} deleteClick={this.props.handleDeleteVideo} />
        </div>
      </>
    );
  };
}

export default SingleVideo;
