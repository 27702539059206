import React, { useState } from 'react';
import FormField from 'grommet/components/FormField';

import axios from '../../../axios-global';

export default function ClientProfileGeneralNotes(props) {
  const [generalNotes, setGeneralNotes] = useState(props.selectedClient.generalNotes);

  const updateGeneralNotes = (event) => setGeneralNotes(event.target.value);

  const saveNotes = async () => {
    try {
      await axios.patch(`/users/${props.selectedClient._id}`, {
        generalNotes
      });
      props.onSuccessfulSave();
    } catch (error) {
      props.onFailedSave(error);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <FormField label="General notes">
        <textarea value={generalNotes} onChange={updateGeneralNotes} onBlur={saveNotes} />
      </FormField>
    </div>
  );
}
