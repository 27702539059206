import axios from '../../axios-global';
import * as actionTypes from './actionTypes';
import { returnErrorFromResponse } from '../../shared/utility';

// plans list
export const fetchPlansListForClient = (gymId) => {
  return (dispatch) => {
    dispatch(setPlansListStart());

    axios
      .get(`/membership-plan/availablePlans/${gymId}`)
      .then((response) => dispatch(setPlansListSuccess(response.data)))
      .catch((error) => dispatch(setPlansListFailed(returnErrorFromResponse(error))));
  };
};

const setPlansListStart = () => {
  return {
    type: actionTypes.CLIENT_FETCH_PLANS_START
  };
};

const setPlansListSuccess = (plansList) => {
  return {
    type: actionTypes.CLIENT_FETCH_PLANS_SUCCESS,
    plansList
  };
};

const setPlansListFailed = (error) => {
  return {
    type: actionTypes.CLIENT_FETCH_PLANS_FAILED,
    error
  };
};

// sources list
export const fetchSources = () => {
  return (dispatch) => {
    dispatch(fetchSourcesStart());

    axios
      .get('/users/me/source')
      .then((resp) => {
        if (resp.data.status === 'fail') {
          dispatch(fetchSourcesFailed(returnErrorFromResponse(resp)));
        } else {
          dispatch(fetchSourcesSuccess(resp.data));
        }
      })
      .catch((error) => dispatch(fetchSourcesFailed(returnErrorFromResponse(error))));
  };
};

const fetchSourcesStart = () => {
  return {
    type: actionTypes.CLIENT_FETCH_SOURCES_START
  };
};

const fetchSourcesSuccess = (sourcesList) => {
  return {
    type: actionTypes.CLIENT_FETCH_SOURCES_SUCCESS,
    sourcesList
  };
};

const fetchSourcesFailed = (error) => {
  return {
    type: actionTypes.CLIENT_FETCH_SOURCES_FAILED,
    error
  };
};

// current membership subscription status
export const fetchSubscriptionStatus = () => {
  return (dispatch) => {
    dispatch(fetchSubscriptionStatusStart());

    axios
      .get('/users/me/status')
      .then((resp) => {
        dispatch(fetchSubscriptionStatusSuccess(resp.data));
      })
      .catch((error) => dispatch(fetchSubscriptionStatusFailed(returnErrorFromResponse(error))));
  };
};

const fetchSubscriptionStatusStart = () => {
  return {
    type: actionTypes.CLIENT_SUBSCRIPTION_STATUS_START
  };
};

const fetchSubscriptionStatusSuccess = (currentStatus) => {
  return {
    type: actionTypes.CLIENT_SUBSCRIPTION_STATUS_SUCCESS,
    currentStatus
  };
};

const fetchSubscriptionStatusFailed = (error) => {
  return {
    type: actionTypes.CLIENT_SUBSCRIPTION_STATUS_FAILED,
    error
  };
};

export const fetchUpcomingClasses = () => {
  return (dispatch) => {
    dispatch(fetchUpcomingClassesStart());

    axios
      .get('/users/class/upcoming')
      .then((resp) =>
        dispatch(fetchUpcomingClassesSuccess(resp.data.sort((a, b) => new Date(a.classDate) - new Date(b.classDate))))
      )
      .catch((error) => dispatch(fetchUpcomingClassesFailed(returnErrorFromResponse(error))));
  };
};

const fetchUpcomingClassesStart = () => {
  return {
    type: actionTypes.CLIENT_FETCH_UPCOMING_CLASSES_START
  };
};

const fetchUpcomingClassesSuccess = (upcomingClasses) => {
  return {
    type: actionTypes.CLIENT_FETCH_UPCOMING_CLASSES_SUCCESS,
    upcomingClasses
  };
};

const fetchUpcomingClassesFailed = (error) => {
  return {
    type: actionTypes.CLIENT_FETCH_UPCOMING_CLASSES_FAILED,
    error
  };
};

export const fetchUpcomingPts = () => {
  return (dispatch) => {
    dispatch(fetchUpcomingPtsStart());

    axios
      .get('/users/personalTraining/upcoming')
      .then((resp) =>
        dispatch(fetchUpcomingPtsSuccess(resp.data.sort((a, b) => new Date(a.classDate) - new Date(b.classDate))))
      )
      .catch((error) => dispatch(fetchUpcomingPtsFailed(returnErrorFromResponse(error))));
  };
};

const fetchUpcomingPtsStart = () => {
  return {
    type: actionTypes.CLIENT_FETCH_UPCOMING_PTS_START
  };
};

const fetchUpcomingPtsSuccess = (upcomingPts) => {
  return {
    type: actionTypes.CLIENT_FETCH_UPCOMING_PTS_SUCCESS,
    upcomingPts
  };
};

const fetchUpcomingPtsFailed = (error) => {
  return {
    type: actionTypes.CLIENT_FETCH_UPCOMING_CLASSES_FAILED,
    error
  };
};

export const fetchWaitList = () => {
  return (dispatch) => {
    dispatch(fetchWaitListStart());

    axios
      .get('/wait-list/show')
      .then((resp) => dispatch(fetchWaitListSuccess(resp.data)))
      .catch((error) => dispatch(fetchWaitListFailed(returnErrorFromResponse(error))));
  };
};

const fetchWaitListStart = () => {
  return {
    type: actionTypes.CLIENT_FETCH_WAIT_LIST_START
  };
};

const fetchWaitListSuccess = (waitList) => {
  return {
    type: actionTypes.CLIENT_FETCH_WAIT_LIST_SUCCESS,
    waitList
  };
};

const fetchWaitListFailed = (error) => {
  return {
    type: actionTypes.CLIENT_FETCH_WAIT_LIST_FAILED,
    error
  };
};

export const updateUserDetails = (userId, object) => {
  return (dispatch) => {
    dispatch(setUpdateUserDetailsStart());

    axios
      .patch(`/users/${userId}`, object)
      .then((response) => {
        dispatch(setUpdateUserDetailsSuccess(response.data));
      })
      .catch((error) => dispatch(setUpdateUserDetailsFailed(returnErrorFromResponse(error))));
  };
};

const setUpdateUserDetailsStart = () => {
  return {
    type: actionTypes.CLIENT_UPDATE_DETAILS_START
  };
};

const setUpdateUserDetailsSuccess = (updatedUser) => {
  return {
    type: actionTypes.CLIENT_UPDATE_DETAILS_SUCCESS,
    updatedUser
  };
};

const setUpdateUserDetailsFailed = (error) => {
  return {
    type: actionTypes.CLIENT_UPDATE_DETAILS_FAILED,
    error
  };
};
