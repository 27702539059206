import moment from 'moment';
import { returnClassesDetails } from './classFunctions';

export const isActiveMembershipUser = (userData) =>
  userData.customerType === 'full-member' &&
  userData.joiningFeePaid &&
  userData.status === 'active' &&
  userData.currentMembershipPlan &&
  moment().isSameOrBefore(moment(userData.currentMembershipPlan.current_period_end, 'YYYY-MM-DD'));

export const isActiveUser = (userData) =>
  isActiveMembershipUser(userData) || (userData.customerType === 'class-pass-member' && userData.status === 'active');

export const isFullAccessAdminOrTrainer = (userData) =>
  userData.role !== 'user' && (userData['gymsFullAccess'] === undefined || userData.gymsFullAccess);

export const returnGymsByAccess = (gymsList, userData) => {
  let result = [];
  if (isFullAccessAdminOrTrainer(userData)) {
    return gymsList;
  }

  if (gymsList !== undefined && gymsList !== null) {
    gymsList.forEach((elem) => {
      userData.gymId.forEach((gym) => {
        if (elem._id === gym) {
          result.push(elem);
        }
      });
    });
  }
  return result;
};

export const isMembershipPlanActive = (userData) =>
  moment(userData.currentMembershipPlan.current_period_end, 'YYYY-MM-DD').isSameOrAfter(moment()) &&
  userData.joiningFeePaid;

export const returnTrainersForSelectedGym = (trainersList, gymId) =>
  trainersList.filter((trainer) => isFullAccessAdminOrTrainer(trainer) || trainer.gymId.includes(gymId));

export const returnUserClassesLeftAmount = (classType, userData, selectedClass) => {
  if (classType === 'class' && userData.customerType === 'full-member') {
    if (
      userData.currentMembershipPlan &&
      userData.currentMembershipPlan.current_period_end &&
      userData.currentMembershipPlan.ssPlanId &&
      userData.currentMembershipPlan.ssPlanId.unlimited &&
      moment(userData.currentMembershipPlan.current_period_end).isSameOrAfter(moment()) &&
      userData.currentMembershipPlan.ssPlanId.unlimited
    ) {
      return 100;
    }
    return moment(selectedClass.classDate).format('M') - moment().format('M') === 0
      ? userData.classesLeft
      : userData.classesLeftNextMonth;
  }
  return userData[returnClassesDetails(classType).usersClassesLeft];
};
