import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Spinning from 'grommet/components/icons/Spinning';

export default (Component) => {
  const mapStateToProps = (state) => {
    return {
      userData: state.auth.userData,
      selectedGym: state.admin.selectedGym,
      locations: state.location.locations,
      loading: state.location.loading,

      adminsList: state.admin.adminsList,
      adminsListLoading: state.admin.adminsListLoading,
      adminsListError: state.admin.adminsListError,

      deletedAdmin: state.admin.deletedAdmin,
      deletedAdminLoading: state.admin.deletedAdminLoading,
      deletedAdminError: state.admin.deletedAdminError
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      onGetAdmins: (gymId) => dispatch(actions.getAdmins(gymId)),
      onAddNewAdmin: (newAdmin) => dispatch(actions.addNewAdmin(newAdmin)),
      onEditAdmin: (updatedAdmin) => dispatch(actions.editAdmin(updatedAdmin)),
      onDeleteAdmin: (adminId) => dispatch(actions.deleteAdmin(adminId))
    };
  };

  class Wrapper extends React.Component {
    componentDidMount() {
      if (this.props.adminsList || this.props.adminsListLoading) {
        return;
      }

      this.props.onGetAdmins(this.props.selectedGym._id);
    }

    render() {
      if (this.props.adminsListLoading) {
        return <Spinning />;
      }
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
