import React from 'react';
import { NavLink } from 'react-router-dom';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';

export default function UserListPtBlocks(props) {
  let PtsList = (
    <span>
      You don't have any PT Packages left. You can buy more <NavLink to="/gym/personal-training-passes">here.</NavLink>
    </span>
  );

  if (props.PtsList && props.PtsList.length > 0) {
    PtsList = props.PtsList.map((PtBlock) => (
      <ListItem key={PtBlock.productPassPurchaseId} direction="column" align="start" separator="horizontal">
        {PtBlock.classesLeft} sessions of {PtBlock.duration} minutes
      </ListItem>
    ));
  }

  return (
    <>
      <h4 className="ss-list-header">PT Packages</h4>
      <List>{PtsList}</List>
    </>
  );
}
