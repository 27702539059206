import React from 'react';

import PrivacyPolicyText from '../../brandConfig/componentsTexts/PrivacyPolicyText';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';

const PrivacyPolicy = () => {
  return (
    <WhiteBackground>
      <div className="container">
        <PrivacyPolicyText />
      </div>
    </WhiteBackground>
  );
};
export default PrivacyPolicy;
