import React from 'react';
import DefaultSingleDayTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultSingleDayTimetable';
import DefaultTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import FullDataTimetable from '../../DefaultTimetable/FullDataTimetable/FullDataTimetable';
import { returnAllUpcomingClasses } from '../../../../shared/classFunctions';

const UserView = ({
  classesList,
  classesListLoading,
  classesListError,
  classesDetails,
  classConfig,
  classesListReFetch
}) => {
  if (classesList && classConfig.classType === 'class') {
    return <FullDataTimetable classesList={classesList} classesDetails={classesDetails} />;
  }

  if (classConfig.singleDayTimetable) {
    return (
      <DefaultSingleDayTimetable
        classesList={classesList}
        classesListLoading={classesListLoading}
        classesListError={classesListError}
        onFetchClasses={classesListReFetch}
        classesDetails={classesDetails}
        classConfig={classConfig}
      />
    );
  }
  return (
    <DefaultTimetable
      classesList={returnAllUpcomingClasses(classesList)}
      classesListLoading={classesListLoading}
      classesListError={classesListError}
      classesDetails={classesDetails}
      classType={classesDetails.type}
      onFetchClasses={classesListReFetch}
      classConfig={classConfig}
    />
  );
};

export default UserView;
