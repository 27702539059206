import React, { useState, useEffect } from 'react';
import classes from './UpcomingClassesForAllGyms.module.scss';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';

import { CheckBox, TextInput, Button } from 'grommet';
import Close from 'grommet/components/icons/base/Close';

const UpcomingClassesForAllGymsFilters = ({ showFilters, onFilter, data, gyms, hideFiters }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [filteredName, setFilteredName] = useState('');
  const [checkedGyms, setCheckedGyms] = useState(gyms.map((elem) => elem.name));

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const mapLocations = gyms.length > 0 && (
    <div>
      <h4>Gym name</h4>
      {gyms.map((elem) => (
        <CheckBox
          className={classes.checkbox}
          key={elem._id}
          label={elem.name}
          onChange={() => handleGymFilter(elem.name)}
          toggle={false}
          disabled={false}
          checked={checkedGyms.indexOf(elem.name) >= 0}
        />
      ))}
    </div>
  );

  const filterByName = (
    <div>
      <h4>Class name</h4>
      <TextInput
        className={classes.classNameFilter}
        name="classFilter"
        placeHolder="Class name"
        onDOMChange={(e) => handleNameFilter(e.target.value)}
        value={filteredName}
      />
    </div>
  );

  const filterByClassName = (array, name) =>
    name !== null ? array.filter((elem) => elem.name.toLowerCase().includes(name.toLowerCase())) : array;
  const filterByGymName = (array, selectedGyms) => array.filter((elem) => selectedGyms.includes(elem.gymName[0]));

  const handleNameFilter = (value) => {
    const result = value !== null ? filterByClassName(filteredData, value) : filterByGymName(data, checkedGyms);
    setFilteredData(result);
    onFilter(result);
    setFilteredName(value);
  };

  const handleGymFilter = (gymName) => {
    const index = checkedGyms.indexOf(gymName);
    let newSelection = checkedGyms;

    if (index < 0) {
      newSelection.push(gymName);
    } else {
      newSelection = newSelection.splice(index, 1);
    }

    const result =
      newSelection.length > 0
        ? filterByGymName(filteredData, newSelection)
        : filteredName !== null
        ? filterByClassName(data, filteredName)
        : data;

    setCheckedGyms(newSelection);
    setFilteredData(result);
    onFilter(result);
  };

  const clearFilters = () => {
    const handleClearFilters = () => {
      setCheckedGyms(gyms.map((elem) => elem.name));
      setFilteredName('');
      onFilter(data);
    };
    return <Button primary label="Reset" onClick={handleClearFilters} />;
  };

  if (showFilters) {
    return (
      <div className={classes.filtersContainer}>
        {clearFilters()}
        {mapLocations}
        {filterByName}
        <Close style={{ cursor: 'pointer', margin: '10px' }} onClick={hideFiters} />
      </div>
    );
  }
  return null;
};

export default withAdminDashboard(UpcomingClassesForAllGymsFilters);
