import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import CircleInformationIcon from 'grommet/components/icons/base/CircleInformation';
import Modal from 'react-modal';
import classes from './SingleClass.module.scss';
import ClassActionButtons from '../../../../ActionButtons/ClassActionButtons/ClassActionButtons';
import ReturnClassPrice from '../../ReturnClassPrice/ReturnClassPrice';
import withUser from '../../../../../store/hoc/withUser';

const SingleClass = ({ selectedClass, classesDetails, userData }) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const classDescriptionModal = () => {
    return (
      <div>
        <Modal onRequestClose={() => setShowDescriptionModal(false)} isOpen={showDescriptionModal} className="ss-modal">
          <div className="grommet">
            <span className={classes.label}>Description:</span>
            {selectedClass.description}
          </div>
        </Modal>

        <div
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.pointer}
          onClick={() => setShowDescriptionModal(true)}>
          <CircleInformationIcon />
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={[classes.singleClass, selectedClass.enabled ? '' : classes.hidden].join(' ')}
        data-cy="singleClass">
        <div className={classes.informationContainer} style={{ marginBottom: '12px' }}>
          <div className={classes.time}>{`${selectedClass.classTime} - ${selectedClass.duration} min`}</div>
          <ReturnClassPrice selectedClass={selectedClass} userData={userData} classType={classesDetails.type} />
        </div>

        <div className={classes.informationContainer} style={{ marginBottom: '12px', minHeight: '40px' }}>
          <div className={classes.name}>{selectedClass.name}</div>
          {classDescriptionModal()}
        </div>

        <div className={classes.informationContainer} style={{ marginBottom: '12px' }}>
          <div className={classes.location}>
            {selectedClass.location} /{' '}
            {selectedClass.trainer
              ? `${selectedClass.trainer.name} ${selectedClass.trainer.last_name.substring(0, 1)}.`
              : null}
          </div>

          <div className={classes.limit}>
            {selectedClass.limit - selectedClass.joinedUsers} of {selectedClass.limit}{' '}
          </div>
        </div>

        <ClassActionButtons
          selectedClass={selectedClass}
          classesDetails={classesDetails}
          userAttendance={{
            stripeOrderId: selectedClass.userAttendanceStripeOrderId,
            status: selectedClass.userAttendanceStatus,
            _id: selectedClass.userAttendanceId
          }}
        />

        {!selectedClass.enabled && <div>Hidden</div>}
      </div>
    </>
  );
};

export default withRouter(withUser(SingleClass));
