import * as actionTypes from './actionTypes';

export const reactToWindowResize = (width) => {
  return (dispatch) => {
    dispatch(setWindowSize(width));
  };
};

const setWindowSize = (width) => {
  return {
    type: actionTypes.SET_WINDOW_WIDTH,
    width
  };
};

export const setGlobalError500 = (status) => {
  return {
    type: actionTypes.SET_GLOBAL_ERROR_500,
    status
  };
};

export const setUnauthorizedError = (status) => {
  return {
    type: actionTypes.SET_UNAUTHORIZED_ERROR,
    status
  };
};

export const setGlobalNetworkError = () => {
  return {
    type: actionTypes.SET_GLOBAL_NETWORK_ERROR
  };
};
