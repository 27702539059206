import axios from '../../../../axios-global';
import React, { useState, useEffect } from 'react';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import { TextInput } from 'grommet';
import DateTime from 'grommet/components/DateTime';
import FormField from 'grommet/components/FormField';
import './AttendancesReports.scss';
import moment from 'moment';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import Spinning from 'grommet/components/icons/Spinning';
import Select from 'grommet/components/Select';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';

export const AttendancesReports = (props) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedGym, setSelectedGym] = useState({
    label: props.selectedGym.name,
    id: props.selectedGym._id
  });
  const [filteredReport, setFilteredReport] = useState([]);
  const [reportResult, setReportResult] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [filteredGym, setFilteredGym] = useState(null);

  useEffect(() => {
    const getReport = () => {
      setLoading(true);
      let url = `/gym-attendances/all?gymId=${selectedGym.id}&startDate=${moment(startDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      )}&endDate=${moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;

      axios
        .get(url)
        .then((response) => {
          setLoading(false);
          setFilteredReport(response.data);
          formatReportArray(response.data);
        })
        .catch(() => setLoading(false));
    };
    if (startDate !== undefined && endDate !== undefined) {
      getReport();
    }
  }, [startDate, endDate, selectedGym]);

  const formatReportArray = (report) => {
    let results = [];
    Object.keys(report).forEach((key) => {
      results.push(report[key]);
    });
    setReportResult(results);
    setFilteredResult(results);
  };

  const startDateChange = (date) => {
    setStartDate(date);
  };
  const endDateChange = (date) => {
    setEndDate(date);
  };

  const onSelectedFilterChanged = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filteredArray = reportResult.filter((result) => {
      return (
        result.userId.name.toLowerCase().includes(searchText) ||
        result.userId.last_name.toLowerCase().includes(searchText)
      );
    });
    setFilteredResult(filteredArray);
  };

  const renderGymSelection = () => {
    let options = props.locations.map((elem) => {
      return {
        label: elem.name,
        id: elem._id
      };
    });
    const onFilterGymList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredGymList = props.locations.filter((gym) => gym.name.toLowerCase().includes(filter));

      setFilteredGym(
        filteredGymList.map((elem) => {
          return {
            label: elem.name,
            id: elem._id
          };
        })
      );
    };
    return (
      <div>
        <h4>Select gym</h4>
        <Select
          placeHolder="Select gym name"
          options={filteredGym || options}
          value={selectedGym}
          onChange={(event) => setSelectedGym(event.option)}
          onSearch={onFilterGymList}
        />
      </div>
    );
  };
  return (
    <>
      <ExportToCSV data={filteredResult} type={'attendanceReport'} gymName={selectedGym.label} />
      <div>
        <div className="attendenceReport__filter">
          <div>
            <div>
              <h4>Start Date</h4>
              <FormField>
                <DateTime
                  id="filterDate"
                  name="filterDate"
                  format="DD/MM/YYYY"
                  onChange={startDateChange}
                  value={startDate}
                />
              </FormField>
            </div>
            <div className="attendenceReport__filter__gym">
              <h4>Filter by name</h4>
              <TextInput
                className="attendenceReport__filter__name"
                placeHolder="client name"
                name="clientName"
                onDOMChange={onSelectedFilterChanged}
              />
            </div>
          </div>
          <div>
            <div className="attendenceReport__filter__date">
              <h4>End Date</h4>
              <FormField>
                <DateTime
                  id="filterDate"
                  name="filterDate"
                  format="DD/MM/YYYY"
                  onChange={endDateChange}
                  value={endDate}
                />
              </FormField>
            </div>
            <div className="attendenceReport__filter__gym">{renderGymSelection()}</div>
          </div>
        </div>
        {loading ? (
          <Spinning />
        ) : (
          <List className="attendenceReport__list">
            {filteredReport.length === 0 && <div>No any client found</div>}
            {filteredReport.length > 0 && (
              <ListItem className="attendenceReport__list__header">
                <span>Client</span>
                <span>Attendance</span>
              </ListItem>
            )}
            {filteredResult.map((result, index) => (
              <ListItem
                className="attendenceReport__list__item"
                key={index}
                onClick={() => props.history.push(`/gym/${props.selectedGym._id}/clients/${result.userId._id}`)}>
                <span>
                  {result.userId.name} {result.userId.last_name}
                </span>
                <span className="attendenceReport__list__item__count">
                  {moment(result.createdDate).format('DD/MM/YYYY hh:mm a')}
                </span>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </>
  );
};

export default withAdminDashboard(AttendancesReports);
