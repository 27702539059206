import React from 'react';

import TermsOfWebsiteText from '../../brandConfig/componentsTexts/TermsOfWebsiteText';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';

const TermsOfWebsite = ({ inModal }) => {
  if (inModal) {
    return <TermsOfWebsiteText />;
  }

  return (
    <WhiteBackground>
      <div className="container">
        <TermsOfWebsiteText />
      </div>
    </WhiteBackground>
  );
};

export default TermsOfWebsite;
