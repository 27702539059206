import React, { useState } from 'react';
import Button from 'grommet/components/Button';
import Heading from 'grommet/components/Heading';

import classes from './SingleMembershipPlan.module.scss';
import Icons from '../UI/Icons/Icons';
import Modal from 'react-modal';
import AddEditMembershipPlan from '../../containers/AdminDashboard/ManageMembershipPlans/AddEditMembershipPlan/AddEditMembershipPlan';
import { getCurrencySymbol, proRatedCost } from '../../shared/utility';

const SingleMembershipPlan = (props) => {
  const [showModal, setShowModal] = useState(false);
  const returnJoiningFeeInfo = () => {
    if (!props.joiningFeePaid && props.plan.joiningFee) {
      const joiningFee = `${getCurrencySymbol(props.plan.currency)} ${props.plan.joiningFee} joining fee`;
      return (
        <>
          {joiningFee}
          <br />
        </>
      );
    }
  };

  const renderControls = () => {
    let controls = (
      <div className={classes.controls}>
        <span className={classes.price}>
          {returnJoiningFeeInfo()}
          {getCurrencySymbol(props.plan.currency)}&nbsp;{props.plan.price}
          &nbsp;monthly ({proRatedCost(props.plan)} for this month)
        </span>
        <Button
          className={classes.buyButton}
          label="Subscribe"
          secondary={true}
          onClick={() => props.selectPlan(props.plan)}
          data-cy="subscribeBtn"
        />
      </div>
    );

    if (props.admin) {
      controls = (
        <div className={classes.adminControls}>
          <Icons edit editClick={() => setShowModal(true)} />
        </div>
      );
    }

    return controls;
  };

  return (
    <div className={classes.singlePlan} data-cy="singlePlan">
      <div className={classes.description}>
        <Heading tag="h4" strong={false}>
          {props.plan.name}
        </Heading>

        <div className={classes.singlePlanDescription}>{props.plan.description}</div>

        <div>
          <span className={classes.singlePlanLabel}>Minimum contract length:</span> {props.plan.minContractLength} month
          {props.plan.minContractLength > 1 ? 's' : null}
        </div>

        <div>
          <span className={classes.singlePlanLabel}>Classes included:</span>{' '}
          {props.plan.unlimited ? 'Unlimited' : props.plan.classesIncluded}
        </div>

        {props.plan.unlimited ? (
          ''
        ) : (
          <div>
            <span className={classes.singlePlanLabel}>Class price:</span>{' '}
            {`${getCurrencySymbol(props.plan.currency)} `.concat(props.plan.classPrice)}
          </div>
        )}

        {props.admin ? (
          <>
            <div>
              <span className={classes.singlePlanLabel}>Joining fee:</span>{' '}
              {props.plan.joiningFee
                ? `${getCurrencySymbol(props.plan.currency)} `.concat(props.plan.joiningFee)
                : 'None'}
            </div>
            <div>
              <span className={classes.singlePlanLabel}>Price:</span> {getCurrencySymbol(props.plan.currency)}&nbsp;
              {props.plan.price}
            </div>
          </>
        ) : null}
      </div>

      {renderControls()}

      <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal">
        <div className="grommet">
          <AddEditMembershipPlan editedPlan={props.plan} />
        </div>
      </Modal>
    </div>
  );
};
export default SingleMembershipPlan;
