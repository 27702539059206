import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  width: window.innerWidth,
  showGlobalError500: false,
  showGlobalNetworkError: false,
  showUnauthorizedError: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WINDOW_WIDTH:
      return updateObject(state, { width: action.width });
    case actionTypes.SET_GLOBAL_ERROR_500:
      return updateObject(state, { showGlobalError500: action.status });
    case actionTypes.SET_UNAUTHORIZED_ERROR:
      return updateObject(state, { showUnauthorizedError: action.status });
    case actionTypes.SET_GLOBAL_NETWORK_ERROR:
      return updateObject(state, { showGlobalNetworkError: true });
    default:
      return state;
  }
};

export default reducer;
