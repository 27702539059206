import React from 'react';
import { Tab, Tabs } from 'grommet';
import SingleClassTypeHistory from '../../../HistoryComponent/SingleClassTypeHistory';
import DefaultSingleDayTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultSingleDayTimetable';
import DefaultTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import { returnAllUpcomingClasses } from '../../../../shared/classFunctions';
import withUser from '../../../../store/hoc/withUser';
import ManagePasses from '../../../ManagePasses/ManagePasses';
import AddEditSingleClass from '../../../AddEditSingleClass/AddEditSingleClass';
import ManageSchedules from '../../../ManageSchedules/ManageSchedules';
import ManageTemplates from '../../../ManageTemplates/ManageTemplates';

const AdminView = ({
  classesList,
  classesListLoading,
  classesListError,
  classType,
  classesDetails,
  classConfig,
  onFetchClasses,
  showAllGymsData
}) => {
  const upcomingClassesComponent = classConfig.singleDayTimetable ? (
    <DefaultSingleDayTimetable
      classesList={classesList}
      classesListLoading={classesListLoading}
      classesListError={classesListError}
      onFetchClasses={onFetchClasses}
      classesDetails={classesDetails}
      classConfig={classConfig}
      showAllGymsData={showAllGymsData}
    />
  ) : (
    <DefaultTimetable
      classesList={returnAllUpcomingClasses(classesList)}
      classesListLoading={classesListLoading}
      classesListError={classesListError}
      classesDetails={classesDetails}
      classType={classesDetails.type}
      onFetchClasses={onFetchClasses}
      classConfig={classConfig}
      showAllGymsData={showAllGymsData}
    />
  );

  const historyComponent = <SingleClassTypeHistory classType={classType} classConfig={classConfig} />;
  const passesComponent = classConfig.allowedToBuyPasses && (
    <Tab title="Passes">
      <ManagePasses classType={classType} />
    </Tab>
  );

  const scheduleComponent = classConfig.schedule && (
    <Tab title="Schedule">
      <ManageSchedules classType={classType} />
    </Tab>
  );

  const templateComponent = classConfig.schedule && (
    <Tab title="Templates">
      <ManageTemplates classType={classType} />
    </Tab>
  );

  return (
    <div>
      <Tabs style={tabStyles} justify="start" data-cy="tabList">
        <Tab title="Upcoming">{upcomingClassesComponent}</Tab>
        <Tab title="History">{historyComponent}</Tab>
        <Tab title="Add New">
          <AddEditSingleClass classType={classType} classConfig={classConfig} />
        </Tab>
        {passesComponent}
        {scheduleComponent}
        {templateComponent}
      </Tabs>
    </div>
  );
};

export default withUser(AdminView);

const tabStyles = {
  borderBottom: 'none',
  paddingBottom: '20px',
  fontWeight: 'bold'
};
