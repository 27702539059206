import React from 'react';

import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';
import CheckBox from 'grommet/components/CheckBox';
import Anchor from 'grommet/components/Anchor';
export default (props) => {
  const renderInfo = () => {
    if (!props.healthStatementAccepted || !props.termsOfServiceAccepted) {
      return (
        <Paragraph>
          To continue, please accept both Terms & Conditions of using the club and the Health Commitment Statement
        </Paragraph>
      );
    }
  };
  return (
    <>
      <div>
        <Paragraph>
          The team at Hoar Cross Hall are dedicated to helping you take every opportunity to enjoy the facilities and
          treatments that we offer. However, we must also ensure that we all stay safe during our time within the
          property.
        </Paragraph>
        <Heading tag="h3">Our commitment to you</Heading>

        <ul>
          <li>
            We will make every reasonable effort to make sure that our equipment and facilities are in a safe condition
            for you to use and enjoy.
          </li>
          <li>
            We will take all reasonable steps to make sure that our staff are qualified to the therapy and fitness
            industry standards.
          </li>
          <li>
            If you tell us you have a disability which puts you at a substantial disadvantage in accessing our equipment
            and facilities, we will consider what adjustments, if any, are reasonable for us to make.
          </li>
        </ul>
      </div>

      <div>
        <Heading tag="h3">We kindly ask that you...</Heading>

        <ul>
          <li>Familiarise yourself with this Health Commitment Policy before your visit and use of our facilities</li>
          <li>
            Seek advice from a medical professional if you have a medical condition which might affect how you are able
            to use or enjoy our treatments, spa facilities and leisure
          </li>
          <li>
            If you have a medical condition or disability you think we should know about, please have a chat with us so
            we can be mindful when carrying out your treatment or teaching your class
          </li>
          <li>
            Please let us know immediately if you feel ill while having treatment, using our equipment or facilities. We
            have first aid trained team members who will be able to help
          </li>
          <li>
            Please follow our in-house guidance on using the pool and heated rooms safely.We strongly advise not to stay
            in hot areas for too long, or use the areas straight after consuming food or alcohol
          </li>
          <li>
            Let us know if you are pregnant, we can give advice on which facilities are safe to use and which treatments
            would suit you
          </li>
          <li>
            Ask for help before using gym equipment whether a seasoned gym-goer or new to exercise, we’d like to be able
            to cover everything properly with you
          </li>
          <li>Please be advised for your safety no glass is to be taken into the changing rooms or poolside areas</li>
        </ul>
      </div>

      <Paragraph>
        <CheckBox
          checked={props.healthStatementAccepted}
          onChange={props.handleHealthStatementCheckbox}
          label={<b>I Accept Pre-Exercise Agreement, Limitation of Liability & Declaration</b>}
        />
      </Paragraph>
      <Paragraph>
        <CheckBox
          checked={props.termsOfServiceAccepted}
          onChange={props.handlePrivacyCheckbox}
          label={
            <Anchor onClick={props.openModal} label={<b>I accept and agree to the Health Commitment Statement</b>} />
          }
        />
      </Paragraph>

      {renderInfo()}
    </>
  );
};
