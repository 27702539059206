import moment from 'moment';
import { ALL_CURRENCIES } from '../constants';
export const updateObject = (oldObject, updatedProperties) => Object.assign({}, oldObject, updatedProperties);

const defaultErrorMassage = 'Oops, something went wrong';
const defaultSuccessMassage = 'Operation successful';

export const returnErrorFromResponse = (err, isAdmin = false) => {
  const errorMessage = getMessageFromResponse(err, defaultErrorMassage);

  if (errorMessage === defaultErrorMassage) {
    return defaultErrorMassage;
  }

  switch (errorMessage) {
    case 'You already joined this class':
      return isAdmin ? 'The client is already on the attendance list' : errorMessage;
    case 'Sorry, you’ve run out of your class passes.':
      return isAdmin ? 'The client has run out of class passes.' : errorMessage;
    case 'The given data was invalid.':
      return err.response.data.errors[0].message;
    default:
      return errorMessage;
  }
};

export const returnSuccessFromResponse = (response) => {
  return getMessageFromResponse(response, defaultSuccessMassage);
};

const getMessageFromResponse = (response, defaultMessage) => {
  // multiple options due to not standardized responses from backend

  if (response.response && response.response.data) {
    return response.response.data.message ? response.response.data.message : defaultMessage;
  }

  if (response.data) {
    return response.data.message ? response.data.message : defaultMessage;
  }

  if (response.message) {
    return response.message ? response.message : defaultMessage;
  }

  return defaultMessage;
};

export const checkValidity = (value, rules, minimalUserAge = 16) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (value === null) {
    return false;
  }

  if (rules.required && typeof value === 'string') {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.restrictSpecialCharacterWithSpace) {
    const pattern = value.replace(/[a-zA-Z0-9-_]/g, '');
    return (isValid = pattern.length === 0 && isValid);
  }
  if (rules.restrictSpecialCharacter) {
    const pattern = value.replace(/[a-zA-Z0-9-_ ]/g, '');
    return (isValid = pattern.length === 0 && isValid);
  }
  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(Number(value)) && isValid;
  }

  if (rules.isNumericDecimal) {
    const pattern = /^[0-9]+(\.[0-9]{1,2})?$/;
    isValid = pattern.test(Number(value)) && isValid;
  }

  if (rules.isDay) {
    const pattern = /^\d+$/;
    isValid = pattern.test(Number(value)) && Number(value) < 32 && Number(value) > 0 && isValid;
  }

  if (rules.isUkTelephone) {
    // const pattern = /^\(?0( *\d\)?){9,10}$/;
    // isValid = pattern.test(Number(value)) && isValid;
    const numericOnly = /^\d+$/;
    isValid = numericOnly.test(Number(value)) && Number(value.slice(0, 1)) === 0 && isValid;
  }

  if (rules.isDate) {
    isValid = moment(value, 'DD/MM/YYYY', true).isValid();
  }

  if (rules.isValidPassword) {
    const pattern = /^.{6,}$/;
    isValid = value.trim() !== '' && pattern.test(value) && isValid;
  }

  if (rules.isYear) {
    const pattern = /^\d{4}$/;
    isValid = pattern.test(Number(value)) && isValid;
  }

  if (rules.isDateTime) {
    isValid = moment(value, 'DD/MM/YYYY h:mm a', true).isValid();
  }

  if (rules.isCommaSeparatedTime) {
    let timeValue = value.split(',');
    let isTimeValid = [];

    timeValue.forEach((time) => {
      isTimeValid.push(moment(time.trim(), 'h:mm a', true).isValid());
    });

    if (value) {
      isValid = !(isTimeValid.filter((elem) => elem === false).length > 0);
    } else {
      isValid = true;
    }
  }

  if (rules.isOldEnough) {
    isValid = moment().year() - value >= minimalUserAge;
  }

  return isValid;
};

export const returnErrorTextForField = (rules, minimalUserAge = 16) => {
  let errorText = 'This field is required';

  if (rules.restrictSpecialCharacter) {
    errorText = 'Only a-z, 0-9 characters and space can be used';
  }
  if (rules.restrictSpecialCharacterWithSpace) {
    errorText = 'Only a-z, 0-9 and - are allowed';
  }

  if (rules.isEmail) {
    errorText = 'Please enter a correct e-mail address';
  }

  if (rules.isNumeric) {
    errorText = 'Please enter a whole, positive number only';
  }

  if (rules.isNumericDecimal) {
    errorText = 'Please enter numbers only (correct format: XX.xx)';
  }
  if (rules.isValidPassword) {
    errorText = 'Password must contain minimum eight characters';
  }

  if (rules.isDay) {
    errorText = 'Please enter a whole, positive number between 0 and 31';
  }

  if (rules.isUkTelephone) {
    errorText = 'Please enter a valid UK telephone number with a leading 0';
  }

  if (rules.isDate) {
    errorText = 'Please enter a date in "DD/MM/YYYY" format';
  }

  if (rules.isYear) {
    errorText = 'Please enter a full year';
  }

  if (rules.isDateTime) {
    errorText = 'Please enter a date in "DD/MM/YYYY h:mm a" format';
  }
  if (rules.isDate) {
    errorText = 'Please enter a date in "DD/MM/YYYY" format';
  }
  if (rules.isTime) {
    errorText = 'Please enter a time in "h:mm a" format';
  }

  if (rules.isCommaSeparatedTime) {
    errorText = 'Please enter a date in "h:mm a" format';
  }

  if (rules.isOldEnough) {
    errorText = `Forbidden to sign up users under ${minimalUserAge} years old.`;
  }

  return errorText;
};
export const getCurrencySymbol = (value) =>
  value === undefined ? '£' : ALL_CURRENCIES.find((currency) => currency.value === value).symbol;
export const getCurrencyDetail = (value) =>
  value === undefined || value === null
    ? {
        label: 'Pound sterling',
        value: 'gbp',
        symbol: '£'
      }
    : ALL_CURRENCIES.find((currency) => currency.value === value);

export const returnCurrencyAndCostString = (currency, value) => getCurrencySymbol(currency) + ' ' + value;

export const proRatedCost = (membershipPlan) => {
  const startOfThisMonth = moment().startOf('month');
  const startOfNextMonth = moment().add(1, 'M').startOf('month');
  const costPerMilliseconds = membershipPlan.price / startOfNextMonth.diff(startOfThisMonth, 'milliseconds');
  return `${getCurrencySymbol(membershipPlan.currency)} ${(
    costPerMilliseconds * startOfNextMonth.diff(moment(), 'milliseconds')
  ).toFixed(2)}`;
};
