import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { delayFetchFunctions, orderAndPayForPass } from '../../../apiFunctions/apiFunctions';
import Icons from '../../../components/UI/Icons/Icons';
import { HashLink as Link } from 'react-router-hash-link';
import Button from 'grommet/components/Button';
import withUser from '../../../store/hoc/withUser';
import { getCurrencyDetail, returnErrorFromResponse } from '../../../shared/utility';
import Modal from 'react-modal';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Loading from '../../../components/Loading/Loading';
import classes from './SinglePass.module.scss';

const RenderPassActionButtons = ({
  sourcesList,
  isAdmin,
  isUser,
  classPass,
  editPass,
  goBackToPreviousPage,
  classType
}) => {
  const [toast, setToast] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const currency = getCurrencyDetail(classPass.currency).value;

  const mutateOrderAndPay = useMutation(() => orderAndPayForPass(classPass._id, 'web', currency), {
    onSuccess: () => {
      setShowModal(false);
      setToast({
        status: 'ok',
        msg: 'Successfully paid',
        show: true
      });
      delayFetchFunctions([['userData'], ['passes', classType]]);
      goBackToPreviousPage();
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  if (isAdmin) {
    return <Icons edit editClick={() => editPass(classPass)} />;
  }

  if (isUser) {
    if (sourcesList && sourcesList.length === 0 && classPass.cost > 0) {
      return (
        <div className={classes.addPaymentMethod}>
          To continue, please add a payment method <Link to="/gym/settings#payments">here.</Link>
        </div>
      );
    }

    const renderModal = (
      <Modal isOpen={showModal} className="ss-modal" onRequestClose={() => setShowModal(false)}>
        <div className="grommet">
          Do you want to pay for the selected product? <br />
          This product will be paid for using your default card payment. You can change it{' '}
          <Link to="/gym/settings#payments">here</Link> if you prefer.
          <div className="ss-modal__buttons-container">
            {mutateOrderAndPay.isLoading ? (
              <Loading topOffset={10} />
            ) : (
              <>
                <Button box label="Yes" secondary={true} onClick={mutateOrderAndPay.mutate} />
                <Button box label="Cancel" primary onClick={() => setShowModal(false)} />
              </>
            )}
          </div>
        </div>
      </Modal>
    );

    return (
      <>
        {renderModal}
        <CustomToast toast={toast} />
        <Button
          style={{ height: '40px', margin: 'auto 0px' }}
          data-cy="payBtn"
          label="Pay"
          secondary={true}
          onClick={() => setShowModal(true)}
        />
      </>
    );
  }

  return null;
};

export default withUser(RenderPassActionButtons);
