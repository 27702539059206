import axios from '../../axios-global';
import * as actionTypes from './actionTypes';
import { returnErrorFromResponse } from '../../shared/utility';

export const fetchLocations = () => (dispatch) => {
  dispatch(setLocationsStart());
  return axios
    .get('/gym/all')
    .then((response) => dispatch(setLocationsSuccess(response.data)))
    .catch((error) => dispatch(setLocationsFailed(returnErrorFromResponse(error))));
};

const setLocationsStart = () => {
  return {
    type: actionTypes.SET_LOCATIONS_START
  };
};

const setLocationsSuccess = (locations) => {
  return {
    type: actionTypes.SET_LOCATIONS_SUCCESS,
    locations
  };
};

const setLocationsFailed = (error) => {
  return {
    type: actionTypes.SET_LOCATIONS_FAILED,
    error
  };
};

export const fetchCityNames = () => {
  return (dispatch) => {
    dispatch(fetchCityNamesStart());

    axios
      .get('/gym/cities')
      .then((response) => dispatch(fetchCityNamesSuccess(response.data)))
      .catch((error) => dispatch(fetchCityNamesFailed(returnErrorFromResponse(error))));
  };
};

const fetchCityNamesStart = () => {
  return {
    type: actionTypes.FETCH_CITY_NAMES_START
  };
};

const fetchCityNamesSuccess = (cityNames) => {
  return {
    type: actionTypes.FETCH_CITY_NAMES_SUCCESS,
    cityNames
  };
};

const fetchCityNamesFailed = (error) => {
  return {
    type: actionTypes.FETCH_CITY_NAMES_FAILED,
    error
  };
};
