import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../../axios-global';

import ListItem from 'grommet/components/ListItem';
import Toast from 'grommet/components/Toast';
import Layer from 'grommet/components/Layer';
import Box from 'grommet/components/Box';
import { Label, Form, FormField, TextInput, Button, Notification } from 'grommet';

import Icons from '../../../../components/UI/Icons/Icons';

import * as actions from '../../../../store/actions/index';

import classes from '../manageGym.module.scss';

const cloneDeep = require('lodash.clonedeep');

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId))
  };
};

export class SingleName extends Component {
  state = {
    showConfirm: false,
    showLayer: false,
    isValid: true,
    value: null,
    savingFailed: false
  };

  componentDidMount() {
    this.setState({ value: this.props.element ? this.props.element : null });
  }

  handleDelete = () => {
    if (!this.state.showConfirm) {
      return this.setState({
        showConfirm: true
      });
    }
    let { associatedNames } = this.props.selectedGym;
    associatedNames.splice(associatedNames.indexOf(this.props.element), 1);

    axios
      .patch(`/gym/${this.props.selectedGym._id}`, {
        associatedNames: associatedNames
      })
      .then(() => {
        this.setState({ loading: false });
        this.props.onFetchGymDetails(this.props.selectedGym._id);
        this.handleModalClose();
      })
      .catch(() => {
        this.setState({
          loading: false
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
  };

  handleEdit = () => {
    this.setState({
      showLayer: true
    });
  };

  handleModalClose = () => {
    this.setState({
      showLayer: false
    });
  };

  closeToast = () => {
    this.setState({
      showConfirm: false
    });
  };

  renderNotification = () => {
    if (this.state.showConfirm) {
      return (
        <Toast status="warning" onClose={this.closeToast}>
          Click once again to confirm the deletion
        </Toast>
      );
    }
  };

  saveName = () => {
    let copiedNames = cloneDeep(this.props.selectedGym.associatedNames);
    copiedNames[copiedNames.indexOf(this.props.element)] = this.state.value;

    if (copiedNames[copiedNames.indexOf(this.props.element)] !== this.state.value) {
      axios
        .patch(`/gym/${this.props.selectedGym._id}`, {
          associatedNames: copiedNames
        })
        .then(() => {
          this.setState({ loading: false });
          this.props.onFetchGymDetails(this.props.selectedGym._id);
          this.handleModalClose();
        })
        .catch(() => {
          this.setState({
            loading: false,
            savingFailed: true
          });
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        });
    }
  };

  render() {
    return (
      <>
        {this.renderNotification()}

        {this.state.showLayer ? (
          <>
            <Layer
              align={'center'}
              closer={true}
              hidden={false}
              overlayClose={true}
              onClose={() => this.setState({ showLayer: false })}>
              <Box pad={'large'}>
                <Form>
                  <Label>Enter associated name</Label>
                  {this.state.savingFailed ? (
                    <Notification
                      message="Something went wrong. Try again"
                      status="waring"
                      className="ss-top-notification"
                    />
                  ) : null}
                  <FormField>
                    <TextInput
                      defaultValue={this.props.element.toString()}
                      placeHolder={'Enter associated name'}
                      onDOMChange={(event) => {
                        const newValue = event.value ? event.value : event.target.value;
                        var newStr = newValue.replace(/[a-zA-Z0-9-_]/g, '');
                        return this.setState({
                          isValid: newValue.length > 0 && newStr.length === 0,
                          value: newValue
                        });
                      }}
                    />
                  </FormField>
                  {this.state.isValid ? (
                    <div className="container--center">
                      <Button label="Save" secondary onClick={this.saveName} />
                    </div>
                  ) : (
                    <p style={{ color: 'red' }}>
                      {' '}
                      Field is not valid. Please enter only letter and numeric characters.
                    </p>
                  )}
                </Form>
              </Box>
            </Layer>
          </>
        ) : null}

        <ListItem direction="column" align="start" separator="horizontal">
          <div className={classes.singleName}>
            <span className={classes.singleNameText}>
              <h4>{this.props.element}</h4>
            </span>

            <Icons ref={this.icons} edit delete editClick={this.handleEdit} deleteClick={this.handleDelete} />
          </div>
        </ListItem>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleName);
