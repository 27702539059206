import React from 'react';
import { Select } from 'grommet';
import useSelectDropdownFilter from '../hooks/useSelectDropdownFilter';

const allFilter = [
  {
    value: 'all',
    label: `Any`
  }
];

const ClientGymMembershipFilter = ({ onFilter, title, filters }) => {
  const { onSelectedFilterChanged, selectedFilter } = useSelectDropdownFilter(allFilter, onFilter);

  if (!filters) {
    return null;
  }

  const gymMemberships = allFilter.concat(
    filters.map((x) => {
      return {
        value: x._id,
        label: `${x.name}`
      };
    })
  );

  return (
    <div className="client__gym-membership-filter">
      <h4>{title}</h4>
      <Select options={gymMemberships} value={selectedFilter} onChange={onSelectedFilterChanged} />
    </div>
  );
};

export default ClientGymMembershipFilter;
