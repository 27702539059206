import axios from '../axios-global';
import { queryClient } from '../SsApp';
import { updateUserData } from '../store/actions/index';
import { store } from '../index';
import moment from 'moment';
export const delayFetchFunctions = (array, timeout = 1500) =>
  setTimeout(() => array.forEach((item) => queryClient.invalidateQueries(item)), timeout);

/**
 *         CLASS TYPES / EVENTS
 */
export const fetchClassesByAdmin = (
  classType,
  startDate = moment().format('DD-MM-YYYY'),
  endDate = moment().format('DD-MM-YYYY').add(14, 'D')
) =>
  axios
    .get(`/class/all`, {
      params: {
        classType,
        startDate,
        endDate
      }
    })
    .then((resp) => resp.data);

export const fetchClassesPerScheduleId = (scheduleId) =>
  axios.get(`/class/scheduledClasses/${scheduleId}`).then((resp) => resp.data);

export const fetchSingleClass = (classId) => axios.get(`/class/${classId}`).then((resp) => resp.data);

export const fetchUpcomingClassesInAllGyms = (classType) =>
  axios.get(`/class/all`, { params: { classType } }).then((resp) => resp.data);

export const fetchUpcomingClasses = (classType, gymId = 'all', startDate = null, endDate = null) => {
  const params =
    startDate && endDate
      ? {
          startDate,
          endDate
        }
      : null;
  return axios.get(`/class/gym/${gymId}/${classType}`, { params }).then((resp) => resp.data);
};

export const fetchClassesHistory = (classType, gymId = 'all') =>
  axios.get(`/class/${classType}/gym/${gymId}/history`).then((resp) => resp.data);

export const fetchClientUpcomingClasses = (classType) =>
  axios.get(`/users/${classType}/upcoming`).then((resp) => resp.data);

export const joinClass = (classId, userId, isSinglePayment = false) =>
  axios
    .post(`/class/${classId}`, {
      userId: userId,
      isSinglePayment
    })
    .then((resp) => resp.data);

export const payForClass = (attendanceId, stripeOrderId) =>
  axios
    .post(`/attendance/${attendanceId}/payment`, {
      stripeOrderId: stripeOrderId,
      method: 'web'
    })
    .then((resp) => resp.data);

export const signUpAndPayForClass = async (classId, userId, isSinglePayment = false) => {
  const joined = await joinClass(classId, userId, isSinglePayment);
  if (joined.stripeOrderId) {
    const payed = await payForClass(joined.attendanceId, joined.stripeOrderId);
    return payed;
  } else {
    return joined;
  }
};

export const changeAttendanceStatus = (attendanceId, status) =>
  axios.patch(`/attendance/${attendanceId}`, { status }).then((resp) => resp.data);

export const cancelAttendance = (attendanceId, userId = null) =>
  axios.patch(`/attendance/${attendanceId}/cancel`, userId && { userId }).then((resp) => resp.data);

export const buyProductAndUpdateAttendance = (attendanceId, method, userId, productId, isSinglePayment) =>
  axios
    .post(`/attendance/${attendanceId}/payByOtherMethod`, {
      method,
      userId,
      productId,
      isSinglePayment
    })
    .then((resp) => resp.data);

export const cancelClass = (classId, shouldAdminChargeClients = false) =>
  axios.delete(`/class/${classId}/checkTiming/${shouldAdminChargeClients}`).then((resp) => resp.data);

export const activateClass = (classId) => axios.patch(`/class/${classId}`, { active: true }).then((resp) => resp.data);

export const addClass = (gymId, classType, formData) =>
  axios.post(`/class/gym/${gymId}/create/${classType}`, formData).then((resp) => resp.data);

export const editClass = (classId, formData) => axios.patch(`/class/${classId}`, formData).then((resp) => resp.data);

export const createClassAndSignUpAllUsersToClass = async (gymId, classType, formData, usersList) => {
  await new Promise((resolve, reject) =>
    addClass(gymId, classType, formData)
      .then(async (newClass) => {
        for (const client of usersList) {
          try {
            await joinClass(newClass._id, client._id);
          } catch (error) {
            await cancelClass(newClass._id);
            return reject(new Error(`Error: ${client.name} ${client.last_name} cannot sign up`));
          }
        }
        return resolve('Successfully created');
      })
      .catch((error) => reject(new Error(error)))
  );
};
export const bulkClassEditing = (data) =>
  axios.patch(`/class/update/multipleClasses`, { data }).then((resp) => resp.data);

/**
 *          USER
 */
export const fetchUserData = () =>
  axios.get(`/users/me`).then((resp) => {
    store.dispatch(updateUserData(resp.data));
    return resp.data;
  });
export const fetchUsersListAllData = (gymId) => axios.get(`users/gym/${gymId}/all`).then((resp) => resp.data);
export const fetchUsersList = (gymId) => axios.get(`users/gym/${gymId}`).then((resp) => resp.data);

/**
 *          TRAINER
 */
export const fetchTrainers = (gymId) => axios.get(`/users/gym/${gymId}/trainers`).then((resp) => resp.data);

/**
 *          PASSES
 */
export const fetchPasses = (classType, gymId) =>
  axios.get(`/products/passes/${classType}Pass/${gymId}/all`).then((resp) => resp.data);
export const orderPass = (passId, method = 'web') =>
  axios.post(`/products/passes/${passId}/order`, { method }).then((resp) => resp.data);
export const payForPass = (productId, stripeOrderId, method = 'web', currency) =>
  axios.post(`/products/passes/pay`, { productId, stripeOrderId, method, currency }).then((resp) => resp.data);
export const orderAndPayForPass = async (passId, method = 'web', currency) => {
  const order = await orderPass(passId, method);
  if (order.stripeOrderId) {
    const payed = await payForPass(passId, order.stripeOrderId, method, currency);
    return payed;
  } else {
    return order;
  }
};
export const addPass = (classType, body = {}) =>
  axios.post(`/products/passes/${classType}`, body).then((resp) => resp.data);
export const editPass = (passId, body = {}) =>
  axios.patch(`/products/passes/${passId}`, body).then((resp) => resp.data);

/**
 *          TEMPLATES
 */

export const fetchTemplates = (gymId, classType = 'all') =>
  axios.get(`/class-template/gym/${gymId}?classType=${classType}`).then((resp) => resp.data);
export const addTemplate = (gymId, body) => axios.post(`/class-template/gym/${gymId}`, body).then((resp) => resp.data);
export const editTemplate = (templateId, body) =>
  axios.patch(`/class-template/${templateId}`, body).then((resp) => resp.data);
export const deleteTemplate = (templateId) => axios.delete(`/class-template/${templateId}`).then((resp) => resp.data);

/**
 *          SCHEDULED CLASSES
 */
export const fetchAllScheduledClasses = (gymId) => axios.get(`/scheduled-class/gym/${gymId}`).then((resp) => resp.data);
export const fetchScheduledClasses = (classType, gymId) =>
  axios.get(`/scheduled-class/gym/${gymId}/${classType}`).then((resp) => resp.data);
export const addScheduledClass = (gymId, body = {}) =>
  axios.post(`/scheduled-class/gym/${gymId}`, body).then((resp) => resp.data);
export const editScheduledClass = (scheduledId, body = {}) =>
  axios.patch(`/scheduled-class/${scheduledId}`, body).then((resp) => resp.data);
export const deleteScheduledClass = (scheduledId) =>
  axios.delete(`/scheduled-class/${scheduledId}`).then((resp) => resp.data);

/**
 *          FAQS
 */
export const fetchFAQs = (gymId) => axios.get(`/faq/all/gym/${gymId}`).then((resp) => resp.data);
export const addFAQ = (gymId, body = {}) => axios.post(`/faq/add/gym/${gymId}`, body).then((resp) => resp.data);
export const editFAQ = (faqId, body = {}) => axios.patch(`/faq/${faqId}`, body).then((resp) => resp.data);
export const deleteFAQ = (faqId) => axios.delete(`/faq/${faqId}`).then((resp) => resp.data);
export const uploadFAQJsonFile = (gymId, body = {}) =>
  axios.post(`faq/add/json/gym/${gymId}`, body).then((resp) => resp.data);
