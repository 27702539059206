import React, { useRef, useState, useEffect } from 'react';
import classes from './LiveStreamViewer.module.scss';
import Button from 'grommet/components/Button';
import AlertIcon from 'grommet/components/icons/base/Alert';
import Toast from 'grommet/components/Toast';
import Messenger from '../../Messenger/Messenger';

const LiveStreamViewer = (props) => {
  const rtcPeerConnection = useRef();
  const [sessionId, setSessionId] = useState();
  const [sessionType] = useState('viewer');
  const [signalServerUnavailable, setSignalServerUnavailable] = useState(false);
  const webSocket = useRef();

  useEffect(
    () => {
      webSocketAdapter();
      return function closeWebSocketSession() {
        webSocket.current.close();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const webSocketAdapter = () => {
    if (!webSocket.current) {
      webSocket.current = new WebSocket(process.env.REACT_APP_LIVE_STREAM_WEBSOCKET_SERVER_URL);
    }

    webSocket.current.onopen = () => {
      const messageData = {
        type: 'welcome',
        message: 'Welcome',
        name: props.userData.name
      };
      addMessageToWindow(messageData);
    };

    webSocket.current.onmessage = (event) => {
      const serverResponse = JSON.parse(event.data);
      if (serverResponse.signalType === 'session') {
        setSessionId(serverResponse.sessionId);
        handleSession(serverResponse);
      } else if (serverResponse.signalType === 'candidate') {
        handleCandidate(serverResponse);
      } else if (serverResponse.signalType === 'offer') {
        handleOffer(serverResponse);
      } else if (serverResponse.signalType === 'message') {
        addMessageToWindow(serverResponse);
      }
    };

    webSocket.current.onerror = () => {
      setSignalServerUnavailable(true);
    };

    webSocket.current.onclose = () => {};
  };

  const handleSession = (serverResponse) => {
    const typeRequest = {
      signalType: 'type',
      sessionType: sessionType,
      sessionId: serverResponse.sessionId
    };
    webSocket.current.send(JSON.stringify(typeRequest));
  };

  const handleJoin = () => {
    const viewerRequest = {
      signalType: sessionType,
      sessionType: sessionType,
      sessionId: sessionId
    };
    webSocket.current.send(JSON.stringify(viewerRequest));
  };

  const handleCandidate = (serverResponse) => {
    rtcPeerConnection.current.addIceCandidate(new RTCIceCandidate(serverResponse.iceCandidate)).catch((err) =>
      // eslint-disable-next-line
      console.error(err)
    );
  };

  const handleOffer = (serverResponse) => {
    const joinStreamButton = document.getElementById('join');
    joinStreamButton.disabled = true;
    const joinStreamButtonPanel = document.getElementById('join-stream-button-panel');
    joinStreamButtonPanel.style.display = 'none';

    const config = {
      iceServers: [
        {
          urls: [process.env.REACT_APP_LIVE_STREAM_STUN_SERVERS]
        }
      ]
    };

    rtcPeerConnection.current = new RTCPeerConnection(config);

    rtcPeerConnection.current
      .setRemoteDescription(serverResponse.sdpOffer)
      .then(() => rtcPeerConnection.current.createAnswer())
      .then((sdp) => rtcPeerConnection.current.setLocalDescription(sdp))
      .then(() => {
        const answerRequest = {
          signalType: 'answer',
          sessionType: sessionType,
          sessionId: serverResponse.sessionId,
          sdpAnswer: rtcPeerConnection.current.localDescription
        };
        webSocket.current.send(JSON.stringify(answerRequest));
      });

    rtcPeerConnection.current.ontrack = (event) => {
      const videoPanelDiv = document.getElementById('video-panel');
      const videoViewer = document.getElementById('video-viewer');
      videoPanelDiv.style.display = 'block';
      videoViewer.srcObject = event.streams[0];
    };

    rtcPeerConnection.current.onicecandidate = (event) => {
      if (event.candidate) {
        const candidateRequest = {
          signalType: 'candidate',
          sessionType: sessionType,
          sessionId: serverResponse.sessionId,
          iceCandidate: event.candidate
        };
        webSocket.current.send(JSON.stringify(candidateRequest));
      }
    };
  };

  const addMessageToWindow = (messageData) => {
    const messageWindow = document.getElementById('messages');
    let message = `<div style="color:${messageData.color}">${messageData.name}: ${messageData.message}</div>`;
    if (messageData.type === 'welcome') {
      message = `<div>${messageData.message}&nbsp;${messageData.name}</div>`;
    }
    messageWindow.innerHTML += message;
    messageWindow.scrollTop = messageWindow.scrollHeight;
  };

  const renderNotification = () => {
    if (signalServerUnavailable) {
      return <Toast status="critical">Unable to connect to the live stream server</Toast>;
    }
  };

  return (
    <>
      {renderNotification()}
      <div>
        <div id="video-panel">
          <video id="video-viewer" autoPlay controls></video>
        </div>
        {signalServerUnavailable ? (
          <div className={classes.signalServerErrorMessage}>
            <AlertIcon />
            &nbsp;Live stream server is currently unavailable&nbsp;
            <AlertIcon />
          </div>
        ) : (
          <div id="join-stream-button-panel" className={classes.joinStreamButtonPanel}>
            <Button id="join" label="Join" secondary={true} onClick={handleJoin} />
          </div>
        )}
        <Messenger
          webSocket={webSocket.current}
          userName={props.userData.name}
          sessionType={sessionType}
          signalServerUnavailable={signalServerUnavailable}
        />
      </div>
    </>
  );
};

export default LiveStreamViewer;
