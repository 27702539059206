import React, { Component } from 'react';
import { connect } from 'react-redux';

import axios from '../../axios-global';
import location from './location.module.scss';
import Button from 'grommet/components/Button';
import Error404 from '../../components/Error404/Error404';
import { withRouter, Route, Switch } from 'react-router-dom';
import * as actions from '../../store/actions';
import PublicClassPage from '../PublicClassPage/PublicClassPage';
import Title from '../../components/UI/Title/Title';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import ManageFAQ from '../FAQ/ManageFAQ';
import Loading from '../../components/Loading/Loading';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userData: state.auth.userData,
    selectedGym: state.admin.selectedGym,

    cityNames: state.location.cityNames,
    cityNamesLoading: state.location.cityNamesLoading,
    cityNamesError: state.location.cityNamesError
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId))
  };
};

class Location extends Component {
  state = {
    gym: null,
    loading: true,
    err: null,
    errCityName: false
  };

  componentDidMount() {
    if (this.props.match.params.city !== 'gym') {
      axios
        .get(`/gym/location/${this.props.match.params.city}/${this.props.match.params.urlName}`, {})
        .then((resp) => {
          this.props.onFetchGymDetails(resp.data.gymId);
          this.setState({
            loading: false,
            gym: resp.data
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            err: err.response
          });
        });
    }
  }

  cityNameIsCorrect = () => {
    let smallLetters = this.props.cityNames.map((city) => city.toLowerCase());
    return smallLetters.includes(this.props.match.params.city);
  };

  renderRoutes = () => {
    return (
      <>
        <Switch>
          <Route path={this.props.match.url} exact render={() => this.showGymHomepage()} />

          {this.state.gym && (
            <>
              <Route
                path={`${this.props.match.url}/faq`}
                exact
                render={() => (
                  <WhiteBackground>
                    <Title
                      header="FAQ"
                      subHeader="See the FAQs below for further information about the services we offer."
                    />
                    <ManageFAQ selectedGymId={this.state.gym.gymId} />
                  </WhiteBackground>
                )}
              />

              <Route path={`${this.props.match.url}/class/:classId`} exact render={PublicClassPage} />
            </>
          )}
        </Switch>
      </>
    );
  };

  showGymHomepage = () => {
    if (this.state.gym) {
      return (
        <div className={location.backgroundImg}>
          {!this.props.isAuthenticated && (
            <div className={location.hero__box}>
              <h2 className={location.box__header}>Sign up</h2>

              <p className={location.box__additionalInfo}>Sign up here.</p>

              <div className={location.boxesContainer}>
                <Button
                  label="LOGIN"
                  className={`${location.visibleMobile} ${location.btnLogin}`}
                  path={`/login`}
                  primary={true}
                />
                <Button label="SIGN UP" path={`/gym/${this.state.gym.gymId}/signup`} primary={true} />
              </div>
            </div>
          )}
        </div>
      );
    }

    if (this.cityNameIsCorrect()) {
      return (
        <div className={location.incorrectBackground}>
          <div className={location.hero__box}>
            <h2 className={location.box__header}>URL ADDRESS IS INCORRECT</h2>

            <p className={location.box__additionalInfo}>
              Please check the URL address or contact us for more information.
            </p>

            <div className={location.boxesContainer}>
              <Button label="CONTACT US" path="/contact" primary={true} />
            </div>
          </div>
        </div>
      );
    }
    return <Error404 />;
  };

  render() {
    if (this.props.cityNamesLoading || this.state.loading) {
      return <Loading size="medium" />;
    }

    return this.renderRoutes();
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Location));
