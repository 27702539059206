import React from 'react';

import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';

export default () => (
  <>
    <h1>Cookies & Privacy</h1>

    <Heading tag="h3">Privacy policy</Heading>

    <Paragraph>
      Barons Eden understands that your privacy is important to you and that you care about how your personal data is
      used and shared online. We respect and value the privacy of everyone who visits this website, baronseden.com (“Our
      Site”) and will only collect and use personal data in ways that are described here, and in a manner that is
      consistent with Our obligations and your rights under the law. Please read this Privacy Policy carefully and
      ensure that you understand it. You will be required to read and accept this Privacy Policy when signing up for an
      Account. If you do not accept and agree with this Privacy Policy, you must stop using Our Site immediately.
    </Paragraph>

    <Heading tag="h3">1. Definitions and Interpretation</Heading>
    <Paragraph>In this Policy, the following terms shall have the following meanings:</Paragraph>
    <Paragraph>
      <b>“Account” |</b> means an account required to access and/or use certain areas and features of Our Site; <br />
      <b>“Cookie” |</b> means a small text file placed on your computer or device by Our Site when when you visit
      certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our
      Site are set out in section 13, below;
      <br />
      <b>“Cookie Law” |</b> means the relevant parts of the Privacy and Electronic Communications (EC Directive)
      Regulations 2003;
      <br />
      <b>“personal data” |</b> means any and all data that relates to an identifiable person who can be directly or
      indirectly identified from that data. In this case, it means personal data that you give to Us via Our Site. This
      definition shall, where applicable, incorporate the definitions provided in the EU Regulation 2016/679 – the
      General Data Protection Regulation (“GDPR”); and
      <br />
      <b>“We/Us/Our” |</b> means Barons Eden, a limited company registered in England under company number 09121021,
      whose registered address is Barons Eden, 8 The Shippon 8 The Shippon, Churchill Heath Farm, Kingham, Oxfordshire,
      England, OX7 6UJ.
    </Paragraph>

    <Heading tag="h3">2. Information About Us</Heading>
    <ol type="1">
      <li>
        Our Site is owned and operated by Barons Eden, a limited company registered in England under company number
        09121021, whose registered address is Barons Eden, 8 The Shippon 8 The Shippon, Churchill Heath Farm, Kingham,
        Oxfordshire, England, OX7 6UJ.
      </li>
      <li>Our VAT number is 186912375</li>
      <li>
        Our Data Protection Officer can be contacted by email at info@baronseden.com by telephone on 01608 670346, or by
        post at Barons Eden, 8 The Shippon 8 The Shippon, Churchill Heath Farm, Kingham, Oxfordshire, England, OX7 6UJ.
      </li>
    </ol>

    <Heading tag="h3">3. What Does This Policy Cover?</Heading>
    <Paragraph>
      This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please
      note that We have no control over how your data is collected, stored, or used by other websites and We advise you
      to check the privacy policies of any such websites before providing any data to them.
    </Paragraph>

    <Heading tag="h3">4. Your Rights</Heading>
    <ol type="1">
      <li>
        As a data subject, you have the following rights under the GDPR, which this Policy and Our use of personal data
        have been designed to uphold:
        <ol type="a">
          <li>The right to be informed about Our collection and use of personal data</li>
          <li>The right of access to the personal data We hold about you (see section 12);</li>
          <li>
            The right to rectification if any personal data We hold about you is inaccurate or incomplete (please
            contact Us using the details in section 14);
          </li>
          <li>
            The right to be forgotten – i.e. the right to ask us to delete any personal data We hold about you (We only
            hold your personal data for a limited time, as explained in section 6 but if you would like Us to delete it
            sooner, please Contact us using the details in section 14);
          </li>
          <li>The right to restrict (i.e. prevent) the processing of your personal data;</li>
          <li>
            {' '}
            The right to data portability (obtaining a copy of your personal data to re-use with another service or
            organisation);
          </li>
          <li>The right to object to Us using your personal data for particular purposes; and</li>
          <li>Rights with respect to automated decision making and profiling.</li>
        </ol>
      </li>
      <li>
        If you have any cause for complaint about Our use of your personal data, please contact Us using the details
        provided in section 14 and We will do Our best to solve the problem for you. If We are unable to help, you also
        have the right to lodge a complaint with the UK’s supervisory authority, the Information Commissioner’s Office.
      </li>
      <li>
        For further information about your rights, please contact the Information Commissioner’s Office or your local
        Citizens Advice Bureau.
      </li>
    </ol>

    <Heading tag="h3">5. What Data Do We Collect?</Heading>
    <Paragraph>
      Depending upon your use of Our Site, we may collect some or all of the following personal and non-personal data
      (please also see section 13 on Our use of Cookies and similar technologies.
      <ol type="1">
        <li>name;</li>
        <li>date of birth;</li>
        <li>gender;</li>
        <li>business/company name</li>
        <li>job title;</li>
        <li>profession;</li>
        <li>contact information such as email addresses and telephone numbers;</li>
        <li>demographic information such as post code, preferences, and interests;</li>
        <li>financial information such as credit / debit card numbers;</li>
        <li>IP address;</li>
        <li>web browser type and version;</li>
        <li>operating system;</li>
        <li>a list of URLs starting with a referring site, your activity on Our Site, and the site you exit to;</li>
      </ol>
    </Paragraph>

    <Heading tag="h3">6. How Do We Use Your Data?</Heading>
    <ol type="1">
      <li>
        All personal data is processed and stored securely, for no longer than is necessary in light of the reason(s)
        for which it was first collected. We will comply with our obligations and safeguard your rights under the GDPR
        at all times. For more details on security see section 7, below.
      </li>
      <li>
        Our use of your personal data will always have a lawful basis, either because it is necessary for Our
        performance of a contract with you, because you have consented to our use of your personal data (e.g. by
        subscribing to emails), or because it is in our legitimate interests. Specifically, we may use your data for the
        following purposes <br />
        <ol type="a">
          <li>Providing and managing your Account;</li>
          <li>Providing and managing your access to Our Site;</li>
          <li>Personalising and tailoring your experience on Our Site;</li>
          <li>
            Supplying Our products AND/OR services to you (please note that We require your personal data in order to
            enter into a contract with you);
          </li>
          <li>Personalising and tailoring Our products AND/OR services for you;</li>
          <li>Replying to emails from you;</li>
          <li>
            Supplying you with emails that you have opted into (you may unsubscribe or opt-out at any time by following
            the unsubscribe link on any marketing email we send you;
          </li>
          <li>Market research;</li>
          <li>
            Analysing your use of Our Site and gathering feedback to enable Us to continually improve Our Site and your
            user experience;
          </li>
        </ol>
      </li>
      <li>
        With your permission and/or where permitted by law, we may also use your data for marketing purposes which may
        include contacting you by email, and post with information, news and offers on Our products and services. We
        will not, however, send you any unsolicited marketing or spam and will take all reasonable steps to ensure that
        We fully protect your rights and comply with Our obligations under the GDPR and the Privacy and Electronic
        Communications (EC Directive) Regulations 2003.
      </li>
      <li>
        Third parties (including Facebook, Twitter, Instagram, YouTube, Google+ and any other services we employ) whose
        content appears on Our Site may use third party Cookies, as detailed below in section 13. Please refer to
        section 13 for more information on controlling Cookies. Please note that We do not control the activities of
        such third parties, nor the data they collect and use and advise you to check the privacy policies of any such
        third parties.
      </li>
      <li>
        You have the right to withdraw your consent to Us using your personal data at any time, and to request that We
        delete it.
      </li>
      <li>
        We do not keep your personal data for any longer than is necessary in light of the reason(s) for which it was
        first collected. Data will therefore be retained for the following periods (or its retention will be determined
        on the following bases):
        <br />
        <ol type="a">
          <li>
            Your data will be held on our system for 12 months. Should you wish for your information to be removed at
            any point during this period, a request must be submitted;
          </li>
        </ol>
      </li>
    </ol>

    <Heading tag="h3">7. How and Where Do We Store Your Data?</Heading>
    <ol type="1">
      <li>
        We only keep your personal data for as long as we need to in order to use it as described above in section 6,
        and/or for as long as we have your permission to keep it.
      </li>
      <li>Your data will be held securely in a database that follows strict Data Protection Guidelines.</li>
      <li>
        Data security is very important to Us, and to protect your data We have taken suitable measures to safeguard and
        secure data collected through Our Site.
      </li>
      <li>
        Steps We take to secure and protect your data include: <br />
        <ol type="a">
          <li> Secure connections via SSL, using the latest approved standard.</li>
          <li>Restricted access to authorised persons of any internal systems.</li>
          <li>Compliance with industry-standard regulations in the management of data through online services.</li>
        </ol>
      </li>
    </ol>

    <Heading tag="h3">8. Do We Share Your Data?</Heading>
    <ol type="1">
      <li>
        We may share your data with other companies in our group for marketing and research purposes. This includes our
        subsidiaries AND/OR our holding company and its subsidiaries. We may sometimes use third parties for marketing
        purpose and to supply products and services to you on our behalf. These may include payment processing, delivery
        of goods, search engine facilities, advertising, and marketing. In some cases, the third parties may require
        access to some or all of your data. Where any of your data is required for such a purpose, we will take all
        reasonable steps to ensure that your data will be handled safely, securely, and in accordance with your rights,
        our obligations, and the obligations of the third party under the law.
      </li>
      <li>
        We may compile statistics about the use of Our Site including data on traffic, usage patterns, user numbers,
        sales, and other information. All such data will be anonymised and will not include any personally identifying
        data, or any anonymised data that can be combined with other data and used to identify you. We may from time to
        time share such data with third parties such as prospective investors, affiliates, partners, and advertisers.
        Data will only be shared and used within the bounds of the law.
      </li>
      <li>
        We may sometimes use third party data processors that are located outside of the European Economic Area (“the
        EEA”) (The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein). Where We transfer any
        personal data outside the EEA, we will take all reasonable steps to ensure that your data is treated as safely
        and securely as it would be within the UK and under the GDPR including:
        <br />
        <ol type="a">
          <li>Secure connections via SSL, using the latest approved standard;</li>
          <li>Restricted access to authorised persons of any internal systems;</li>
          <li>Compliance with industry-standard regulations in the management of data through online services;</li>
        </ol>
      </li>
      <li>
        In certain circumstances, We may be legally required to share certain data held by Us, which may include your
        personal data, for example, where We are involved in legal proceedings, where We are complying with legal
        requirements, a court order, or a governmental authority.
      </li>
    </ol>

    <Heading tag="h3">9. What Happens If Our Business Changes Hands?</Heading>
    <ol type="1">
      <li>
        We may, from time to time, expand or reduce Our business and this may involve the sale and/or the transfer of
        control of all or part of Our business. Any personal data that you have provided will, where it is relevant to
        any part of Our business that is being transferred, be transferred along with that part and the new owner or
        newly controlling party will, under the terms of this Privacy Policy, be permitted to use that data only for the
        same purposes for which it was originally collected by Us.
      </li>
      <li>
        In the event that any of your data is to be transferred in such a manner, you will not be contacted in advance
        and informed of the changes. When contacted you will not, however, be given the choice to have your data deleted
        or withheld from the new owner or controller.
      </li>
    </ol>

    <Heading tag="h3">10. How Can You Control Your Data?</Heading>
    <ol type="1">
      <li>
        In addition to your rights under the GDPR, set out in section 4, when you submit personal data via Our Site, you
        may be given options to restrict Our use of your data. In particular, We aim to give you strong controls on Our
        use of your data for direct marketing purposes (including the ability to opt-out of receiving emails from Us
        which you may do by unsubscribing using the links provided in Our emails and at the point of providing your
        details and by managing your Account).
      </li>
      <li>
        You may also wish to sign up to one or more of the preference services operating in the UK: The Telephone
        Preference Service (“the TPS”), the Corporate Telephone Preference Service (“the CTPS”), and the Mailing
        Preference Service (“the MPS”). These may help to prevent you receiving unsolicited marketing. Please note,
        however, that these services will not prevent you from receiving marketing communications that you have
        consented to receive.
      </li>
    </ol>

    <Heading tag="h3">11. Your Right to Withhold Information</Heading>
    <Paragraph>
      You may access [certain areas of] Our Site without providing any data at all. [However, to use all features and
      functions available on Our Site you may be required to submit or allow for the collection of certain data. <br />
      You may restrict Our use of Cookies. For more information, see section 13.
    </Paragraph>

    <Heading tag="h3">12. How Can You Access Your Data?</Heading>
    <Paragraph>
      You have the right to ask for a copy of any of your personal data held by Us (where such data is held). Under the
      GDPR, no fee is payable, and we will provide any and all information in response to your request free of charge.
      Please contact us for more details at info@baronseden.com, or using the contact details below in section 14.
    </Paragraph>

    <Heading tag="h3">13. Our Use of Cookies</Heading>
    <Paragraph>
      Our Site may place and access certain first-party Cookies on your computer or device. First party Cookies are
      those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of
      Our Site and to provide and improve Our products <b>AND/OR</b> services. By using Our Site, you may also receive
      certain third-party Cookies on your computer or device. Third-party Cookies are those placed by websites,
      services, and/or parties other than Us. Third-party Cookies are used on Our Site for e-commerce, managing your
      online account and how you use our website. In addition, Our Site uses analytics services provided by Google
      Analytics, which also use Cookies. Website analytics refers to a set of tools used to collect and analyse usage
      statistics, enabling us to better understand how people use Our Site. For more details, please refer to Our{' '}
      <b>Cookie Policy.</b>
    </Paragraph>

    <Heading tag="h3">14. Contacting Us</Heading>
    <Paragraph>
      If you have any questions about Our Site or this Privacy Policy, please contact us by email at{' '}
      <b>info@baronseden.com,</b> by telephone on 01608 670346, or by post at Barons Eden – GDPR, 8 The Shippon 8 The
      Shippon, Churchill Heath Farm, Kingham, Oxfordshire, England, OX7 6UJ. Please ensure that your query is clear,
      particularly if it is a request for information about the data, we hold about you (as under section 12, above).
    </Paragraph>

    <Heading tag="h3">15. Changes to Our Privacy Policy</Heading>
    <Paragraph>
      We may change this Privacy Policy from time to time (for example, if the law changes). Any changes will be
      immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your
      first use of Our Site following the alterations. We recommend that you check this page regularly to keep up to
      date.
    </Paragraph>

    <Heading tag="h3">Data Collection for Covid-19 Track and Trace. </Heading>
    <Paragraph>
      We will request your name, email address and/or telephone number on arrival which will be held securely on our
      booking system along with your date of arrival.
    </Paragraph>
    <Paragraph>
      Under Covid-19 government guidelines, we can request this data, hold this data but cannot remove data at your
      request unless 21 days has passed since you left the venue.
    </Paragraph>
    <Paragraph>
      This does not affect your rights to:-
      <ol type="1">
        <li>Request data we hold about you</li>
        <li>Request to be forgotten excluding track and trace data</li>
      </ol>
    </Paragraph>
  </>
);
