import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../../axios-global';

import Button from 'grommet/components/Button';
import Notification from 'grommet/components/Notification';
import Toast from 'grommet/components/Toast';
import Spinning from 'grommet/components/icons/Spinning';

import FormCreator from '../../../../components/UI/FormCreator/FormCreator';
import { checkValidity, returnErrorTextForField } from '../../../../shared/utility';

import * as actions from '../../../../store/actions/index';
const cloneDeep = require('lodash.clonedeep');

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId))
  };
};

class AddEditGym extends Component {
  state = {
    form: {
      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter gym name',
        value: '',
        validation: {
          required: true,
          restrictSpecialCharacter: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      address: {
        label: 'Address',
        type: 'text',
        placeholder: 'Enter address',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      city: {
        label: 'City',
        type: 'text',
        placeholder: 'Enter city',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      postcode: {
        label: 'Postcode',
        type: 'text',
        placeholder: 'Enter postcode',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      termsUrl: {
        label: 'Terms URL',
        type: 'text',
        placeholder: 'Terms URL',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    },
    toastShow: false,
    toastMessage: null,
    toastStatus: null
  };

  componentDidMount() {
    if (this.props.data) {
      const copiedForm = cloneDeep(this.state.form);
      this.setState(
        {
          form: copiedForm
        },
        () => this.assignExistingValues()
      );
    }
  }

  assignExistingValues = () => {
    const formNames = Object.keys(this.props.data);
    const previousValues = Object.values(this.props.data);
    const copiedForm = cloneDeep(this.state.form);

    formNames.forEach((element, index) => {
      if (copiedForm.hasOwnProperty(element) && copiedForm[element] !== undefined) {
        copiedForm[element] = {
          ...copiedForm[element],
          value: previousValues[index],
          valid: true,
          touched: true
        };
      }
    });

    this.setState({
      form: copiedForm
    });
  };

  valueChangedHandler = (newValue, identifier) => {
    const copiedForm = cloneDeep(this.state.form);

    copiedForm[identifier] = {
      ...copiedForm[identifier],
      value: newValue,
      valid: checkValidity(newValue, this.state.form[identifier].validation),
      errorText: returnErrorTextForField(this.state.form[identifier].validation),
      touched: true
    };

    return this.setState({
      form: copiedForm
    });
  };

  checkFormValidity = () => {
    this.setState({ formValidated: true });

    for (let input in this.state.form) {
      if (!this.state.form[input].valid) {
        const copiedForm = cloneDeep(this.state.form);
        copiedForm[input] = {
          ...copiedForm[input],
          valid: checkValidity(copiedForm[input].value, copiedForm[input].validation),
          errorText: returnErrorTextForField(copiedForm[input].validation),
          touched: true
        };

        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });

        return this.setState({
          formIsValid: false,
          form: copiedForm
        });
      }
    }

    this.setState({ formIsValid: true });
    if (this.props.data) {
      this.saveEdited();
    } else {
      this.addNew();
    }
  };

  passErrorMessagesToForm = (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const formNames = Object.keys(error.response.data.errors);
      const errorsMessages = Object.values(error.response.data.errors);
      const copiedForm = cloneDeep(this.state.form);

      formNames.forEach((element, index) => {
        if (this.state.form.hasOwnProperty(element) && this.state.form[element] !== undefined) {
          copiedForm[element] = {
            ...copiedForm[element],
            valid: false,
            errorText: errorsMessages[index].message,
            touched: true
          };
        }
      });

      this.setState({
        form: copiedForm
      });
    } else {
      // eslint-disable-next-line
      console.log(error.response);
    }
  };

  addNew = () => {
    this.setState({ loading: true });

    let formData = {};

    for (let input in this.state.form) {
      formData[input] = this.state.form[input].value;
    }
    axios
      .post(`/gym/${this.props.data._id}`, formData)
      .then(() => {
        this.setState({ loading: false });
        this.props.onFetchGymDetails(this.props.data._id);
        this.props.history.push(`/gym/${this.props.data._id}/settings`);
      })
      .catch((error) => {
        this.passErrorMessagesToForm(error);

        this.setState({
          loading: false
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
  };

  saveEdited = () => {
    this.setState({ loading: true });
    const formData = {};
    for (let input in this.state.form) {
      formData[input] = this.state.form[input].value;
    }

    axios
      .patch(`/gym/${this.props.data._id}`, formData)
      .then(() => {
        this.setState({
          loading: false,
          toastShow: true,
          toastMessage: 'Gym updated successfully',
          toastStatus: 'ok'
        });
      })
      .catch((error) => {
        this.passErrorMessagesToForm(error);

        this.setState({
          loading: false
        });
      });
  };

  render() {
    let formWarning = null;
    if (!this.state.formIsValid && this.state.formValidated) {
      formWarning = (
        <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
      );
    }

    let button = (
      <Button
        style={{ marginTop: '50px', display: 'block', minWidth: '100%' }}
        label={this.props.data ? 'Save' : 'Add'}
        secondary={true}
        onClick={this.checkFormValidity}
      />
    );
    if (this.state.loading) {
      button = <Spinning />;
    }

    let error = null;
    let toast = null;
    if (this.state.toastShow) {
      toast = (
        <Toast status={this.state.toastStatus} onClose={this.closeToast}>
          {this.state.toastMessage}
        </Toast>
      );
    }
    if (this.state.error) {
      error = <Notification message={this.state.error} status="critical" className="ss-top-notification" />;
    }

    return (
      <>
        {toast}
        {error}
        {formWarning}

        <FormCreator formData={this.state.form} valueChanged={this.valueChangedHandler} />

        {button}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEditGym);
