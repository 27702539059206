import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from '../../../axios-global';
import Modal from 'react-modal';

import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import Button from 'grommet/components/Button';
import Toast from 'grommet/components/Toast';

import ClientProfileMainDetails from '../../../components/ClientProfile/ClientProfileMainDetails/ClientProfileMainDetails';
import ClientProfileMoreDetails from '../../../components/ClientProfile/ClientProfileMoreDetails';
import ClientProfileGeneralNotes from '../../../components/ClientProfile/ClientProfileGeneralNotes';
import ClientProfilePaymentNotes from '../../../components/ClientProfile/ClientProfilePaymentNotes/ClientProfilePaymentNotes';
import ClientProfilePlanStatus from '../../../components/ClientProfile/ClientProfilePlanStatus/ClientProfilePlanStatus';
import ClientProfilePayments from '../../../components/ClientProfile/ClientProfilePayments';
import ClientProfileAddProducts from '../../../components/ClientProfile/ClientProfileAddProducts/index';
import ClientProfileSignUpClient from '../../../components/ClientProfile/ClientProfileSignUpClient';
import ClientProfileOperationalButtons from '../../../components/ClientProfile/ClientProfileOperationalButtons';
import ClientProfileAddQr from '../../../components/ClientProfile/ClientProfileAddQr';
import AdminEditClient from '../../../components/AdminEditClient';
import ClientProfileProductsDetail from '../../../components/ClientProfile/ClientProfileProductsDetail/ClientProfileProductsDetail';

import { returnErrorFromResponse, returnSuccessFromResponse } from '../../../shared/utility';

import * as actions from '../../../store/actions/index';

import { CLASS_TYPES } from '../../../constants';

import classes from './ClientProfile.module.scss';

const shouldDisplaySignUpButton = (client) => client.customerType === 'full-member';

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,

    selectedClientLoading: state.admin.selectedClientLoading,
    selectedClientError: state.admin.selectedClientError,
    selectedClient: state.admin.selectedClient,

    selectedGym: state.admin.selectedGym,

    plansList: state.admin.plansList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymUsersList: (gymId) => dispatch(actions.fetchGymUsersList(gymId)),
    onFetchSingleClient: (userId) => dispatch(actions.fetchSingleClient(userId)),
    onSingleClientDataReset: (userId) => dispatch(actions.singleClientDataReset(userId)),
    onGlobalQrReaderStateChange: (state) => dispatch(actions.changeGlobalQrReader(state))
  };
};

class ClientProfile extends Component {
  state = {
    showQrModal: false,
    showDeleteModal: false,
    showEditModal: false,
    editLoading: false,
    deleteLoading: false,

    toast: null,
    toastStatus: null,
    classPasses: [],
    personalTrainingPasses: [],
    gymClassPasses: [],
    swimmingClassPasses: [],
    tennisClassPasses: [],
    massageClassPasses: []
  };

  componentDidMount() {
    this.props.onFetchSingleClient(this.props.match.params.clientId);
  }

  componentWillUnmount() {
    this.props.onSingleClientDataReset(this.props.match.params.clientId);
    this.props.onGlobalQrReaderStateChange(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.clientId !== this.props.match.params.clientId) {
      this.props.onFetchSingleClient(this.props.match.params.clientId);
    }
    if (prevProps.selectedClient !== this.props.selectedClient && this.props.userData.role !== 'user') {
      CLASS_TYPES.forEach((elem) => {
        if (
          this.props.selectedClient.gymSettings[elem] &&
          this.props.selectedClient.gymSettings.classConfig.filter((config) => config.classType === elem)[0]
            .allowedToBuyPasses
        ) {
          if (!(this.props.selectedClient.customerType === 'full-member' && elem === 'class')) {
            axios
              .get(
                `/products/passes/${elem}Pass/${this.props.selectedClient.gymId}/all?userId=${this.props.selectedClient._id}`
              )
              .then((response) =>
                this.setState({
                  [`${elem}Passes`]: response.data
                })
              )
              .catch((err) =>
                // eslint-disable-next-line
                console.log(err)
              );
          }
        }
      });
    }

    if (this.props.selectedClient && this.props.selectedClient._id) {
      if (
        this.props.match.params.clientId !== this.props.selectedClient._id &&
        (!this.props.selectedClient.qrCode ||
          (this.props.selectedClient.qrCode && this.props.selectedClient.qrCode !== this.props.match.params.clientId))
      ) {
        this.props.onFetchSingleClient(this.props.match.params.clientId);
      }
    }
  }

  fetchSingleClientDetails = () => this.props.onFetchSingleClient(this.props.match.params.clientId);

  showSuccessfulToast = (response) => {
    this.setState({
      toast: response ? returnSuccessFromResponse(response) : 'Changes saved',
      toastStatus: 'ok'
    });
  };

  showFailedToast = (error) => {
    this.setState({
      toast: returnErrorFromResponse(error),
      toastStatus: 'critical'
    });
  };

  closeToast = () => {
    this.setState({
      toast: false,
      toastStatus: null
    });
  };

  renderToast() {
    if (this.state.toast && this.state.toastStatus) {
      return (
        <Toast status={this.state.toastStatus} onClose={this.closeToast}>
          {this.state.toast}
        </Toast>
      );
    }
  }

  handleClientDeletion = () => {
    this.setState({
      deleteLoading: true
    });

    axios
      .delete(`/users/${this.props.selectedClient._id}`)
      .then((resp) => {
        this.props.onFetchGymUsersList(this.props.selectedClient.gymId);
        this.props.onFetchSingleClient(this.props.match.params.clientId);
        this.setState({
          toast: resp.data.message,
          toastStatus: 'ok',
          deleteLoading: false,
          showDeleteModal: false
        });
      })
      .catch((err) => {
        this.setState({
          toast: err.response.data.message,
          toastStatus: 'critical',
          deleteLoading: false,
          showDeleteModal: false
        });
      });
  };

  onNewQrScan = (scan) => {
    if (scan) {
      axios
        .patch(`/users/${this.props.selectedClient._id}`, {
          qrCode: scan
        })
        .then(() => {
          this.setState({
            showQrModal: false,
            toast: 'Additional QR code saved',
            toastStatus: 'ok'
          });
          setTimeout(() => {
            this.props.onGlobalQrReaderStateChange(true);
          }, 1000);
        })
        .catch((err) => {
          if (err.response.data.errors && err.response.data.errors[0].message) {
            this.setState({
              showQrModal: false,
              toast: err.response.data.errors[0].message,
              toastStatus: 'critical'
            });
            this.props.onGlobalQrReaderStateChange(true);
          }
        });
    }
  };

  onQrCodeError = (error) => {
    this.setState({
      toast: returnErrorFromResponse(error),
      toastStatus: 'critical'
    });
  };

  handleQrModalClose = () => {
    this.setState({
      showQrModal: false
    });
    this.props.onGlobalQrReaderStateChange(true);
  };

  handleQrModalOpen = () => {
    this.setState({
      showQrModal: true
    });
    this.props.onGlobalQrReaderStateChange(false);
  };

  renderQrModal = () => {
    return (
      <Modal
        isOpen={this.state.showQrModal}
        onRequestClose={this.handleQrModalClose}
        className="ss-modal ss-modal--no-min-width">
        <ClientProfileAddQr handleQrCodeScan={this.onNewQrScan} handleQrCodeError={this.onQrCodeError} />
      </Modal>
    );
  };

  renderDeleteModal = () => {
    return (
      <Modal
        isOpen={this.state.showDeleteModal}
        onRequestClose={this.handleDeleteModalClose}
        className="ss-modal ss-modal--no-min-width">
        Do you want to deactivate chosen user?
        <br />
        <div className="ss-modal__buttons-container">
          {this.state.deleteLoading ? (
            <Spinning />
          ) : (
            <>
              <Button box label="Yes" secondary={true} onClick={this.handleClientDeletion} />

              <Button box label="Cancel" primary onClick={this.handleDeleteModalClose} />
            </>
          )}
        </div>
      </Modal>
    );
  };

  handleDeleteModalClose = () => {
    this.setState({
      showDeleteModal: false
    });
  };

  handleDeleteModalOpen = () => {
    this.setState({
      showDeleteModal: true
    });
  };

  renderEditModal = () => {
    return (
      <Modal isOpen={this.state.showEditModal} onRequestClose={this.handleEditModalClose} className="ss-modal">
        <div className="grommet">
          <AdminEditClient
            clientData={this.props.selectedClient}
            onFetchClientDetails={this.fetchSingleClientDetails}
          />
        </div>
      </Modal>
    );
  };

  handleEditModalClose = () => {
    this.setState({
      showEditModal: false
    });
  };

  handleEditModalOpen = () => {
    this.setState({
      showEditModal: true
    });
  };

  renderProfile = () => {
    if (!this.props.selectedClientLoading && this.props.selectedClientError && !this.props.selectedClient) {
      return <Notification message={this.props.selectedClientError} status="critical" />;
    }

    if (
      !this.props.selectedClientLoading &&
      !this.props.selectedClientError &&
      this.props.selectedClient &&
      !this.props.selectedClient._id
    ) {
      return <Notification message="No customer found for this ID or this QR code" status="warning" />;
    }

    if (!this.props.selectedClientLoading && !this.props.selectedClientError && this.props.selectedClient) {
      const isUserActive = this.props.selectedClient.status === 'active';
      return (
        <>
          {!isUserActive && this.renderInactiveUser()}

          <ClientProfileMainDetails
            editable={this.props.userData.role !== 'trainer'}
            selectedClient={this.props.selectedClient}
            fetchSingleClient={this.fetchSingleClientDetails}
            onSuccessfulSave={this.showSuccessfulToast}
            onFailedSave={this.showFailedToast}
            isAdmin={this.props.userData.role === 'admin'}
          />

          <ClientProfileMoreDetails
            editable={this.props.userData.role !== 'trainer'}
            selectedClient={this.props.selectedClient}
            fetchSingleClient={this.fetchSingleClientDetails}
            onSuccessfulSave={this.showSuccessfulToast}
            onFailedSave={this.showFailedToast}
          />

          <h3 style={{ fontSize: '18px' }}>NOTES</h3>
          <ClientProfilePaymentNotes
            isAdmin={this.props.userData.role === 'admin'}
            selectedClient={this.props.selectedClient}
            onSuccessfulSave={this.showSuccessfulToast}
            onFailedSave={this.showFailedToast}
          />

          {this.props.userData.role === 'admin' && (
            <ClientProfileGeneralNotes
              selectedClient={this.props.selectedClient}
              onSuccessfulSave={this.showSuccessfulToast}
              onFailedSave={this.showFailedToast}
            />
          )}

          {this.props.userData.role === 'admin' &&
            isUserActive &&
            this.state.classPasses &&
            this.state.personalTrainingPasses &&
            this.state.gymClassPasses &&
            this.state.swimmingClassPasses &&
            this.state.tennisClassPasses &&
            this.state.massageClassPasses && (
              <ClientProfileAddProducts
                gymPasses={this.state.gymClassPasses}
                swimPasses={this.state.swimmingClassPasses}
                tennisPasses={this.state.tennisClassPasses}
                massagePasses={this.state.massageClassPasses}
                gymSettings={this.props.selectedGym.settings}
                selectedClient={this.props.selectedClient}
                classPasses={this.state.classPasses}
                personalTrainings={this.state.personalTrainingPasses}
                fetchSingleClient={this.fetchSingleClientDetails}
                onSuccessfulSave={this.showSuccessfulToast}
                onFailedSave={this.showFailedToast}
              />
            )}
          {this.props.userData.role === 'admin' && (
            <ClientProfileProductsDetail
              gymSettings={this.props.selectedGym.settings}
              selectedClient={this.props.selectedClient}
              bookings={this.props.selectedClient.fitnessClassHistory}
              history={this.props.history}
              gymId={this.props.selectedClient.gymId}
            />
          )}

          <ClientProfilePlanStatus
            isAdmin={this.props.userData.role === 'admin'}
            selectedClient={this.props.selectedClient}
            onSuccessfulSave={this.showSuccessfulToast}
            onFailedSave={this.showFailedToast}
          />

          {shouldDisplaySignUpButton(this.props.selectedClient) && (
            <ClientProfileSignUpClient
              isAdmin={this.props.userData.role === 'admin'}
              selectedClient={this.props.selectedClient}
              plansList={this.props.plansList}
              fetchSingleClient={this.fetchSingleClientDetails}
              onSuccessfulSave={this.showSuccessfulToast}
              onFailedSave={this.showFailedToast}
            />
          )}

          <ClientProfilePayments
            isAdmin={this.props.userData.role === 'admin'}
            selectedClient={this.props.selectedClient}
            onSuccessfulSave={this.showSuccessfulToast}
            onFailedSave={this.showFailedToast}
          />

          {this.props.userData.role === 'admin' && isUserActive && (
            <ClientProfileOperationalButtons
              showQRButton={this.props.selectedGym.settings.qrCode}
              handleQrModalOpen={this.handleQrModalOpen}
              handleEditModalOpen={this.handleEditModalOpen}
              handleDeleteModalOpen={this.handleDeleteModalOpen}
            />
          )}
        </>
      );
    }

    return <Spinning />;
  };

  renderInactiveUser = () => {
    const activateUser = () => {
      axios
        .patch(`/users/${this.props.selectedClient._id}`, { status: 'active' })
        .then(() => {
          this.setState({
            toast: 'Client successfully activated!',
            toastStatus: 'ok'
          });
          this.props.onFetchSingleClient(this.props.selectedClient._id);
          this.props.onFetchGymUsersList(this.props.selectedClient.gymId);
        })
        .catch((error) => {
          this.setState({
            toast: returnErrorFromResponse(error),
            toastStatus: 'critical'
          });
        });
    };
    const userStatus =
      this.props.selectedClient.status === 'waitingForApproval'
        ? 'waiting for admin approval'
        : this.props.selectedClient.status === 'permissionDenied'
        ? 'permission denied'
        : 'inactive';

    return (
      <>
        <Notification message={`This customer status is: ${userStatus}`} status="warning" />

        {this.props.userData.role === 'admin' && (
          <div className={classes.userActivationBox}>
            Do you want to activate this user?
            <Button label="YES" primary onClick={() => activateUser()} />
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <div className={classes.detailsContainer}>
          {this.renderToast()}
          {this.renderQrModal()}
          {this.renderDeleteModal()}
          {this.renderEditModal()}
          {this.renderProfile()}
        </div>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientProfile));
