import { useState } from 'react';

const useSelectDropdownFilter = (initialState, onFilter) => {
  const [selectedFilter, setSelectedFilter] = useState(initialState);

  const onSelectedFilterChanged = (e) => {
    const filter = e.value;

    onFilter(filter.value);
    setSelectedFilter(filter);
  };

  return {
    onSelectedFilterChanged,
    selectedFilter
  };
};

export default useSelectDropdownFilter;
