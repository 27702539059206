import React, { useState } from 'react';
import TrainerSelector from '../../../components/Selectors/TrainerSelector/TrainerSelector';
import { useMutation } from 'react-query';
import { delayFetchFunctions, editClass } from '../../../apiFunctions/apiFunctions';
import CustomToast from '../../../components/CustomToast/CustomToast';
import { returnErrorFromResponse } from '../../../shared/utility';

const ClassTrainerSelector = ({ classConfig, singleClass }) => {
  // eslint-disable-next-line
  const [status, setStatus] = useState('ok');
  const [toast, setToast] = useState(null);

  const mutateSaveTrainer = useMutation((trainer) => editClass(singleClass._id, { trainerId: trainer._id }), {
    onSuccess: () => {
      delayFetchFunctions(['singleClass'], 0);
      setToast({
        status: 'ok',
        msg: 'Trainer successfully changed',
        show: true
      });
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  if (classConfig.trainerRequired) {
    return (
      <div>
        <CustomToast toast={toast} />
        <h4 className="ss-list-header" style={{ marginTop: '20px' }}>
          Change trainer
        </h4>
        <TrainerSelector
          setStatus={setStatus}
          selectedTrainer={singleClass.trainer}
          isValid={singleClass.trainer}
          onSelectTrainer={(trainer) => mutateSaveTrainer.mutate(trainer)}
          showLabel={false}
        />
      </div>
    );
  }
  return null;
};

export default ClassTrainerSelector;
