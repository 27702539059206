import React from 'react';
import { withRouter } from 'react-router-dom';
import Spinning from 'grommet/components/icons/Spinning';
import withUser from '../../../../store/hoc/withUser';
import { Table, TableHeader, TableRow } from 'grommet';
import moment from 'moment';
import Button from 'grommet/components/Button';
import classes from './DefaultClientUpcomingClasses.module.scss';
import ClientUpcomingClasses from '../../../ClientDashboard/ClientUpcomingClasses/ClientUpcomingClasses';
import ClientShowPTPackages from '../../../ClientDashboard/ClientPtSessions/ClientShowPTPackages';
import { useQuery } from 'react-query';
import { fetchClientUpcomingClasses } from '../../../../apiFunctions/apiFunctions';
import ClassActionButtons from '../../../ActionButtons/ClassActionButtons/ClassActionButtons';
import { returnClassesDetails } from '../../../../shared/classFunctions';

const DefaultClientUpcomingClasses = ({ classType, userData, match }) => {
  const classesDetails = returnClassesDetails(classType);
  const { data: upcomingClasses, isLoading: upcomingClassesLoading, error: upcomingClassesError } = useQuery(
    ['clientUpcomingClasses', classType],
    () => fetchClientUpcomingClasses(classType)
  );

  const renderHeader = <h3>{classesDetails.name.charAt(0).toUpperCase() + classesDetails.name.slice(1)} bookings</h3>;

  const clientUpcomingClasses = () => {
    const activeUpcomingClasses = upcomingClasses.filter(
      (classes) => classes.status === 'active' || classes.status === 'pending'
    );
    if (activeUpcomingClasses.length === 0) {
      return <div>You have no upcoming bookings</div>;
    }
    let tableHeader = ['Date', 'Time', 'Duration', ''];
    if (classType === 'class') {
      tableHeader.unshift('Name');
    }
    const rows = activeUpcomingClasses.map((elem) => (
      <TableRow key={elem._id} data-cy="tableRow">
        {classType === 'class' && <td>{elem.class.name}</td>}
        <td>{moment(elem.class.classDate).format('DD/MM')}</td>
        <td>{elem.class.classTime}</td>
        <td>{elem.class.duration}</td>
        <td>
          <ClassActionButtons
            selectedClass={elem.class}
            classesDetails={classesDetails}
            userAttendance={{ stripeOrderId: elem.stripeOrderId, status: elem.status, _id: elem._id }}
          />
        </td>
      </TableRow>
    ));

    return (
      <Table responsive={false} className={classes.bookingsTable}>
        <TableHeader labels={tableHeader} />
        <tbody>{rows}</tbody>
      </Table>
    );
  };

  const renderRedirectButton = ({ name }) => (
    <div>
      <Button
        className={classes.redirectButton}
        label={`Show ${name} timetable`}
        secondary={true}
        path={`${match.url}/${name}-timetable`}
      />
    </div>
  );

  if (upcomingClassesLoading) {
    return <Spinning />;
  }
  if (upcomingClassesError) {
    return <div>ERROR</div>;
  }
  if (classType === 'personalTraining') {
    return (
      <div className={classes.root}>
        <h3> Personal Training Sessions</h3>
        <ClientUpcomingClasses
          type={'PTs'}
          upcomingClasses={upcomingClasses}
          upcomingClassesLoading={upcomingClassesLoading}
          upcomingClassesError={upcomingClassesError}
        />

        {userData.PTclassesLeft.length > 0 ? (
          <ClientShowPTPackages PtsList={userData.PTclassesLeft} />
        ) : (
          <Button
            className={classes.redirectButton}
            label="Buy a PT package"
            secondary={true}
            path={`${match.url}/personal-training-passes`}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {renderHeader}
      {clientUpcomingClasses()}
      {renderRedirectButton(classesDetails)}
    </div>
  );
};

export default withRouter(withUser(DefaultClientUpcomingClasses));
