import { returnAvailableClassTypesInGym } from '../../../shared/gymFunctions';

export const returnFAQFields = (gymSettings = {}, data = null) => {
  const classTypesOptions = returnAvailableClassTypesInGym(gymSettings).map((elem) => elem.name);
  let fields = {
    questionType: {
      label: 'Question Type',
      type: 'select',
      options: ['general', 'non customer', ...classTypesOptions],
      placeholder: 'Select question type',
      value: data ? data.questionType : 'general',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    question: {
      label: 'Question',
      type: 'longText',
      placeholder: 'Enter question',
      value: data ? data.question : '',
      validation: {
        required: true
      },
      valid: !!data,
      touched: !!data,
      errorText: null
    },
    answer: {
      label: 'Answer',
      type: 'longText',
      placeholder: 'Enter answer',
      value: data ? data.answer : '',
      validation: {
        required: true
      },
      valid: !!data,
      touched: !!data,
      errorText: null
    }
  };
  return fields;
};
