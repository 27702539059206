import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'react-query';

import Heading from 'grommet/components/Heading';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import Notification from 'grommet/components/Notification';

import classes from './TrainersUpcomingClasses.module.scss';
import Loading from '../../components/Loading/Loading';
import { fetchClientUpcomingClasses } from '../../apiFunctions/apiFunctions';

const TrainersUpcomingClasses = ({ history }) => {
  const { data, isLoading, error } = useQuery(['allTrainerUpcomingClasses'], () => fetchClientUpcomingClasses('all'));

  if (isLoading) {
    return <Loading size="medium" />;
  }

  if (error) {
    return <Notification message="Something went wrong. Please try again." status="critical" />;
  }

  if (data && data.length > 0) {
    return (
      <List>
        {data.map((element) => (
          <ListItem
            key={element._id}
            separator="horizontal"
            justify="between"
            onClick={() => history.push(`class/${element._id}`)}>
            <Heading tag="h4" className={classes.tableListHeader}>
              {element.name}
            </Heading>
            <span className="secondary">
              {moment(element.classDate).format('Do MMMM')}, {element.classTime.toUpperCase()}
            </span>
          </ListItem>
        ))}
      </List>
    );
  }

  return 'No upcoming classes';
};

export default withRouter(TrainersUpcomingClasses);
