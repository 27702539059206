import React from 'react';
import { withRouter } from 'react-router-dom';

import Notification from 'grommet/components/Notification';

import ClientPaymentPage from '../ClientPaymentPage/ClientPaymentPage';

export default withRouter(({ joiningFeePaid, match, plansList, currentStatus }) => {
  if (currentStatus.active) {
    return <Notification message="You are already subscribed to a membership plan." status="ok" />;
  }

  if (plansList && plansList.length > 0) {
    const foundPlan = plansList.find((elem) => elem._id === match.params.planId);

    if (foundPlan) {
      return <ClientPaymentPage membershipPlan={foundPlan} joiningFeePaid={joiningFeePaid} />;
    }

    return (
      <Notification message="No selected plan found. Please double check your URL and try again." status="warning" />
    );
  }

  return <Notification message="Admin haven't added any membership plan yet. Try again later." status="warning" />;
});
