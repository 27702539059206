import React from 'react';
import Accordion from 'grommet/components/Accordion';
import AccordionPanel from 'grommet/components/AccordionPanel';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import ProfilePaymentHistory from '../../UI/ProfilePaymentHistory/ProfilePaymentHistory';

import './ClientProfilePayments.scss';

export default function ClientProfilePayments(props) {
  const lastPaymentInfo = props.selectedClient.paymentHistory ? props.selectedClient.paymentHistory[0] : null;

  return (
    <>
      <div>
        <h4 className="profile-details__header">Payments</h4>

        <div className="last-payment">
          <ProfileTextWithLabel
            label="Last payment"
            text={
              lastPaymentInfo ? <ProfilePaymentHistory lastPaymentInfo={lastPaymentInfo} /> : 'No payments received yet'
            }
          />
        </div>

        <Accordion>
          <AccordionPanel heading="Payment history">
            {props.selectedClient.paymentHistory.map((elem) => (
              <ProfileTextWithLabel
                paymentsInfo
                key={elem.date}
                label={elem.type}
                text={<ProfilePaymentHistory lastPaymentInfo={elem} />}
              />
            ))}
          </AccordionPanel>
        </Accordion>
      </div>
    </>
  );
}
