import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'grommet/components/Button';
import Columns from 'grommet/components/Columns';
import Box from 'grommet/components/Box';
import Modal from 'react-modal';
import classes from './ManageVideos.module.scss';
import SingleVideo from './SingleVideo/SingleVideo.js';
import AddEditVideo from './AddEditVideo/AddEditVideo';
import axios from '../../../axios-global';
import Toast from 'grommet/components/Toast';

export class ManageVideos extends Component {
  state = {
    showModal: false,
    videos: []
  };

  componentDidMount = () => {
    this.setState({
      allowUserToSave: false
    });
    this.getAllVideos();
  };

  getAllVideos = async () => {
    try {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_DEV_ENV_API}`
          : `${process.env.REACT_APP_PROD_ENV_API}`;
      const response = await axios.get(`${baseUrl}/api/videos`);
      if (response.status === 200) {
        this.setState({
          videos: response.data
        });
      } else {
        this.showErrorToast('Failed to get videos');
      }
    } catch (error) {
      this.showErrorToast('Failed to get videos');
    }
  };

  handleModalClose = async (isEdited, fileName) => {
    this.setState({
      showModal: false,
      editVideo: undefined
    });
    if (isEdited) {
      this.getAllVideos();
    }
    if (fileName) {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_DEV_ENV_API}`
          : `${process.env.REACT_APP_PROD_ENV_API}`;
      await axios.delete(`${baseUrl}/api/videos/s3/${fileName}`);
    }
  };

  handleVideoEdit = (index) => {
    this.setState({
      showModal: true,
      editVideo: this.state.videos[index]
    });
  };

  handleDeleteVideo = (index) => {
    if (!this.state.showConfirmToast) {
      return this.setState({
        showConfirmToast: true
      });
    }

    this.setState(
      {
        showConfirmToast: false
      },
      () => this.deleteVideo(index)
    );
  };

  addVideo = () => {
    this.setState({
      showModal: true
    });
  };

  deleteVideo = async (index) => {
    await axios
      .delete(`/videos/${this.state.videos[index]._id}`)
      .then(() => this.getAllVideos())
      .catch(() => this.showErrorToast('Failed to delete video'));
  };

  renderVideos = () => (
    <Columns maxCount={4}>
      {this.state.videos.map((video, index) => {
        return (
          <Box align="start" margin="small" key={index}>
            <SingleVideo
              handleVideoEdit={() => this.handleVideoEdit(index)}
              handleDeleteVideo={() => this.handleDeleteVideo(index)}
              video={video}
            />
          </Box>
        );
      })}
    </Columns>
  );

  showErrorToast = (message) => this.setState({ errorMessage: message });

  renderNotification = () => {
    if (this.state.showConfirmToast) {
      return (
        <Toast status="warning" onClose={this.handleConfirmToastClose}>
          Click once again to confirm the deletion of the video
        </Toast>
      );
    }

    if (this.state.errorMessage) {
      return (
        <Toast status="critical" onClose={this.handleToastClose}>
          {this.state.errorMessage}
        </Toast>
      );
    }
  };

  render = () => {
    return (
      <>
        {this.renderNotification()}
        <div className={classes.videoHeader}>
          <h4 className={classes.listHeader}>Videos</h4>
          <Button label="Media Category" secondary={true} path={`${this.props.match.url}/media-category`} />
        </div>

        <Modal isOpen={this.state.showModal} className={['ss-modal', classes.videoModal].join(' ')}>
          <div className="grommet">
            <AddEditVideo onRequestClose={this.handleModalClose} editVideo={this.state.editVideo} />
          </div>
        </Modal>

        {this.renderVideos()}

        <Button
          style={{ marginTop: '50px', display: 'inline-block' }}
          label="Add Video"
          secondary={true}
          onClick={this.addVideo}
        />
      </>
    );
  };
}

export default connect()(ManageVideos);
