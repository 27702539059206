import React from 'react';

import Button from 'grommet/components/Button';
import './ClientProfileOperationalButtons.scss';

export default function ClientProfileOperationalButtons(props) {
  return (
    <div className="client-profile__buttons-container">
      {props.showQRButton && (
        <Button
          className="client-profile__qr-button"
          label="Add extra QR code"
          secondary={true}
          onClick={props.handleQrModalOpen}
        />
      )}

      <div className="client-profile__smaller-buttons-container">
        <Button
          className="client-profile__button"
          label="Edit client"
          secondary={true}
          onClick={props.handleEditModalOpen}
        />

        <Button
          className="client-profile__delete-button"
          label="Deactivate client"
          secondary={true}
          onClick={props.handleDeleteModalOpen}
        />
      </div>
    </div>
  );
}
