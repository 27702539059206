import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { delayFetchFunctions, deleteTemplate } from '../../../apiFunctions/apiFunctions';
import { returnCurrencyAndCostString, returnErrorFromResponse } from '../../../shared/utility';
import CustomToast from '../../../components/CustomToast/CustomToast';
import { Table, TableRow, TableHeader } from 'grommet';
import Icons from '../../../components/UI/Icons/Icons';

const ShowTemplateList = ({ data, onUpdateTemplate, classType }) => {
  const [toast, setToast] = useState(null);
  const [selectedTemplateIdToDelete, setSelectedTemplateIdToDelete] = useState(null);

  const mutateDeleteTemplate = useMutation((templateId) => deleteTemplate(templateId), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Template successfully deleted',
        show: true
      });
      delayFetchFunctions([['templates', classType]], 0);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  const removeTemplate = (templateId) => {
    if (selectedTemplateIdToDelete === templateId) {
      mutateDeleteTemplate.mutate(templateId);
    } else {
      setSelectedTemplateIdToDelete(templateId);
      setToast({
        status: 'ok',
        msg: 'To confirm deletion please click once again.',
        show: true
      });
    }
  };
  const tableHeader = ['Name', 'Description', 'Capacity', 'Cost', 'Membership Price', 'Duration', 'Action'];

  const renderTableBody = data.map((elem) => (
    <TableRow
      direction="column"
      align="start"
      separator="horizontal"
      key={elem._id}
      style={elem.active ? {} : inactiveRow}>
      <td>{elem.name}</td>
      <td style={{ fontSize: '12px' }}>{elem.defaultDescription}</td>
      <td>{elem.defaultLimit}</td>
      <td>{returnCurrencyAndCostString(elem.currency, elem.defaultCost)}</td>
      <td>{returnCurrencyAndCostString(elem.currency, elem.membershipClassPrice ? elem.membershipClassPrice : 0)}</td>
      <td>{elem.defaultDuration} mins</td>
      <td>
        <Icons edit delete editClick={() => onUpdateTemplate(elem)} deleteClick={() => removeTemplate(elem._id)} />
      </td>
    </TableRow>
  ));

  return (
    <div>
      <CustomToast toast={toast} />
      <Table responsive={false}>
        <TableHeader labels={tableHeader} />
        <tbody>{renderTableBody}</tbody>
      </Table>
    </div>
  );
};

export default ShowTemplateList;

const inactiveRow = {
  background: '#dddddd'
};
