import React from 'react';
import Button from 'grommet/components/Button';
import classes from './Hero.module.scss';

export default (props) => (
  <div className={classes.hero}>
    <div className="container">
      <div className={classes.hero__box}>
        <h2 className={classes.box__header}>Sign up</h2>

        <p className={classes.box__additionalInfo}>
          If you have already signed up then please log in below, for details on signing up please contact us.
        </p>

        {props.isAuthenticated ? (
          <Button label="DASHBOARD" path={props.dashboardLink} secondary />
        ) : (
          <Button label="LOGIN" path={`/login`} secondary />
        )}
      </div>
    </div>
  </div>
);
