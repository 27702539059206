import moment from 'moment';

export const classDetailsFields = (classType, data) => {
  let detailsToDisplay = [
    {
      fieldName: 'name',
      label: 'Name',
      value: data.name
    },
    {
      fieldName: 'gymId',
      label: 'Location',
      value: data.gymId.name
    },
    {
      fieldName: 'gymId',
      label: 'Address',
      value: data.gymId.city + ', ' + data.gymId.address
    },
    {
      fieldName: 'classDate',
      label: 'Date',
      value: moment(data.classDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    {
      fieldName: 'classTime',
      label: 'Time',
      value: data.classTime
    },
    {
      fieldName: 'duration',
      label: 'Duration',
      value: data.duration
    },
    {
      fieldName: 'trainer',
      label: 'Trainer',
      value: data.trainer ? data.trainer.name + ' ' + data.trainer.last_name : ''
    },
    {
      fieldName: 'notesForClient',
      label: 'Notes for client',
      value: data.notesForClient
    },
    {
      fieldName: 'notesForCompany',
      label: 'Notes for company',
      value: data.notesForCompany
    }
  ];
  if (classType !== 'personalTraining') {
    const fieldsToRemove = ['notesForClient', 'notesForCompany'];
    detailsToDisplay = detailsToDisplay.filter((elem) => !fieldsToRemove.includes(elem.fieldName));
  }

  if (
    classType === 'gymClass' ||
    classType === 'swimmingClass' ||
    classType === 'tennisClass' ||
    classType === 'massageClass'
  ) {
    const selectedFields = ['classDate', 'classTime', 'duration'];
    return detailsToDisplay.filter((elem) => selectedFields.includes(elem.fieldName));
  }

  return detailsToDisplay;
};
