import React from 'react';

import classes from './ProfileTextWithLabel.module.scss';

const ProfileTextWithLabel = (props) => {
  return (
    <div className={classes.container} data-cy="credits">
      <span className={classes.label}>{props.label}</span>
      <span className={classes.text}>{props.text}</span>
    </div>
  );
};
export default ProfileTextWithLabel;
