import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  loading: false,
  locations: null,
  error: false,

  faq: null,
  faqLoading: false,
  faqError: false,

  cityNames: null,
  cityNamesLoading: true,
  cityNamesError: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCATIONS_START:
      return updateObject(state, { loading: true });
    case actionTypes.SET_LOCATIONS_SUCCESS:
      return updateObject(state, {
        loading: false,
        locations: action.locations
      });
    case actionTypes.SET_LOCATIONS_FAILED:
      return updateObject(state, { loading: false, error: action.error });

    case actionTypes.FETCH_CITY_NAMES_START:
      return updateObject(state, { cityNamesLoading: true });
    case actionTypes.FETCH_CITY_NAMES_SUCCESS:
      return updateObject(state, {
        cityNamesLoading: false,
        cityNames: action.cityNames
      });
    case actionTypes.FETCH_CITY_NAMES_FAILED:
      return updateObject(state, {
        cityNamesLoading: false,
        cityNamesError: action.error
      });

    default:
      return state;
  }
};

export default reducer;
