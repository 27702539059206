import React from 'react';
import { withRouter } from 'react-router-dom';
import Clipboard from 'grommet/components/icons/base/Clipboard';

const RedirectToClassPage = ({ history, gymId, classId }) => (
  <Clipboard
    size={'small'}
    type={'status'}
    style={{ margin: '0px', cursor: 'pointer' }}
    onClick={() => history.push(`/gym/${gymId}/class/${classId}`)}
  />
);

export default withRouter(RedirectToClassPage);
