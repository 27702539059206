import React, { useState } from 'react';
import { Button, Notification } from 'grommet';
import Spinning from 'grommet/components/icons/Spinning';
import { returnTemplateFields } from './returnTemplateFields';
import { useMutation } from 'react-query';
import { addTemplate, delayFetchFunctions, editTemplate } from '../../../apiFunctions/apiFunctions';
import { updateObject, checkValidity, returnErrorTextForField, returnErrorFromResponse } from '../../../shared/utility';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import FormCreator from '../../../components/UI/FormCreator/FormCreator';
import classes from './AddEditTemplate.module.scss';
import CustomToast from '../../../components/CustomToast/CustomToast';

const AddEditTemplate = ({ classType, selectedTemplate = null, selectedGym, onClose }) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [formFields, setFormFields] = useState(returnTemplateFields(classType, selectedTemplate));
  const [toast, setToast] = useState(null);

  const isNewTemplate = selectedTemplate === null || selectedTemplate === undefined;

  const mutateTemplate = useMutation(
    (formData) =>
      isNewTemplate ? addTemplate(selectedGym._id, formData) : editTemplate(selectedTemplate._id, formData),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: isNewTemplate ? 'Successfully created' : 'Successfully updated',
          show: true
        });
        delayFetchFunctions([['templates', classType]], 0);
        setTimeout(() => onClose(), 1500);
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  const valueChangedHandler = (newValue, identifier) => {
    const updated = updateObject(formFields, {
      [identifier]: updateObject(formFields[identifier], {
        value: newValue,
        valid: checkValidity(newValue, formFields[identifier].validation),
        errorText: returnErrorTextForField(formFields[identifier].validation),
        touched: true
      })
    });
    setFormFields(updated);
    setFormValidated(false);
  };

  const checkFormValidity = () => {
    setFormValidated(true);

    for (let input in formFields) {
      if (!formFields[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return setFormIsValid(false);
      }
    }
    setFormIsValid(true);
    saveTemplate();
  };

  const saveTemplate = () => {
    const formData = {
      currency: formFields.currency.value.value,
      private: formFields.private.value === 'yes',
      active: formFields.active.value === 'yes',
      classType: classType
    };
    const fieldsToSkip = ['currency', 'private', 'active'];

    for (let input in formFields) {
      if (!fieldsToSkip.includes(input)) {
        let value = formFields[input].value;
        formData[input] = value;
      }
    }
    mutateTemplate.mutate(formData);
  };

  const formWarning = !formIsValid && formValidated && (
    <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
  );

  const button = mutateTemplate.isLoading ? (
    <Spinning className={classes.spinner} />
  ) : (
    <Button
      className={classes.saveBtn}
      label={selectedTemplate ? 'Save' : 'Add'}
      secondary={true}
      onClick={checkFormValidity}
    />
  );

  return (
    <div>
      <div className={classes.scheduledList}>
        <Button label={'Templates list'} secondary={true} onClick={onClose} />
      </div>
      <div>
        <CustomToast toast={toast} />
        {formWarning}
        <FormCreator formData={formFields} valueChanged={valueChangedHandler} />
        {button}
      </div>
    </div>
  );
};

export default withAdminDashboard(AddEditTemplate);
