import React from 'react';

import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';

import SingleMembershipPlan from '../../../../components/SingleMembershipPlan/SingleMembershipPlan';

const MembershipPlanList = (props) => {
  let plans = [];
  if (props.availablePlans.length > 0) {
    for (const plan in props.availablePlans) {
      if (props.availablePlans.hasOwnProperty(plan)) {
        plans.push(props.availablePlans[plan]);
      }
    }
  }

  return (
    <List>
      {plans.map((plan) => (
        <ListItem key={plan._id} direction="column" align="start" separator="horizontal">
          <SingleMembershipPlan
            showJoiningFee={props.showJoiningFee}
            plan={plan}
            key={plan.planId}
            selectPlan={(plan) => {
              props.buyPlan(plan);
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};
export default MembershipPlanList;
