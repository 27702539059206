import axiosAuthApi from '../../axios-auth';
import axiosGlobalApi from '../../axios-global';
import * as actionTypes from './actionTypes';
import { returnErrorFromResponse } from '../../shared/utility';
import { setUnauthorizedError } from './utils';
import { createAction } from './actionTypes';

export const authCheckStatus = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return dispatch(authLogout());
    }

    dispatch(authSuccess(token));
    dispatch(fetchUserDetails());
  };
};

export const fetchUserDetails = () => {
  return (dispatch) => {
    dispatch(getUserDetailsStart());
    axiosGlobalApi.get('/users/me').then((response) => {
      dispatch(setUserDetails(response.data));
    });
  };
};

export const auth = ({ email, password, rememberMe }) => {
  return (dispatch) => {
    dispatch(authStart());

    axiosAuthApi
      .post('/local', {
        email,
        password,
        rememberMe
      })
      .then((response) => {
        dispatch(authSuccess(response.data.token));
        dispatch(setUnauthorizedError());
        dispatch(fetchUserDetails());
      })
      .catch((err) => dispatch(authFail(returnErrorFromResponse(err))));
  };
};

const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

const getUserDetailsStart = () => {
  return {
    type: actionTypes.AUTH_SET_USER_DATA_START
  };
};

export const authReset = () => {
  return {
    type: actionTypes.AUTH_RESET
  };
};

export const authSuccess = (token) => {
  localStorage.setItem('token', token);

  return {
    type: actionTypes.AUTH_SUCCESS,
    token
  };
};

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error
  };
};

export const authLogout = () => {
  localStorage.removeItem('token');

  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

const setUserDetails = (userData) => {
  return {
    type: actionTypes.AUTH_SET_USER_DATA,
    userData
  };
};

export const adjustClassesLeftNumber = (adjustment, nextMonth = false) => {
  return (dispatch, getState) => {
    if (typeof getState().auth.userData.classesLeft === 'boolean') {
      return;
    }
    dispatch(adjustClassesLeftInStore(adjustment, nextMonth));
  };
};

const adjustClassesLeftInStore = (adjustment, nextMonth) => {
  return {
    type: actionTypes.CLIENT_CHANGE_CLASS_PASSES_LIMIT,
    adjustment,
    nextMonth
  };
};

export const adjustPtsSessionNumber = (adjustment) => {
  return {
    type: actionTypes.CLIENT_CHANGE_PTS_LIMIT,
    adjustment
  };
};

export const updateUserData = (newData) => (dispatch) =>
  dispatch(createAction(actionTypes.UPDATE_USER_DATA, { newData }));
