import React from 'react';
import Spinning from 'grommet/components/icons/Spinning';

//xsmall|small|medium|large|xlarge|huge

const Loading = ({ size = 'small', topOffset = 0, bottomOffset = 0 }) => (
  <div style={{ margin: `${topOffset}px auto ${bottomOffset}px auto`, display: 'block' }}>
    <Spinning size={size} />
  </div>
);

export default Loading;
