import React from 'react';

import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';

import SingleMembershipPlan from '../../../components/SingleMembershipPlan/SingleMembershipPlan';
import classes from './ManageMembershipPlans.module.scss';

const ManageMembershipPlans = (props) => {
  let plans = <Spinning />;
  if (!props.loading && props.error) {
    plans = <Notification message={props.error} status="critical" />;
  }
  if (!props.loading && !props.error && props.plansList && props.plansList.length > 0) {
    plans = props.plansList.map((plan) => (
      <ListItem
        key={plan._id}
        direction="column"
        align="start"
        separator="horizontal"
        className={plan.active ? '' : classes.inactive}>
        <SingleMembershipPlan plan={plan} key={plan.planId} admin />
      </ListItem>
    ));
  }
  if (!props.loading && !props.error && props.plansList && props.plansList.length === 0) {
    plans = 'No plans';
  }

  return (
    <>
      <h4 className={classes.listHeader}>Membership plans</h4>
      <List>{plans}</List>
      <Button
        style={{ marginTop: '50px', display: 'inline-block' }}
        label="Add"
        secondary={true}
        path={`${props.match.url}/membership-plans/add`}
      />
    </>
  );
};
export default ManageMembershipPlans;
