import React from 'react';
import { withRouter } from 'react-router-dom';

import logo from '../../../brandConfig/logo-icons/brand-logo.png';
import logoDark from '../../../brandConfig/logo-icons/brand-logo-for-dark-background.png';
import classes from './logo.module.scss';

export default withRouter(({ history, ...props }) => {
  return (
    <img
      className={[classes.supersonicLogo, props.additionalClass].join(' ')}
      src={props.dark ? logoDark : logo}
      alt="Logo"
      onClick={() => history.push('/')}
    />
  );
});
