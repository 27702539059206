import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'grommet';
import SingleClassTypeHistory from './SingleClassTypeHistory';
import withUser from '../../store/hoc/withUser';
import withAdminDashboard from '../../store/hoc/withAdminDashboard';
import { returnAvailableClassTypesInGym } from '../../shared/gymFunctions';

const CombinedHistoryComponent = ({ selectedGym }) => {
  const tabs = returnAvailableClassTypesInGym(selectedGym.settings);

  return (
    <Tabs style={{ borderBottom: 'none', paddingBottom: '20px', fontWeight: 'bold' }} justify="start">
      {tabs.map((elem) => (
        <Tab key={elem.type} title={elem.title}>
          <SingleClassTypeHistory classType={elem.type} />
        </Tab>
      ))}
    </Tabs>
  );
};

export default withRouter(withUser(withAdminDashboard(CombinedHistoryComponent)));
