import { useState } from 'react';

const useSelectFilter = (initialState, onFilter) => {
  const [selectedFilter, setSelectedFilter] = useState(initialState);

  const onSelectedFilterChanged = (e) => {
    const filter = e.target.value;

    onFilter(filter);
    setSelectedFilter(filter);
  };

  const isSelectedFilter = (filter) => {
    return filter === selectedFilter;
  };

  return {
    onSelectedFilterChanged,
    isSelectedFilter,
    selectedFilter
  };
};

export default useSelectFilter;
