export const CLIENTS_FILTERS = {
  FULL_MEMBERS: 'full-member',
  CLASS_PASS_MEMBERS: 'class-pass-member',
  ALL_MEMBERS: 'all-member'
};
export const MEMBERSHIP_STATUS_FILTERS = {
  ACTIVE: 'member-active',
  EXPIRED: 'member-expired',
  ALL: 'member-all'
};

export const CLIENTS_ACTIVE_FILTERS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ALL: 'all'
};

export const CLIENTS_STATUS_FILTERS = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PERMISSION_DENIED: 'permissionDenied',
  WAITING_FOR_APPROVAL: 'waitingForApproval'
};

export const CLIENTS_TYPES = {
  FULL_MEMBER: 'full-member',
  CLASS_PASS_MEMBER: 'class-pass-member'
};

export const PRODUCT_TYPES = {
  CLASS_PASS: 'classPass',
  PERSONAL_TRAINING_PASS: 'personalTrainingPass',
  GYM_CLASS: 'gymClassPass',
  SWIMMING_CLASS: 'swimmingClassPass',
  TENNIS_CLASS: 'tennisClassPass',
  MASSAGE_CLASS: 'massageClassPass'
};

export const CLASS_TYPES = [
  'class',
  'personalTraining',
  'induction',
  'gymClass',
  'swimmingClass',
  'tennisClass',
  'massageClass'
];
export const ALL_CLASS_TYPES_WITH_DETAILS = () => [
  CLASS_DETAILS,
  PERSONAL_TRAINING_SESSION_DETAILS,
  INDUCTION_CLASS_DETAILS,
  GYM_CLASS_DETAILS,
  SWIMMING_CLASS_DETAILS,
  TENNIS_CLASS_DETAILS,
  MASSAGE_CLASS_DETAILS
];

export const CLASS_LEFT_NAMES = [
  'classesLeft',
  'PTclassesLeft',
  'gymClassesLeft',
  'swimmingClassesLeft',
  'tennisClassesLeft',
  'massageClassesLeft'
];

export const CLASS_DETAILS = {
  type: 'class',
  name: 'class',
  title: 'Class',
  passesUrl: 'class-passes',
  addOneOffUrl: 'class-timetable/add-one-off',
  usersClassesLeft: 'classesLeft'
};

export const PERSONAL_TRAINING_SESSION_DETAILS = {
  type: 'personalTraining',
  name: 'personal training',
  title: 'PT',
  passesUrl: 'personal-training-passes',
  addOneOffUrl: 'personal-training-timetable/add-one-off',
  usersClassesLeft: 'PTclassesLeft'
};

export const INDUCTION_CLASS_DETAILS = {
  type: 'induction',
  name: 'induction',
  title: 'Induction',
  passesUrl: null,
  addOneOffUrl: 'induction-timetable/add-one-off',
  usersClassesLeft: null
};

export const GYM_CLASS_DETAILS = {
  type: 'gymClass',
  name: 'gym',
  title: 'Gym',
  passesUrl: 'gym-passes',
  addOneOffUrl: 'gym-timetable/add-one-off',
  usersClassesLeft: 'gymClassesLeft'
};

export const SWIMMING_CLASS_DETAILS = {
  type: 'swimmingClass',
  name: 'swim',
  title: 'Swim',
  passesUrl: 'swim-passes',
  addOneOffUrl: 'swim-timetable/add-one-off',
  usersClassesLeft: 'swimmingClassesLeft'
};

export const TENNIS_CLASS_DETAILS = {
  type: 'tennisClass',
  name: 'tennis',
  title: 'Tennis',
  passesUrl: 'tennis-passes',
  addOneOffUrl: 'tennis-timetable/add-one-off',
  usersClassesLeft: 'tennisClassesLeft'
};

export const MASSAGE_CLASS_DETAILS = {
  type: 'massageClass',
  name: 'massage',
  title: 'Massage',
  passesUrl: 'massage-passes',
  addOneOffUrl: 'massage-timetable/add-one-off',
  usersClassesLeft: 'massageClassesLeft'
};

export const ALL_CURRENCIES = [
  {
    label: 'Pound sterling',
    value: 'gbp',
    symbol: '£'
  },
  {
    label: 'Euro',
    value: 'eur',
    symbol: '€'
  },
  {
    label: 'US Dollar',
    value: 'usd',
    symbol: '$'
  },
  {
    label: 'Australian Dollar',
    value: 'aud',
    symbol: 'A$'
  }
];

export const DEFAULT_WAIT_LIST_CONFIG = {
  active: false,
  minimalTimeBeforeClassStartsToNotifyUsers: 2,
  timeDelayForSendingNotifications: 2
};
