import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import CheckBox from 'grommet/components/CheckBox';
import Anchor from 'grommet/components/Anchor';
import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';

import Title from '../../components/UI/Title/Title';
import FormCreator from '../../components/UI/FormCreator/FormCreator';

import classes from './Login.module.scss';

import * as actions from '../../store/actions/index';

import { updateObject, checkValidity, returnErrorTextForField } from '../../shared/utility';

const mapDispatchToProps = (dispatch) => {
  return {
    onLoginStart: () => dispatch(actions.authReset()),
    onAuthUser: (email, password) => dispatch(actions.auth(email, password)),
    onSetUnauthorizedError: (status) => dispatch(actions.setUnauthorizedError(status))
  };
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    userData: state.auth.userData
  };
};

class Login extends Component {
  state = {
    loginError: false,
    rememberMe: true,
    loginForm: {
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        cy_id: 'login_email',
        valid: false,
        touched: false,
        errorText: null
      },
      password: {
        label: 'Password',
        type: 'password',
        placeholder: 'Enter password',
        value: '',
        validation: {
          required: true
        },
        cy_id: 'login_password',
        valid: false,
        touched: false,
        errorText: null,
        keyPress: true
      }
    }
  };

  componentDidMount() {
    this.props.onLoginStart();
  }

  handleKeyPress = (event, elementIdentifier) => {
    if (event.key === 'Enter' && elementIdentifier === 'password') {
      this.checkFormValidity();
    }
  };

  valueChangedHandler = (newValue, identifier) => {
    const updatedloginForm = updateObject(this.state.loginForm, {
      [identifier]: updateObject(this.state.loginForm[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.loginForm[identifier].validation),
        errorText: returnErrorTextForField(this.state.loginForm[identifier].validation),
        touched: true
      })
    });
    return this.setState({
      loginForm: updatedloginForm
    });
  };

  onCheckboxChange = () => this.setState({ rememberMe: !this.state.rememberMe });

  checkFormValidity = () => {
    for (let input in this.state.loginForm) {
      if (!this.state.loginForm[input].valid) {
        const updatedloginForm = updateObject(this.state.loginForm, {
          [input]: updateObject(this.state.loginForm[input], {
            valid: checkValidity(this.state.loginForm[input].value, this.state.loginForm[input].validation),
            errorText: returnErrorTextForField(this.state.loginForm[input].validation),
            touched: true
          })
        });
        return this.setState({
          loginForm: updatedloginForm
        });
      }
    }
    this.onAuthHandler();
  };

  onAuthHandler = () => {
    this.props.onSetUnauthorizedError(false);
    this.props.onAuthUser({
      email: this.state.loginForm.email.value.trim(),
      password: this.state.loginForm.password.value,
      rememberMe: this.state.rememberMe
    });
  };

  render() {
    let button = (
      <Button
        className={classes.loginButton}
        box
        data-cy="login_btn"
        label="Login"
        secondary={true}
        onClick={this.checkFormValidity}
      />
    );
    if (this.props.loading) {
      button = (
        <div className={classes.spinnerContainer}>
          <Spinning />
        </div>
      );
    }

    let errorNotification = null;
    if (this.props.error) {
      errorNotification = (
        <div className={classes.notifcation}>
          <Notification message={this.props.error} status="critical" />
        </div>
      );
    }

    if (this.props.isAuthenticated) {
      return <Redirect to="/gym" />;
    }

    return (
      <div className={classes.loginContainer}>
        <Title header="Login" />

        {errorNotification}

        <FormCreator
          formData={this.state.loginForm}
          valueChanged={this.valueChangedHandler}
          keyPress={this.handleKeyPress}
        />

        <div className={classes.additionalOptions}>
          <CheckBox
            defaultChecked={this.state.rememberMe}
            onChange={this.onCheckboxChange}
            className={classes.remeberMeCheckbox}
            label="Remember me"
          />
          <Anchor className={classes.forgotPasswordLink} path="/forgot-password" label="Forgot password?" />
        </div>

        {button}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
