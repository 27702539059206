import React, { useState } from 'react';
import Button from 'grommet/components/Button';
import Notification from 'grommet/components/Notification';
import { useMutation } from 'react-query';
import FormCreator from '../../../components/UI/FormCreator/FormCreator';
import { updateObject, checkValidity, returnErrorTextForField, returnErrorFromResponse } from '../../../shared/utility';
import { returnFAQFields } from './returnsFAQFields';
import { addFAQ, delayFetchFunctions, editFAQ } from '../../../apiFunctions/apiFunctions';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Loading from '../../../components/Loading/Loading';

const AddEditFAQ = ({ selectedGym, selectedFAQ, onClose }) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [formFields, setFormFields] = useState(returnFAQFields(selectedGym.settings, selectedFAQ ? selectedFAQ : null));
  const [toast, setToast] = useState(null);

  const isNewFaq = selectedFAQ === null || selectedFAQ === undefined;

  const mutateFAQ = useMutation(
    (formData) => (isNewFaq ? addFAQ(selectedGym._id, formData) : editFAQ(selectedFAQ._id, formData)),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: isNewFaq ? 'Successfully added' : 'Successfully updated',
          show: true
        });
        delayFetchFunctions([['faq']], 1000);
        onClose();
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  const valueChangedHandler = (newValue, identifier) => {
    const updatedNewFAQ = updateObject(formFields, {
      [identifier]: updateObject(formFields[identifier], {
        value: newValue,
        valid: checkValidity(newValue, formFields[identifier].validation),
        errorText: returnErrorTextForField(formFields[identifier].validation),
        touched: true
      })
    });
    setFormFields(updatedNewFAQ);
    setFormValidated(false);
  };

  const checkFormValidity = () => {
    setFormValidated(true);

    for (let input in formFields) {
      if (!formFields[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return setFormIsValid(false);
      }
    }
    setFormIsValid(true);
    saveFAQ();
  };

  const saveFAQ = () => {
    const formData = {};
    for (let input in formFields) {
      formData[input] = formFields[input].value;
    }
    mutateFAQ.mutate(formData);
  };

  let formWarning = !formIsValid && formValidated && (
    <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
  );

  const button = mutateFAQ.isLoading ? (
    <Loading topOffset={50} />
  ) : (
    <Button
      style={{ marginTop: '50px', display: 'block' }}
      label={isNewFaq ? 'Add' : 'Save'}
      secondary={true}
      onClick={checkFormValidity}
    />
  );

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <Button label={'FAQ list'} secondary={true} onClick={onClose} />
      </div>
      <div>
        <CustomToast toast={toast} />
        {formWarning}
        <FormCreator formData={formFields} valueChanged={valueChangedHandler} />
        {button}
      </div>
    </div>
  );
};

export default AddEditFAQ;
