import React, { useState, useEffect } from 'react';
import Select from 'grommet/components/Select';

export default function ClientProfilePaymentMethodSelection({ onMethodSelected }) {
  const [method, setMethod] = useState('cash');
  useEffect(
    () => onMethodSelected('cash'),
    // eslint-disable-next-line
    []
  );
  const chooseNewMethod = (event) => {
    setMethod(event.value);
    onMethodSelected(event.value);
  };

  const methods = ['cash', 'bank transfer', 'card', 'paid by other'];

  return <Select options={methods} value={method} onChange={chooseNewMethod} />;
}
