import axios from 'axios';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_DEV_ENV_API}/auth`
      : `${process.env.REACT_APP_PROD_ENV_API}/auth`
});

export default instance;
