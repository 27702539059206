import React, { useState } from 'react';
import { Table, TableHeader, TableRow } from 'grommet';
import classes from '../TrainerUpcomingClasses/TrainerUpcomingClasses.module.scss';
import Modal from 'react-modal';
import UserDetailsLink from '../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import Group from 'grommet/components/icons/base/Group';
import withUser from '../../store/hoc/withUser';
import { WaitListUserButton } from './WaitListUserButton/WaitListUserButton';

const WaitListModal = ({ selectedClass, isAdmin }) => {
  const [showModal, setShowModal] = useState(false);

  if (selectedClass && selectedClass.waitListConfig && selectedClass.waitListConfig.active) {
    const renderWaitListModal = () => {
      const waitList = selectedClass.waitList.filter((elem) => elem.status === 'active');

      const countUsers = waitList.length;
      const rows = (waitListItem) => {
        return waitListItem.map((element) => (
          <TableRow className={classes.TableRowDesign} key={element._id}>
            <td>{element.userData.name}</td>
            <td>{element.userData.last_name}</td>
            <UserDetailsLink gymId={selectedClass.gymId[0]} clientId={element.user} />
          </TableRow>
        ));
      };
      const renderWaitListTable = (waitList) =>
        waitList.length > 0 ? (
          <Table responsive={true}>
            <TableHeader labels={['Name', 'Last name', '']} />
            <tbody>{rows(waitList)}</tbody>
          </Table>
        ) : (
          <div>No users</div>
        );

      return (
        <Modal isOpen={showModal} className="ss-modal" onRequestClose={() => setShowModal(false)}>
          <div className="grommet">
            <h2> Waiting users: {countUsers}</h2>
            {renderWaitListTable(waitList)}
          </div>
        </Modal>
      );
    };

    return (
      <div>
        {renderWaitListModal()}
        {isAdmin ? (
          <Group style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)} />
        ) : (
          <WaitListUserButton selectedClass={selectedClass} />
        )}
      </div>
    );
  }
  return null;
};

export default withUser(WaitListModal);
