import React, { Component } from 'react';

import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';

import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import Title from '../../components/UI/Title/Title';
import FormCreator from '../../components/UI/FormCreator/FormCreator';

import axios from '../../axios-global';

import classes from './ResetPassword.module.scss';

import { updateObject, checkValidity, returnErrorTextForField } from '../../shared/utility';

export default class ResetPassword extends Component {
  state = {
    loading: false,
    loginError: false,
    notification: null,
    notificationType: 'ok',
    samePasswords: false,
    passwordResetCode: null,
    passwordChanged: false,
    resetPasswordForm: {
      password: {
        label: 'Password',
        type: 'password',
        placeholder: 'Enter password',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      confirmPassword: {
        label: 'Confirm password',
        type: 'password',
        placeholder: 'Confirm password',
        value: '',
        basePasswordValue: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    }
  };

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    if (!query.has('token')) {
      return this.props.history.push('/login');
    }
    this.setState({
      passwordResetCode: query.get('token')
    });
  }

  valueChangedHandler = (newValue, identifier) => {
    if (identifier === 'confirmPassword') {
      const updatedResetPasswordForm = updateObject(this.state.resetPasswordForm, {
        [identifier]: updateObject(this.state.resetPasswordForm[identifier], {
          value: newValue,
          valid: checkValidity(newValue, this.state.resetPasswordForm[identifier].validation),
          errorText: returnErrorTextForField(this.state.resetPasswordForm[identifier].validation),
          touched: true,
          basePasswordValue: this.state.resetPasswordForm.password.value
        })
      });
      return this.setState({
        resetPasswordForm: updatedResetPasswordForm,
        samePasswords: updatedResetPasswordForm.password.value === updatedResetPasswordForm.confirmPassword.value
      });
    }

    const updatedResetPasswordForm = updateObject(this.state.resetPasswordForm, {
      [identifier]: updateObject(this.state.resetPasswordForm[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.resetPasswordForm[identifier].validation),
        errorText: returnErrorTextForField(this.state.resetPasswordForm[identifier].validation),
        touched: true
      })
    });

    return this.setState({
      resetPasswordForm: updatedResetPasswordForm,
      samePasswords: updatedResetPasswordForm.password.value === updatedResetPasswordForm.confirmPassword.value
    });
  };

  checkFormValidity = () => {
    for (let input in this.state.resetPasswordForm) {
      if (!this.state.resetPasswordForm[input].valid) {
        const updatedResetPasswordForm = updateObject(this.state.resetPasswordForm, {
          [input]: updateObject(this.state.resetPasswordForm[input], {
            valid: checkValidity(
              this.state.resetPasswordForm[input].value,
              this.state.resetPasswordForm[input].validation
            ),
            errorText: returnErrorTextForField(this.state.resetPasswordForm[input].validation),
            touched: true
          })
        });
        return this.setState({
          resetPasswordForm: updatedResetPasswordForm
        });
      }
    }
    this.saveNewPassword();
  };

  saveNewPassword = () => {
    this.setState({
      loading: true,
      notification: null,
      notificationType: 'ok'
    });

    axios
      .post(`/users/change-reseted-password`, {
        newPassword: this.state.resetPasswordForm.password.value,
        passwordResetCode: this.state.passwordResetCode
      })
      .then((resp) => {
        this.setState({
          loading: false,
          notification: resp.data.message,
          resetPasswordForm: null,
          passwordChanged: true
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          notificationType: 'critical',
          notification:
            err.response.data.message && typeof err.response.data.message !== 'object'
              ? err.response.data.message
              : 'Server error'
        });
      });
  };

  render() {
    let button = (
      <Button
        className={classes.button}
        box
        label="Save new password"
        secondary={true}
        onClick={this.state.samePasswords ? this.checkFormValidity : null}
      />
    );
    if (this.state.loading) {
      button = (
        <div className={classes.spinnerContainer}>
          <Spinning />
        </div>
      );
    }
    if (this.state.passwordChanged) {
      button = <Button className={classes.button} box label="Go to login" secondary={true} path={'/login'} />;
    }

    let errorNotification = null;
    if (this.state.notification) {
      errorNotification = (
        <div className={classes.notification}>
          <Notification message={this.state.notification} status={this.state.notificationType} />
        </div>
      );
    }

    return (
      <WhiteBackground>
        <div className={classes.formContainer}>
          <Title header="Your new password" />

          {errorNotification}

          <FormCreator formData={this.state.resetPasswordForm} valueChanged={this.valueChangedHandler} />

          {button}
        </div>
      </WhiteBackground>
    );
  }
}
