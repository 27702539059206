import React from 'react';
import { TextInput } from 'grommet';
import useSelectFilter from '../hooks/useSelectFilter';

const ClientNameFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged } = useSelectFilter('', onFilter);

  return (
    <div className="client__name-filter">
      <h4>Filter by name</h4>
      <TextInput name="client-name-filter" placeHolder="client name" onDOMChange={onSelectedFilterChanged} />
    </div>
  );
};

export default ClientNameFilter;
