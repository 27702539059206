import React from 'react';

import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';

const Error404 = () => {
  const containerStyle = {
    minHeight: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const headerStyle = {
    fontWeight: 700,
    fontSize: '144px',
    color: '#FFFFFF',
    lineHeight: '42px',
    textShadow: '0 0 50px rgba(0,0,0,0.20)',
    margin: '0 28px 0 0'
  };

  const column = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%'
  };

  const secondHeaderStyle = {
    fontSize: '36px',
    fontWeight: 700,
    margin: 0
  };

  const thirdHeaderStyle = {
    margin: 0,
    fontSize: '18px',
    fontWeight: 300
  };

  return (
    <WhiteBackground>
      <div style={containerStyle}>
        <h1 style={headerStyle}>404</h1>
        <div style={column}>
          <h2 style={secondHeaderStyle}>OOPS!</h2>
          <h3 style={thirdHeaderStyle}>Page not found</h3>
        </div>
      </div>
    </WhiteBackground>
  );
};
export default Error404;
