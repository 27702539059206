import React from 'react';

import Heading from 'grommet/components/Heading';
import ContactDetails from '../../brandConfig/componentsTexts/ContactDetails';

import classes from './Contact.module.scss';

const Contact = () => {
  return (
    <div className={'container'}>
      <div className={classes.detailsContainer}>
        <Heading className={classes.header}>Contact us</Heading>
        <ContactDetails />
      </div>
    </div>
  );
};
export default Contact;
