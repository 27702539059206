import React from 'react';
import { withRouter } from 'react-router-dom';
import classes from './SingleClient.module.scss';
import axios from '../../../../axios-global';

import Toast from 'grommet/components/Toast';
import AddUserIcon from 'grommet/components/icons/base/UserAdd';
import DenyUserIcon from 'grommet/components/icons/base/Clear';
import InactiveUserIcon from 'grommet/components/icons/base/Close';
import TableRow from 'grommet/components/TableRow';
import { CLIENTS_TYPES } from '../../../../constants';

class SingleClient extends React.Component {
  state = {
    showError: false,
    showConfirm: false
  };

  changeClientStatus = (status) => {
    axios
      .patch(`/users/${this.props.client._id}`, { status: status })
      .then(() => {
        this.setState({
          showError: false
        });
        this.props.fetchClients(this.props.client.gymId);
      })
      .catch(() => this.setState({ showError: true }));
  };

  handleUserDelete = () => {
    if (!this.state.showConfirm) {
      return this.setState({
        showConfirm: true
      });
    }
    this.changeClientStatus('inactive');
  };

  closeToast = () => {
    this.setState({
      showConfirm: false
    });
  };

  addUser = () => (
    <AddUserIcon
      size={'large'}
      type={'status'}
      className={classes.icon}
      onClick={() => this.changeClientStatus('active')}
    />
  );

  deniedUser = () => (
    <DenyUserIcon
      size={'large'}
      type={'status'}
      className={classes.icon}
      onClick={() => this.changeClientStatus('permissionDenied')}
    />
  );

  inactiveUser = () => (
    <InactiveUserIcon size={'large'} type={'status'} className={classes.icon} onClick={() => this.handleUserDelete()} />
  );

  render() {
    const renderNotification = () => {
      if (this.props.updateUserDetailsError) {
        return <Toast status="critical">Something went wrong. Try again.</Toast>;
      }

      if (this.state.showConfirm) {
        return (
          <Toast status="warning" onClose={this.closeToast}>
            Click once again to confirm the deletion of the user
          </Toast>
        );
      }
    };

    return (
      <>
        {renderNotification()}
        <TableRow direction="column" align="start" separator="horizontal" key={this.props.client.id}>
          <td
            className={classes.singleClientName}
            onClick={() => this.props.history.push(`${this.props.match.url}/${this.props.client._id}`)}>
            {this.props.client.name} {this.props.client.last_name}
          </td>

          {this.props.showGymName && <td>{this.props.client.gym.name}</td>}

          <td>{this.props.client.customerType === CLIENTS_TYPES.FULL_MEMBER ? 'Membership' : 'Class Pass'}</td>

          <td>{this.props.client.customerType === CLIENTS_TYPES.FULL_MEMBER ? this.props.client.status : 'N/A'}</td>

          <td> {this.props.client.marketing === 'true' ? 'Y' : 'N'}</td>

          <td>
            {this.props.isAdmin && (
              <div className={classes.actionButtons}>
                {this.props.client.status === 'waitingForApproval' && (
                  <>
                    <span>Activate new client?</span>
                    {this.addUser()}
                    {this.deniedUser()}
                  </>
                )}
                {this.props.client.status === 'active' && <> {this.inactiveUser()}</>}
                {this.props.client.status === 'inactive' && (
                  <>
                    <span>Inactive client</span>
                    {this.addUser()}
                  </>
                )}
                {this.props.client.status === 'permissionDenied' && (
                  <>
                    <span>Permission denied</span>
                    {this.addUser()}
                  </>
                )}
              </div>
            )}
          </td>
        </TableRow>
      </>
    );
  }
}

export default withRouter(SingleClient);
