import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import classes from './AdminDashboard.module.scss';
import Notification from 'grommet/components/Notification';
import ManageMembershipPlans from './ManageMembershipPlans/ManageMembershipPlans';
import ManageGym from './ManageGym/ManageGym';
import AddEditMembershipPlan from './ManageMembershipPlans/AddEditMembershipPlan/AddEditMembershipPlan';
import ManageClients from './ManageClients/ManageClients';
import ManageTrainers from './ManageTrainers/ManageTrainers';
import ManageVideos from './ManageVideos/ManageVideos';
import MediaCategory from './ManageVideos/MediaCategory/MediaCategory';
import TrainersAddEditTrainer from './ManageTrainers/TrainersAddEditTrainer/TrainersAddEditTrainer';
import ClientProfile from './ClientProfile/ClientProfile';
import AdminQrScanner from './AdminQrScanner/AdminQrScanner';
import Reports from './Reports';
import ClassPassesReport from './Reports/ClassPasses';
import ClassAttendancesReports from './Reports/ClassAttendancesReports/ClassAttendancesReports';
import AttendancesReports from './Reports/AttendancesReports/AttendancesReports';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import ClassPage from '../ClassPage/ClassPage';
import ManageFAQ from '../FAQ/ManageFAQ';
import CombinedHistoryComponent from '../HistoryComponent/CombinedHistoryComponent';
import NeedsApproval from './NeedsApproval/NeedsApproval';
import KitList from '../../components/KitList/KitList';
import ManageAdmins from './ManageAdmins/ManageAdmins';
import AddEditAdmin from './ManageAdmins/AddEditAdmin/AddEditAdmin';
import LiveStreamBroadcaster from '../LiveStream/LiveStreamBroadcaster/LiveStreamBroadcaster';
import StaffCalendar from './StaffCalendar/StaffCalendar';
import withUser from '../../store/hoc/withUser';
import withAdminDashboard from '../../store/hoc/withAdminDashboard';
import ManageTimetable from '../Timetables/ManageTimetable/ManageTimetable';
import AdminTrainerSubHeader from '../../components/Navigation/AdminTrainerSubHeader/AdminTrainerSubHeader';
import { isFullAccessAdminOrTrainer } from '../../shared/userFunctions';
import TilesBoard from '../../components/UI/TilesBoard/TilesBoard';
import { menuItemsArray } from '../../components/Navigation/MenuNavigation/menuItems';
import TrainersUpcomingClasses from '../TrainersUpcomingClasses/TrainersUpcomingClasses';
import Loading from '../../components/Loading/Loading';
import BulkEditing from '../BulkEditing/BulkEditing';
import UpcomingClassesForAllGyms from '../Timetables/UpcomingClassesForAllGyms/UpcomingClassesForAllGyms';
import DatabaseSearches from './DatabaseSearches/DatabaseSearches';

const AdminTrainerDashboard = (props) => {
  useEffect(
    () => {
      props.onFetchLocations();
      props.onFetchGymUsersList(props.match.params.gymId);
      props.onFetchGymDetails(props.match.params.gymId);
      props.onFetchTrainersList(props.match.params.gymId);

      if (props.userData.role === 'admin') {
        if (isFullAccessAdminOrTrainer(props.userData)) {
          props.onFetchTrainersList('all');
        }
        props.onFetchPlansList(props.match.params.gymId);
        props.onFetchClassesTemplatesList(props.match.params.gymId);
        props.onFetchGymAdmins(props.match.params.gymId);
      }
    }, // eslint-disable-next-line
    []
  );

  const renderGymDashboard = () => {
    if (!props.gymDataLoading && !props.gymDataError) {
      const tiles = menuItemsArray(
        props.userData,
        props.selectedGym.settings,
        props.match.url,
        props.locations,
        'showAsTile'
      );
      return (
        <>
          <Switch>
            <Route path={props.match.url} exact render={() => <TilesBoard tiles={tiles} />} />

            {/**DATABASE SEARCH**/}
            <Route path={`${props.match.url}/search`} component={DatabaseSearches} />

            {/**BULK MANAGEMENT ROUTES**/}
            <Route path={`${props.match.url}/bulk-management`} component={BulkEditing} />

            {/**MANAGE CLIENT ROUTES**/}
            <Route
              path={`${props.match.url}/clients`}
              exact
              render={() => (
                <ManageClients
                  allGymsClients={false}
                  isAdmin={props.userData.role === 'admin'}
                  error={props.gymUsersListError}
                  loading={props.gymUsersListLoading}
                  classPasses={props.classPasses}
                  personalTrainings={props.personalTrainings}
                  gymMemberships={props.plansList}
                  gymSettings={props.selectedGym.settings}
                  match={props.match}
                  history={props.history}
                />
              )}
            />
            <Route path={`${props.match.url}/clients/:clientId`} component={ClientProfile} />

            {/**MANAGE ADMINS ROUTES**/}
            <Route path={`${props.match.url}/admins`} exact component={ManageAdmins} />
            <Route path={`${props.match.url}/admins/add`} render={() => <AddEditAdmin editAdmin={false} />} />

            {/**MANAGE TRAINER ROUTES**/}
            <Route
              path={`${props.match.url}/trainers`}
              exact
              render={() => (
                <ManageTrainers
                  locations={props.locations}
                  userData={props.userData}
                  trainersList={props.trainersList}
                  refreshTrainersList={props.onFetchTrainersList}
                  error={props.trainersListError}
                  loading={props.trainersListLoading}
                  match={props.match}
                  history={props.history}
                />
              )}
            />
            <Route path={`${props.match.url}/trainers/add`} exact component={TrainersAddEditTrainer} />

            {/**GYM SETTINGS ROUTES**/}
            <Route path={`${props.match.url}/settings`} exact component={ManageGym} />
            <Route
              path={`${props.match.url}/needs-approval`}
              render={() => (
                <NeedsApproval match={props.match} history={props.history} isAdmin={props.userData.role === 'admin'} />
              )}
            />

            {/**REPORT ROUTES**/}
            <Route path={`${props.match.url}/reports`} exact component={Reports} />
            <Route path={`${props.match.url}/reports/class-passes-reports`} exact component={ClassPassesReport} />
            <Route
              path={`${props.match.url}/reports/class-attendances-reports`}
              exact
              render={() => <ClassAttendancesReports locations={props.locations} history={props.history} />}
            />
            <Route
              path={`${props.match.url}/reports/attendances-reports`}
              exact
              render={() => <AttendancesReports locations={props.locations} history={props.history} />}
            />

            {/**MEMBERSHIP PLAN ROUTES**/}
            <Route
              path={`${props.match.url}/membership-plans`}
              exact
              render={() => (
                <ManageMembershipPlans
                  plansList={props.plansList}
                  error={props.plansListError}
                  loading={props.plansListLoading}
                  match={props.match}
                />
              )}
            />
            <Route path={`${props.match.url}/membership-plans/add`} component={AddEditMembershipPlan} />
            <Route path={`${props.match.url}/membership-plans/edit`} component={AddEditMembershipPlan} />

            {/**TRAINER ROUTES**/}
            {props.userData.role === 'trainer' && (
              <Route path={`${props.match.url}/my-timetable`} component={TrainersUpcomingClasses} />
            )}

            {/**STAFF CALENDAR**/}
            <Route path={`${props.match.url}/staff-calendar`} exact component={StaffCalendar} />

            {/**CLASS TYPES ROUTES**/}
            <Route path={`${props.match.url}/class-history`} exact component={CombinedHistoryComponent} />
            <Route
              path={`${props.match.url}/class/:classId`}
              exact
              render={() => (
                <ClassPage
                  isAdmin={props.userData.role === 'admin'}
                  trainersList={props.trainersList}
                  clientsList={props.clientsList}
                  clientsListError={props.gymUsersListError}
                  clientsListLoading={props.gymUsersListLoading}
                />
              )}
            />

            {/**CLASS ROUTES**/}
            <Route
              path={`${props.match.url}/class-timetable`}
              exact
              render={() =>
                props.selectedGym.settings.class ? (
                  <ManageTimetable classType="class" />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />

            {/**PERSONAL TRAINING ROUTES**/}
            <Route
              path={`${props.match.url}/personal-training-timetable`}
              exact
              render={() =>
                props.selectedGym.settings.personalTraining ? (
                  <ManageTimetable classType={'personalTraining'} />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />

            {/**INDUCTION ROUTES**/}
            <Route
              path={`${props.match.url}/induction-timetable`}
              exact
              render={() =>
                props.selectedGym.settings.induction ? (
                  <ManageTimetable classType={'induction'} />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />

            {/**GYM CLASS ROUTES**/}
            <Route
              path={`${props.match.url}/gym-timetable`}
              exact
              render={() =>
                props.selectedGym.settings.gymClass ? (
                  <ManageTimetable classType="gymClass" />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />

            {/**SWIM CLASS ROUTES**/}
            <Route
              path={`${props.match.url}/swim-timetable`}
              exact
              render={() =>
                props.selectedGym.settings.swimmingClass ? (
                  <ManageTimetable classType="swimmingClass" />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />

            {/**TENNIS CLASS ROUTES**/}
            <Route
              path={`${props.match.url}/tennis-timetable`}
              exact
              render={() =>
                props.selectedGym.settings.tennisClass ? (
                  <ManageTimetable classType="tennisClass" />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />

            {/**MASSAGE CLASS ROUTES**/}
            <Route
              path={`${props.match.url}/massage-timetable`}
              exact
              render={() =>
                props.selectedGym.settings.massageClass ? (
                  <ManageTimetable classType="massageClass" />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />

            {/**ALL UPCOMING CLASSES FOR ALL GYMS**/}
            <Route path={`${props.match.url}/timetable/all`} exact component={UpcomingClassesForAllGyms} />

            {/**VIDEO ROUTES**/}
            <Route path={`${props.match.url}/videos`} exact component={ManageVideos} />
            <Route path={`${props.match.url}/videos/media-category`} exact component={MediaCategory} />

            {/**FAQ ROUTES**/}
            <Route path={`${props.match.url}/faq`} exact component={ManageFAQ} />

            {/**KIT LIST ROUTES**/}
            <Route path={`${props.match.url}/kit-list`} exact component={() => <KitList editableView={true} />} />

            <Route
              path={`${props.match.url}/videos/live-stream`}
              exact
              render={() => <LiveStreamBroadcaster userData={props.userData} />}
            />
          </Switch>
        </>
      );
    }

    if (props.gymDataError) {
      return <Notification message={`Error when creating admin dashboard. ${props.gymDataError}`} status="critical" />;
    }

    return <Loading />;
  };

  const renderQrReader = () => {
    if (props.globalQrReader) {
      return <AdminQrScanner isQRCodeAvailable={props.selectedGym.settings.qrCode} />;
    }
    return null;
  };
  const mainDivStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh'
  };

  return (
    <>
      {props.loading ||
      props.gymDataLoading ||
      !props.selectedGym ||
      props.gymUsersListLoading ||
      props.scheduledClassesListLoading ||
      props.plansListLoading ||
      props.classesTemplatesListLoading ? (
        <div style={mainDivStyles}>
          <Loading size={'xlarge'} />
        </div>
      ) : (
        <>
          <AdminTrainerSubHeader />
          <WhiteBackground>
            <div className={classes.dashboardContainer}>{renderGymDashboard()}</div>
            {renderQrReader()}
          </WhiteBackground>
        </>
      )}
    </>
  );
};

export default withUser(withAdminDashboard(AdminTrainerDashboard));
