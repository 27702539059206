import React, { useState } from 'react';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import SearchClients from './SearchClients/SearchClients';

const DatabaseSearches = () => {
  const [selected, setSelected] = useState(null);
  const databaseSearches = [
    {
      name: 'Client Search',
      type: 'client-search'
    }
  ];

  const returnProperSearch = () => {
    if (selected !== null && selected === 'client-search') {
      return <SearchClients />;
    }
    return (
      <div className="report">
        <List className="report__list">
          {databaseSearches.map((elem, index) => (
            <ListItem className="report__list__item" key={index} onClick={() => setSelected(elem.type)}>
              <span>{elem.name}</span>
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return returnProperSearch();
};

export default DatabaseSearches;
