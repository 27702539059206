import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Tabs, Tab } from 'grommet';

import EditGym from './EditGymSettings/EditGym';
import EditGymSettings from './EditGymSettings/EditGymSettings';
import EditAssociatedNames from './AddEditAssociatedNames/EditAssociatedNames';

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym
  };
};

class ManageGym extends Component {
  render() {
    return (
      <>
        <Tabs
          style={{
            borderBottom: 'none',
            paddingBottom: '20px',
            fontWeight: 'bold'
          }}
          justify="start">
          <Tab title="Basic information">
            <EditGym data={this.props.selectedGym} />
          </Tab>

          <Tab title="Associated names">
            <EditAssociatedNames names={this.props.selectedGym.associatedNames} selectedGym={this.props.selectedGym} />
          </Tab>

          <Tab title="Gym properties">
            <EditGymSettings gym={this.props.selectedGym} />
          </Tab>
        </Tabs>
      </>
    );
  }
}

export default connect(mapStateToProps)(ManageGym);
