import React, { useState } from 'react';
import FormCreator from '../../../components/UI/FormCreator/FormCreator';
import { returnFormFields } from './returnFormFields';
import { checkValidity, returnErrorTextForField, updateObject } from '../../../shared/utility';
import Button from 'grommet/components/Button';
import { Notification } from 'grommet';
import { useMutation } from 'react-query';
import { bulkClassEditing, delayFetchFunctions } from '../../../apiFunctions/apiFunctions';
import CustomToast from '../../../components/CustomToast/CustomToast';
import CheckBox from 'grommet/components/CheckBox';
import { WaitListForm } from '../../WaitList/WaitListForm/WaitListForm';
import pick from 'lodash/pick';
import { DEFAULT_WAIT_LIST_CONFIG } from '../../../constants';
import moment from 'moment';

const BulkEditingForm = ({ data, fields, closeModal, updatedData = null, classType }) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [formFields, setFormFields] = useState(returnFormFields(fields, updatedData));
  const [sendClassChangeNotification, setSendClassChangeNotification] = useState(false);

  const [waitListFormValid, setWaitListFormValid] = useState(true);
  const [waitListConfig, setWaitListConfig] = useState(
    updatedData ? updatedData.waitListConfig : DEFAULT_WAIT_LIST_CONFIG
  );

  const [toast, setToast] = useState(returnFormFields(fields));
  const showWaitList = fields.includes('waitListConfig');

  const mutateUpdateMultipleClasses = useMutation(() => bulkClassEditing(returnUpdatedData()), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Successfully updated',
        show: true
      });
      delayFetchFunctions([['upcomingClasses', classType]], 1000);
      setTimeout(() => closeModal(), 1500);
    },
    onError: () => {
      setToast({
        status: 'critical',
        msg: 'Something went wrong. Try again',
        show: true
      });
    }
  });

  const valueChangedHandler = (newValue, identifier) => {
    const updated = updateObject(formFields, {
      [identifier]: updateObject(formFields[identifier], {
        value: newValue,
        valid: checkValidity(newValue, formFields[identifier].validation),
        errorText: returnErrorTextForField(formFields[identifier].validation),
        touched: true
      })
    });

    setFormFields(updated);
    setFormValidated(false);
  };

  const checkFormValidity = () => {
    setFormValidated(true);

    for (let input in formFields) {
      if (!formFields[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return setFormIsValid(false);
      }
    }
    setFormIsValid(true);
    mutateUpdateMultipleClasses.mutate();
  };

  const returnUpdatedData = () => {
    const keys = Object.keys(formFields);
    keys.unshift('_id');
    let result = data.map((elem) => pick(elem, keys));
    result = result.map((elem) => {
      for (const key in formFields) {
        if (key === 'private' || key === 'active') {
          elem[key] = formFields[key].value === 'yes';
        } else if (key === 'classDate') {
          elem[key] = moment(formFields.classDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        } else {
          elem[key] = formFields[key].value;
        }
      }
      if (showWaitList) {
        elem.waitListConfig = waitListConfig;
      }

      elem.sendClassChangeNotification = sendClassChangeNotification;
      return elem;
    });
    return result;
  };

  const formWarning = ((!formIsValid && formValidated) || !waitListFormValid) && (
    <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
  );

  const checkWaitList = (valid, waitListConfig) => {
    setWaitListFormValid(valid);
    setWaitListConfig(waitListConfig);
  };

  const renderWaitList = showWaitList && <WaitListForm waitListConfig={waitListConfig} checkWaitList={checkWaitList} />;

  return (
    <div>
      <CustomToast toast={toast} />
      {formWarning}
      <FormCreator formData={formFields} valueChanged={valueChangedHandler} />
      {renderWaitList}
      <CheckBox
        label="Send Class change notification"
        checked={sendClassChangeNotification}
        onChange={() => setSendClassChangeNotification((sendClassChangeNotification) => !sendClassChangeNotification)}
      />
      <div style={{ marginTop: '20px' }}>
        <Button
          primary
          label="Submit changes"
          onClick={checkFormValidity}
          style={{ width: '100%', maxWidth: '100%' }}
        />
      </div>
    </div>
  );
};

export default BulkEditingForm;
