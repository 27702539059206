import React, { useState } from 'react';
import { List, ListItem, Button, Notification } from 'grommet';
import { useQuery, useMutation } from 'react-query';
import { fetchScheduledClasses, delayFetchFunctions, deleteScheduledClass } from '../../apiFunctions/apiFunctions';
import withUser from '../../store/hoc/withUser';
import SingleSchedule from './SingleSchedule/SingleSchedule';
import AddEditSchedule from './AddEditSchedule/AddEditSchedule';
import { returnClassConfig } from '../../shared/gymFunctions';
import { returnErrorFromResponse } from '../../shared/utility';
import Loading from '../../components/Loading/Loading';
import CustomToast from '../../components/CustomToast/CustomToast';

const ManageSchedules = ({ classType, selectedGym }) => {
  const [showAddEditSchedule, setShowAddEditSchedule] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [toast, setToast] = useState(null);

  const gymId = selectedGym._id;
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);

  const { data, isLoading, error } = useQuery(['scheduled-class', classType], () =>
    fetchScheduledClasses(classType, gymId)
  );

  const mutateDeleteSchedule = useMutation((scheduleId) => deleteScheduledClass(scheduleId), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Scheduled class deleted',
        show: true
      });
      delayFetchFunctions([['scheduled-class', classType]], 0);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message={error} status="critical" />;
  }

  const addNewScheduleButton = (
    <Button
      style={{ margin: '0px 0px 0px auto' }}
      label={'Add'}
      secondary={true}
      selectedSchedule={null}
      onClick={() => updateScheduledClass(null)}
    />
  );

  const removeScheduledClass = (scheduledClass) => {
    mutateDeleteSchedule.mutate(scheduledClass._id);
  };
  const updateScheduledClass = (scheduledClass) => {
    setSelectedSchedule(scheduledClass);
    setShowAddEditSchedule(true);
  };

  const returnSchedules =
    data && data.length > 0 ? (
      <List>
        {data.map((scheduledClass) => (
          <ListItem key={scheduledClass._id} direction="column" align="start" separator="horizontal">
            <SingleSchedule
              scheduledClass={scheduledClass}
              onRemoveScheduledClass={removeScheduledClass}
              onUpdateScheduledClass={updateScheduledClass}
              classConfig={classConfig}
            />
          </ListItem>
        ))}
      </List>
    ) : (
      <h3>No schedules</h3>
    );

  if (showAddEditSchedule) {
    return (
      <AddEditSchedule
        classType={classType}
        selectedSchedule={selectedSchedule}
        onClose={() => setShowAddEditSchedule(false)}
      />
    );
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>{addNewScheduleButton}</div>
      <CustomToast toast={toast} />
      {returnSchedules}
    </>
  );
};

export default withUser(ManageSchedules);
