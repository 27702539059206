import React from 'react';
import withUser from '../../../store/hoc/withUser';
import PayButton from '../PayButton/PayButton';
import SignUpAndPayButton from '../SignUpAndPayButton/SignUpAndPayButton';
import CancelAttendanceOrClass from '../CancelAttandanceOrClass/CancelAttendanceOrClass';
import { hasClassStarted } from '../../../shared/classFunctions';
import Status from 'grommet/components/icons/Status';
import WaitListModal from '../../WaitList/WaitListModal';
import RedirectToClassPage from '../RedirectToClassPage/RedirectToClassPage';
import classes from './ClassActionButtons.module.scss';
import Button from 'grommet/components/Button';
import { withRouter } from 'react-router-dom';
import { returnUserClassesLeftAmount } from '../../../shared/userFunctions';

const ClassActionButtons = ({ userData, selectedClass, userAttendance = null, sourcesList, history }) => {
  const userEventAttendance =
    userAttendance !== null
      ? userAttendance
      : {
          _id: selectedClass.userAttendanceId,
          status: selectedClass.userAttendanceStatus,
          stripeOrderId: selectedClass.userAttendanceStripeOrderId
        };
  const isUser = userData.role === 'user';
  const isAdmin = userData.role === 'admin';
  const hasClassAlreadyStarted = hasClassStarted(selectedClass, 15);
  const userHasClassesLeft = returnUserClassesLeftAmount(selectedClass.__t, userData, selectedClass) > 0;
  const needsAddPaymentMethod =
    sourcesList && sourcesList.length === 0 && selectedClass.cost > 0 && !userHasClassesLeft;
  const isClassFull = selectedClass.limit - selectedClass.joinedUsers === 0;
  const isClassCancelled = !selectedClass.active;

  const isClassCancelledContent = <p className={classes.warningClassInfo}>Cancelled</p>;

  const hasClassAlreadyStartedContent = (
    <div>
      <Status value="disabled" style={{ width: '14px', marginRight: '5px' }} />
      Already taken place.
    </div>
  );

  const cancelButton = (
    <CancelAttendanceOrClass
      type={isUser ? 'cancelAttendanceButton' : 'cancelClassXicon'}
      selectedClass={selectedClass}
      user={userData}
      attendance={userEventAttendance}
    />
  );

  if (isUser) {
    if (isClassCancelled) {
      return isClassCancelledContent;
    }

    if (hasClassAlreadyStarted) {
      return hasClassAlreadyStartedContent;
    }

    if (userEventAttendance && userEventAttendance.status === 'active') {
      return <div>{cancelButton}</div>;
    }

    if (userEventAttendance && (userEventAttendance.status === 'attended' || userEventAttendance.status === 'absent')) {
      return null;
    }

    if (isClassFull) {
      if (selectedClass.waitListConfig && selectedClass.waitListConfig.active) {
        return <WaitListModal selectedClass={selectedClass} />;
      }
      return <p className={classes.warningClassInfo}>Full</p>;
    }

    if (needsAddPaymentMethod) {
      return (
        <Button
          data-cy="addPaymentMethod"
          className={classes.nowrap}
          label="Add card details"
          secondary
          onClick={() => history.push(`/gym/payment-methods`)}
        />
      );
    }

    if (userEventAttendance && userEventAttendance.status === 'pending') {
      return (
        <div>
          <PayButton attendanceId={userEventAttendance._id} stripeOrderId={userEventAttendance.stripeOrderId} />
          {cancelButton}
        </div>
      );
    }

    return <SignUpAndPayButton selectedClass={selectedClass} />;
  }

  return (
    <div className={classes.adminActionButtonContainer}>
      {isClassCancelled && isClassCancelledContent}
      {!isClassCancelled && hasClassAlreadyStarted && hasClassAlreadyStartedContent}
      <WaitListModal selectedClass={selectedClass} />
      <RedirectToClassPage gymId={selectedClass.gymId[0]._id} classId={selectedClass._id} />
      {isAdmin && (
        <CancelAttendanceOrClass
          type="cancelClassXicon"
          selectedClass={selectedClass}
          user={userData}
          attendance={selectedClass.attendanceList}
        />
      )}
    </div>
  );
};

export default withUser(withRouter(ClassActionButtons));
