import React, { useRef, useState, useEffect } from 'react';
import Button from 'grommet/components/Button';
import Box from 'grommet/components/Box';
import Form from 'grommet/components/Form';
import FormField from 'grommet/components/FormField';
import TextInput from 'grommet/components/TextInput';
import classes from './Messenger.module.scss';

const Messenger = (props) => {
  const messageColor = useRef('#000000');
  const [message, setMessage] = useState('');
  const [signalServerUnavailable, setSignalServerUnavailable] = useState();

  useEffect(() => {
    setSignalServerUnavailable(props.signalServerUnavailable);
  }, [props.signalServerUnavailable]);

  const handleSend = () => {
    if (message !== '') {
      const messageRequest = {
        signalType: 'message',
        sessionType: props.sessionType,
        name: props.userName,
        message: message,
        color: messageColor.current
      };
      props.webSocket.send(JSON.stringify(messageRequest));
      addMessageToWindow(`<div style="color:${messageColor.current}" class='myComments'><b>You: </b>${message}</div>`);
      setMessage('');
    }
  };

  const addMessageToWindow = (message) => {
    const messageWindow = document.getElementById('messages');
    messageWindow.innerHTML += `<div>${message}</div>`;
    messageWindow.scrollTop = messageWindow.scrollHeight;
  };

  const handleMessageTextColorChange = (event) => {
    messageColor.current = event.target.value;
    const myComments = document.getElementsByClassName('myComments');
    if (myComments) {
      for (var index = 0; index < myComments.length; index++) {
        myComments[index].style.color = messageColor.current;
      }
    }
  };

  const handleMessageChange = (message) => {
    setMessage(message.target.value);
  };

  return (
    <>
      <div className={classes.messagePanel} id="message-panel">
        <Box
          id="messages"
          align="start"
          alignContent="stretch"
          margin="medium"
          textAlign="left"
          className={classes.messages}
        />
        <div id="message-color-picker" className={classes.messageColorPicker}>
          <label className={classes.messageColorLabel} htmlFor="message-color">
            Choose your messaging colour:
          </label>
          <input
            className={classes.messageColor}
            type="color"
            id="message-color"
            name="message-color"
            onChange={handleMessageTextColorChange}
          />
        </div>
        <Form
          className={classes.messageForm}
          onSubmit={(event) => {
            event.preventDefault();
          }}>
          <FormField>
            <TextInput
              id="message"
              value={message}
              placeHolder="Enter your message then hit Send..."
              className={classes.message}
              onDOMChange={(event) => handleMessageChange(event)}
            />
          </FormField>
          <Button
            type={signalServerUnavailable ? null : 'submit'}
            className={classes.sendButton}
            label="Send"
            secondary={true}
            onClick={signalServerUnavailable ? null : handleSend}
          />
        </Form>
      </div>
    </>
  );
};

export default Messenger;
