import axios from '../../../../axios-global';
import React, { useState, useEffect } from 'react';
import { TextInput, Tabs, Tab, List, ListItem, Toast } from 'grommet';
import ViewIcon from 'grommet/components/icons/base/View';
import { returnErrorFromResponse } from '../../../../shared/utility';
import ProfilePaymentHistory from '../../../../components/UI/ProfilePaymentHistory/ProfilePaymentHistory';
import Modal from 'react-modal';
import moment from 'moment';
import Spinning from 'grommet/components/icons/Spinning';
import './ClassPassReport.scss';
import Select from 'grommet/components/Select';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
export const ClassPassesReports = (props) => {
  const [reportResult, setReportResult] = useState([]);
  const [overallTotal, setOverallTotal] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [bookingHistory, setBookingHistory] = useState([]);
  const [toastMsg, setToastMsg] = useState(false);
  const [filteredReport, setFilteredReport] = useState([]);
  const [selectedGym, setSelectedGym] = useState({
    label: props.selectedGym.name,
    id: props.selectedGym._id
  });
  const [loading, setLoading] = useState(false);
  const [filteredGym, setFilteredGym] = useState(null);

  useEffect(() => {
    const getReport = async () => {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_DEV_ENV_API}`
          : `${process.env.REACT_APP_PROD_ENV_API}`;

      await axios
        .get(`${baseUrl}/api/reports/remaining-classpass-credits/${selectedGym.id}`)
        .then((response) => {
          setLoading(false);
          setFilteredReport(response.data);
          formatReportArray(response.data.userTotals);
          setOverallTotal(response.data.overallTotal);
        })
        .catch(() => setLoading(false));
    };
    getReport();
  }, [selectedGym]);

  const formatReportArray = (report) => {
    let results = [];
    Object.keys(report).forEach((key) => {
      results.push(report[key]);
    });

    setReportResult(results);
    setFilteredResult(results);
  };

  const onSelectedFilterChanged = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filteredArray = reportResult.filter((result) => {
      return (
        result.user.name.toLowerCase().includes(searchText) || result.user.last_name.toLowerCase().includes(searchText)
      );
    });
    setFilteredResult(filteredArray);
  };

  const viewDetail = (classObj) => {
    axios
      .get(`/users/${classObj.user._id}`)
      .then((response) => {
        setPaymentHistory(response.data.paymentHistory.filter((payment) => payment.type === 'classPass_payment'));
        setBookingHistory(response.data.fitnessClassHistory.filter((booking) => booking.class.__t === 'class'));
        setShowModal(true);
      })
      .catch((error) => {
        setToastMsg(returnErrorFromResponse(error));
      });
  };

  const renderModal = () => {
    const renderPaymentHistory = () => {
      return paymentHistory.map((payment, index) => {
        return (
          <ListItem justify="between" separator="horizontal" key={index}>
            <span>{payment.type}</span>
            <span className="secondary">
              <ProfilePaymentHistory lastPaymentInfo={payment} />
            </span>
          </ListItem>
        );
      });
    };

    const renderBookingHistory = () => {
      return bookingHistory.map((booking, index) => {
        return (
          <ListItem justify="between" separator="horizontal" key={index}>
            <span className="secondary">
              {booking.class.name} - {moment(booking.classDate).format('Do MMM YYYY')}
            </span>
            <span>{booking.status}</span>
          </ListItem>
        );
      });
    };
    return (
      <Modal onRequestClose={onHideModal} isOpen={showModal} className="ss-modal">
        <Tabs justify="start">
          <Tab title="Payment History">
            <List>{renderPaymentHistory()}</List>
          </Tab>
          <Tab title="Booking History">
            <List>{renderBookingHistory()}</List>
          </Tab>
        </Tabs>
      </Modal>
    );
  };

  const onHideModal = () => {
    return setShowModal(false);
  };

  const renderToast = () => {
    if (toastMsg) {
      return (
        <Toast status="critical" onClose={() => setToastMsg(false)}>
          {toastMsg}
        </Toast>
      );
    }
  };
  const renderGymSelection = () => {
    let options = props.locations.map((elem) => {
      return {
        label: elem.name,
        id: elem._id
      };
    });
    const onFilterGymList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredGymList = props.locations.filter((gym) => gym.name.toLowerCase().includes(filter));

      setFilteredGym(
        filteredGymList.map((elem) => {
          return {
            label: elem.name,
            id: elem._id
          };
        })
      );
    };
    return (
      <div>
        <h4>Select gym</h4>
        <Select
          placeHolder="Select gym name"
          options={filteredGym || options}
          value={selectedGym}
          onChange={(event) => setSelectedGym(event.option)}
          onSearch={onFilterGymList}
        />
      </div>
    );
  };

  return (
    <>
      {renderModal()}
      {renderToast()}
      <ExportToCSV data={filteredResult} type={'classPassesReport'} gymName={selectedGym.label} />
      <div className="ClassPassReport">
        <div className="ClassPassReport__filter">
          <div>
            <h4>Filter by name</h4>
            <TextInput placeHolder="client name" onDOMChange={onSelectedFilterChanged} />
          </div>
          {renderGymSelection()}
        </div>

        <div className="ClassPassReport__total">
          <span className="ClassPassReport__total__label">Total Class Passes Remaining: </span>
          <span className="ClassPassReport__total__value">{overallTotal}</span>
        </div>

        {loading ? (
          <Spinning />
        ) : (
          <List className="ClassPassReport__list">
            {filteredReport !== null && filteredReport.length === 0 && <div>No client found</div>}
            {filteredReport !== null && filteredReport.length > 0 && (
              <ListItem className="ClassPassReport__list__header">
                <span>Client</span>
                <span>Class Passes Remaining</span>
              </ListItem>
            )}
            {filteredResult.map((result, index) => (
              <ListItem className="ClassPassReport__list__item" key={index}>
                <span>
                  {result.user.name} {result.user.last_name}
                </span>
                <span className="ClassPassReport__list__item__count">
                  {result.total} <ViewIcon onClick={() => viewDetail(result)} />
                </span>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </>
  );
};

export default withAdminDashboard(ClassPassesReports);
