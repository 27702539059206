import React from 'react';
import MenuIcon from 'grommet/components/icons/base/Menu';
import Menu from 'grommet/components/Menu';
import Anchor from 'grommet/components/Anchor';
import classes from './NavigationMenu.module.scss';

const MenuNavigation = ({ menuItems }) =>
  menuItems.length > 0 && (
    <div className={classes.root}>
      <Menu dropAlign={{ left: 'left', top: 'bottom' }} icon={<MenuIcon size={'large'} className={classes.icon} />}>
        {menuItems.map((elem, index) => (
          <Anchor key={index} path={elem.link}>
            {elem.name}
          </Anchor>
        ))}
      </Menu>
    </div>
  );

export default MenuNavigation;
