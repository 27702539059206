import React from 'react';
import moment from 'moment';

import Heading from 'grommet/components/Heading';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';

import classes from './DefaultClientUpcomingPTs.module.scss';

const DefaultClientUpcomingPTs = (props) => {
  let upcomingPts = <Spinning />;

  if (!props.upcomingPtsLoading && props.upcomingPtsError) {
    upcomingPts = <Notification message={props.upcomingPtsError} status="critical" />;
  }

  if (!props.upcomingPtsLoading && props.upcomingPts && props.upcomingPts.length === 0) {
    upcomingPts = `You don't have any personal training sessions scheduled`;
  }

  if (props.upcomingPts && !props.upcomingPtsLoading && !props.upcomingPtsError && props.upcomingPts.length > 0) {
    upcomingPts = props.upcomingPts.map((element) => (
      <ListItem key={element._id} direction="column" align="start" separator="horizontal">
        <div className={classes.singleSession}>
          <div>
            <Heading tag="h4" className={classes.tableListHeader}>
              {moment(element.class.classDate).format('Do MMM')}, {element.class.classTime.toUpperCase()}
            </Heading>
            {element.class.notesForClient && (
              <span>
                <b className={classes.noteTitle}>From your trainer: </b> {element.class.notesForClient}
              </span>
            )}
          </div>
        </div>
      </ListItem>
    ));
  }

  return (
    <div className={classes.singleDay}>
      <h3 className={classes.listHeader}>Personal Training Bookings</h3>

      <List>{upcomingPts}</List>
    </div>
  );
};

export default DefaultClientUpcomingPTs;
