import React from 'react';
import QrReader from 'react-qr-reader';

export default function ClientProfileAddQr(props) {
  return (
    <QrReader
      delay={2000}
      onError={props.handleQrCodeError}
      onScan={props.handleQrCodeScan}
      showViewFinder={false}
      style={{ width: '150px' }}
      shouldReturnFocusAfterClose={false}
    />
  );
}
