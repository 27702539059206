import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { connect } from 'react-redux';
import axios from '../../../../axios-global';

import SingleSource from '../../../../components/UI/SingleSource/SingleSource';

import Button from 'grommet/components/Button';
import Toast from 'grommet/components/Toast';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';

import * as actions from '../../../../store/actions';

import classes from './PaymentMethods.module.scss';

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,

    sourcesList: state.client.sourcesList,
    sourcesListLoading: state.client.sourcesListLoading,
    sourcesListError: state.client.sourcesListError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSources: () => dispatch(actions.fetchSources())
  };
};

class PaymentMethods extends Component {
  state = {
    currentPage: null,
    showToast: false,
    toastMessage: null,
    status: null,
    loading: false
  };

  componentDidMount() {
    if (this.props.userData.customerType !== 'class-pass-member') {
      this.props.onFetchSources();
    }
    // const { url } = this.props.match
  }

  renderSources = () => {
    if (this.props.sourcesListLoading) {
      return <Spinning />;
    }

    if (this.props.sourcesListError) {
      return <Notification message={this.props.sourcesListError} status="warning" />;
    }

    if (this.props.sourcesList && this.props.sourcesList.length > 0) {
      return this.props.sourcesList.map((elem) => (
        <SingleSource
          source={elem}
          key={elem.id}
          default={elem.default}
          disabledCheckbox={this.state.loading || this.props.sourcesList.length === 1}
          closeInvisible={this.state.loading || this.props.sourcesList.length === 1}
          selectNewDefaultSource={this.onSelectNewDefaultSource}
          removeSource={this.onRemoveSource}
        />
      ));
    }
  };

  onRemoveSource = (sourceId) => {
    this.setState({
      loading: true
    });

    axios
      .delete(`/users/me/delete-payment/${sourceId}`)
      .then((resp) => {
        this.setState({
          showToast: true,
          toastMessage: resp.data.message,
          status: 'ok',
          loading: false
        });

        this.props.onFetchSources();
      })
      .catch((err) => {
        this.setState({
          showToast: true,
          toastMessage:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Something went wrong, try again',
          status: 'critical',
          loading: false
        });
      });
  };

  onSelectNewDefaultSource = (sourceId) => {
    this.setState({
      loading: true
    });

    axios
      .post('/users/me/payment-default', {
        source: sourceId,
        method: 'web'
      })
      .then((resp) => {
        this.setState({
          showToast: true,
          toastMessage: resp.data.message,
          status: 'ok',
          loading: false
        });

        this.props.onFetchSources();
      })
      .catch((err) => {
        this.setState({
          showToast: true,
          toastMessage:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Something went wrong, try again',
          status: 'critical',
          loading: false
        });
      });
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();

    this.setState({
      loading: true
    });

    this.props.stripe
      .createSource({
        type: 'card',
        currency: 'gbp'
      })
      .then((result) => {
        if (result.error) {
          return this.setState({
            showToast: true,
            toastMessage: result.error.message ? result.error.message : 'Something went wrong, try again',
            status: 'critical',
            loading: false
          });
        }

        axios
          .post('/users/me/payment-methods', {
            source: result.source.id,
            method: 'web'
          })
          .then((resp) => {
            let toastMessage = resp.data.message;
            if (this.props.userData.customerType === 'class-pass-member') {
              toastMessage = `Thanks for adding your card details.`;
            }

            this.setState({
              showToast: true,
              toastMessage,
              status: 'ok',
              loading: false
            });

            this._cardElement.clear();
            this.props.onFetchSources();

            setTimeout(() => {
              this.props.history.goBack();
            }, 3000);
          })
          .catch((err) => {
            this.setState({
              showToast: true,
              toastMessage:
                err.response && err.response.message ? err.response.message : 'Something went wrong, try again',
              status: 'critical',
              loading: false
            });
          });
      });
  };

  renderForm = () => {
    let button = <Spinning />;

    if (!this.state.loading) {
      button = (
        <div className={classes.thankYouButtons}>
          <Button label="Add" secondary={true} onClick={this.handleSubmit} data-cy="addBtn" />
        </div>
      );
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          <span className={classes.label}>Add new</span>
          <CardElement
            disabled={this.state.loading}
            className={classes.StripeElement}
            onReady={(c) => (this._cardElement = c)}
          />
        </label>
        {button}
      </form>
    );
  };

  render() {
    let toast = null;
    if (this.state.showToast) {
      toast = (
        <Toast
          status={this.state.status}
          onClose={() => {
            this.setState({
              showToast: false,
              toastMessage: null,
              status: null
            });
          }}>
          {this.state.toastMessage}
        </Toast>
      );
    }

    return (
      <div id="payments" className={classes.container}>
        {toast}
        {this.renderSources()}
        {this.renderForm()}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectStripe(PaymentMethods)));
