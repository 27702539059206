import React from 'react';
import { withRouter } from 'react-router-dom';

import classes from './SingleTile.module.scss';

export default withRouter((props) => {
  const clickHandler = () => {
    if (props.link) {
      props.history.push(props.link);
    } else if (props.actionPayload) {
      props.action(props.actionPayload);
    }
  };

  const renderBadge = () => {
    if (props.badgeCount) {
      return <div className={classes.tileContainerBadge}>{props.badgeCount}</div>;
    }

    return null;
  };

  return (
    <div className={classes.tileContainer} onClick={clickHandler} data-cy={props.cyId}>
      <div className={classes.tile}>
        {props.text && <span className={classes.text}>{props.text}</span>}
        {props.icon && <img className={classes.tileIcon} src={props.icon.type} alt="dashboard icon" />}
      </div>

      {renderBadge()}

      <div className={classes.title}>{props.title}</div>
    </div>
  );
});
