import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Heading from 'grommet/components/Heading';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';
import TextInput from 'grommet/components/TextInput';

import classes from './GymList.module.scss';
import Button from 'grommet/components/Button';

const mapStateToProps = (state) => {
  return {
    loading: state.location.loading,
    locations: state.location.locations,
    error: state.location.error
  };
};

const GymList = (props) => {
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    setFilteredList(props.gymsList);
  }, [props.gymsList]);
  let locations = <Spinning />;
  if (!props.loading && props.error) {
    locations = <Notification message={props.error} status="critical" />;
  }

  if (!props.loading && !props.error && filteredList) {
    locations = filteredList.map((element) => (
      <ListItem
        key={element._id}
        direction="column"
        align="start"
        separator="horizontal"
        onClick={() => props.gymSelected(element._id)}>
        <Heading tag="h3" className={classes.tableListHeader}>
          {element.name}
        </Heading>
        <span>
          {element.city}, {element.address}
        </span>
      </ListItem>
    ));
  }
  if (!props.loading && props.locations && props.locations.length === 0) {
    locations = 'No gyms';
  }
  if (!props.loading && props.locations && props.locations.length === 1) {
    props.gymSelected(props.locations[0]._id);
  }
  const onSearchChanged = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredArray = props.gymsList.filter((location) => {
      return location.name.toLowerCase().includes(searchText);
    });
    setFilteredList(filteredArray);
  };
  const searchGym = () => {
    return (
      <div className={classes.filter}>
        <h4>Filter by name</h4>
        <TextInput placeHolder="Gym name" onDOMChange={onSearchChanged} />
      </div>
    );
  };

  const addNewGymButton = props.adminFullAccess && (
    <Button
      style={{ marginTop: '50px', display: 'inline-block' }}
      label="Add"
      secondary={true}
      onClick={() => props.history.push('/add-gym')}
    />
  );

  return (
    <>
      <div className={classes.header}>
        <h2>{props.header}</h2>
        {addNewGymButton}
      </div>
      {searchGym()}
      <List data-cy="gymlist">{locations}</List>
    </>
  );
};

export default connect(mapStateToProps)(GymList);
