import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../store/actions/index';

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.authLogout())
  };
};

class Login extends Component {
  componentDidMount() {
    this.props.onLogout();
    window.location.href = '/';
  }

  render() {
    return <Redirect to="/" />;
  }
}

export default connect(null, mapDispatchToProps)(Login);
