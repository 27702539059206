import React, { useState, useEffect } from 'react';
import { Button, Notification } from 'grommet';
import Spinning from 'grommet/components/icons/Spinning';
import { returnScheduleFields } from './returnScheduleFields';
import { useMutation, useQuery } from 'react-query';
import {
  addScheduledClass,
  delayFetchFunctions,
  editScheduledClass,
  fetchClassesPerScheduleId
} from '../../../apiFunctions/apiFunctions';
import { updateObject, checkValidity, returnErrorTextForField, returnErrorFromResponse } from '../../../shared/utility';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import FormCreator from '../../../components/UI/FormCreator/FormCreator';
import TrainerSelector from '../../../components/Selectors/TrainerSelector/TrainerSelector';
import TemplateSelector from '../../../components/Selectors/TemplateSelector/TemplateSelector';
import classes from './AddEditSchedule.module.scss';
import { returnClassConfig } from '../../../shared/gymFunctions';
import { WaitListForm } from '../../WaitList/WaitListForm/WaitListForm';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Modal from 'react-modal';
import BulkModal from '../../BulkEditing/BulkModal/BulkModal';
const cloneDeep = require('lodash.clonedeep');

const AddEditSchedule = ({ classType, selectedSchedule = null, selectedGym, onClose }) => {
  const [status, setStatus] = useState('ok');
  const [formIsValid, setFormIsValid] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState([]);
  const [formFields, setFormFields] = useState(returnScheduleFields(classType, selectedSchedule));
  const [updatedData, setUpdatedData] = useState(null);
  const [selectedTrainer, setSelectedTrainer] = useState(selectedSchedule ? selectedSchedule.trainerId : null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [waitListConfig, setWaitListConfig] = useState(selectedSchedule ? selectedSchedule.waitListConfig : false);
  const [waitListFormValid, setWaitListFormValid] = useState(true);
  const [toast, setToast] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showBulkManagementModal, setShowBulkManagementModal] = useState(false);

  const isNewSchedule = selectedSchedule === null || selectedSchedule === undefined;
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);
  const showTrainerSelector = classConfig.trainerRequired;

  const { data: bulkData, error: bulkDataError, isLoading: bulkDataIsLoading } = useQuery(
    ['bulkData'],
    () => fetchClassesPerScheduleId(selectedSchedule._id),
    {
      enabled: showBulkManagementModal
    }
  );

  const mutateSchedule = useMutation(
    (formData) =>
      isNewSchedule ? addScheduledClass(selectedGym._id, formData) : editScheduledClass(selectedSchedule._id, formData),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: isNewSchedule ? 'Successfully created' : 'Successfully updated',
          show: true
        });
        delayFetchFunctions(
          [
            ['scheduled-class', classType],
            ['upcomingClasses', classType]
          ],
          0
        );

        if (!isNewSchedule) {
          setShowModal(!isNewSchedule);
        } else {
          setTimeout(() => onClose(), 1500);
        }
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );
  useEffect(() => {
    setFormFields(returnScheduleFields(classType, selectedSchedule !== null ? selectedSchedule : selectedTemplate));
  }, [selectedTemplate, selectedSchedule, classType]);

  const valueChangedHandler = (newValue, identifier) => {
    const updated = updateObject(formFields, {
      [identifier]: updateObject(formFields[identifier], {
        value: newValue,
        valid: checkValidity(newValue, formFields[identifier].validation),
        errorText: returnErrorTextForField(formFields[identifier].validation),
        touched: true
      })
    });

    setFormFields(updated);
    setFormValidated(false);
  };

  const isAtLeastOneDayEntered = () => {
    if (
      formFields.monday.value === '' &&
      formFields.tuesday.value === '' &&
      formFields.wednesday.value === '' &&
      formFields.thursday.value === '' &&
      formFields.friday.value === '' &&
      formFields.saturday.value === '' &&
      formFields.sunday.value === ''
    ) {
      return false;
    }
    return true;
  };

  const checkFormValidity = () => {
    setFormValidated(true);
    let errors = [];

    if (selectedTemplate === null && isNewSchedule) {
      errors.push('template');
    }
    if (selectedTrainer === null && showTrainerSelector) {
      errors.push('trainer');
    }
    if (errors.length > 0 || !waitListFormValid) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      setFormValidationErrors(errors);
      setFormIsValid(false);
      return false;
    } else {
      setFormValidationErrors([]);
    }

    if (!isAtLeastOneDayEntered()) {
      const copiedForm = cloneDeep(formFields);
      copiedForm.monday = {
        ...copiedForm.monday,
        valid: false,
        touched: true,
        errorText: 'Please, specify at least one day'
      };
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      setFormFields(copiedForm);
      setFormIsValid(false);
      return false;
    }
    for (let input in formFields) {
      if (!formFields[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return setFormIsValid(false);
      }
    }
    setFormIsValid(true);
    saveSchedule();
  };

  const saveSchedule = () => {
    const formData = {
      stripeProductId: selectedSchedule ? selectedSchedule.stripeProductId : selectedTemplate.stripeProductId,
      classTemplateId: selectedSchedule ? selectedSchedule.classTemplateId : selectedTemplate._id,
      recurrentOn: {
        1: formFields.monday.value,
        2: formFields.tuesday.value,
        3: formFields.wednesday.value,
        4: formFields.thursday.value,
        5: formFields.friday.value,
        6: formFields.saturday.value,
        0: formFields.sunday.value
      },
      currency: formFields.currency.value.value,
      gymId: selectedSchedule ? selectedSchedule.gymId : selectedGym._id,
      private: formFields.private.value === 'yes',
      enabledForScheduling: formFields.enabledForScheduling ? formFields.enabledForScheduling.value === 'yes' : true,
      waitListConfig
    };
    const fieldsToSkip = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
      'classTemplateId',
      'stripeProductId',
      'currency',
      'gymId',
      'private',
      'enabledForScheduling'
    ];
    for (let input in formFields) {
      if (!fieldsToSkip.includes(input)) {
        let value = formFields[input].value;
        formData[input] = value;
      }
    }
    if (
      classType === 'gymClass' ||
      classType === 'swimmingClass' ||
      classType === 'tennisClass' ||
      classType === 'massageClass'
    ) {
      formData['name'] = classType;
      formData['description'] = classType;
    }

    if (showTrainerSelector) {
      formData.trainerId = selectedTrainer._id;
    }
    setUpdatedData(formData);
    mutateSchedule.mutate(formData);
  };
  const checkWaitList = (valid, waitListConfig) => {
    setWaitListConfig(waitListConfig);
    setWaitListFormValid(valid);
  };

  const formWarning = !formIsValid && formValidated && (
    <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
  );

  const button = mutateSchedule.isLoading ? (
    <Spinning className={classes.spinner} />
  ) : (
    <Button
      className={classes.saveBtn}
      label={selectedSchedule ? 'Save' : 'Add'}
      secondary={true}
      onClick={checkFormValidity}
    />
  );

  const renderTemplatesSelection = isNewSchedule && classConfig.template && (
    <TemplateSelector
      classType={classType}
      gymId={selectedGym._id}
      selectedTemplate={selectedTemplate}
      onSelectTemplate={setSelectedTemplate}
      setStatus={setStatus}
      isValid={formValidationErrors.indexOf('template') === -1}
      fetchData={classConfig.template}
    />
  );

  const renderTrainerSelection = showTrainerSelector && (
    <TrainerSelector
      selectedTrainer={selectedTrainer}
      isValid={formValidationErrors.indexOf('trainer') === -1}
      onSelectTrainer={setSelectedTrainer}
      setStatus={setStatus}
    />
  );

  const renderForm = () =>
    status !== 'ok' ? null : (
      <>
        {renderTrainerSelection}
        <FormCreator formData={formFields} valueChanged={valueChangedHandler} />
        <WaitListForm waitListConfig={waitListConfig} checkWaitList={checkWaitList} />
        {button}
      </>
    );

  const bulkDataManagementModal = () => {
    const handleBulkManagement = () => {
      setShowBulkManagementModal(true);
    };
    const handleCloseModal = () => {
      setShowBulkManagementModal(false);
      setShowModal(false);
    };
    return (
      <>
        <Modal isOpen={showModal} className="ss-modal" onRequestClose={() => setShowModal(false)}>
          <div className="grommet">
            Do you want to update existing classes ?
            <div className={classes.modalFooter}>
              <Button label="Yes" secondary={true} onClick={handleBulkManagement} />
              <Button label="Cancel" primary onClick={handleCloseModal} />
            </div>
          </div>
        </Modal>
        <BulkModal
          isLoading={bulkDataIsLoading}
          error={bulkDataError}
          data={bulkData}
          isOpen={showBulkManagementModal}
          closeModal={handleCloseModal}
          classType={classType}
          scheduleId={selectedSchedule && selectedSchedule._id}
          updatedData={updatedData}
        />
      </>
    );
  };

  return (
    <div>
      {bulkDataManagementModal()}
      <div className={classes.scheduledList}>
        <Button label={'Schedule list'} secondary={true} onClick={onClose} />
      </div>
      <div>
        <CustomToast toast={toast} />
        {formWarning}
        {renderTemplatesSelection}
        {renderForm()}
      </div>
    </div>
  );
};

export default withAdminDashboard(AddEditSchedule);
