import React, { useState } from 'react';
import axios from '../../../axios-global';
import Modal from 'react-modal';

import Button from 'grommet/components/Button';
import Select from 'grommet/components/Select';
import NumberInput from 'grommet/components/NumberInput';

import SignUpSummary from './SignUpSummary';
import ClientProfilePaymentMethodSelection from '../ClientProfilePaymentMethodSelection';
import HeaderWithBorder from '../../UI/HeaderWithBorder/HeaderWithBorder';
import './ClientProfileSignUpClient.scss';

export default function ClientProfileSignUpClient(props) {
  const [modalState, setModalState] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [plan, setPlan] = useState({});
  const [paidPeriodInMonths, setPaidPeriodInMonths] = useState(1);

  let plansList = [];
  if (props.plansList) {
    plansList = props.plansList.map((elem) => {
      return {
        label: elem.name,
        value: elem
      };
    });
  }

  const selectPlan = (event) => {
    setPlan({ label: event.option.label, value: event.option.value });
  };

  const updatePaidPeriodInMonths = (event) => {
    setPaidPeriodInMonths(event.target.value.replace(',', '.').split('.')[0]);
  };

  const signUpClient = async () => {
    if (!isAlreadySubscribed && !plan.value) {
      return props.onFailedSave({
        response: { data: { message: 'Please select a plan' } }
      });
    }

    let url;

    if (props.selectedClient.currentMembershipPlan) {
      url = '/membership-plan/payment';
    } else {
      url = `/membership-plan/subscribe/${plan.value._id}`;
    }

    try {
      const respond = await axios.post(url, {
        method: paymentMethod,
        userId: props.selectedClient._id,
        paidPeriodInMonths: Number(paidPeriodInMonths)
      });
      props.onSuccessfulSave(respond);
      props.fetchSingleClient();
    } catch (error) {
      props.onFailedSave(error);
    }
  };

  const handleModalClose = () => {
    setModalState(false);
  };

  const isAlreadySubscribed = props.selectedClient.currentMembershipPlan
    ? props.selectedClient.currentMembershipPlan
    : false;
  const joiningFeePaid = props.selectedClient.joiningFeePaid;

  return (
    <>
      <Modal
        isOpen={modalState}
        onRequestClose={handleModalClose}
        className="ss-modal client-profile-add-membership__modal">
        {!isAlreadySubscribed && (
          <div style={{ marginBottom: '20px' }} data-cy="choosePlan">
            <HeaderWithBorder header={'Choose plan'} />

            <Select options={plansList} value={plan.label} onChange={selectPlan} />
          </div>
        )}
        <div style={{ marginBottom: '20px' }} data-cy="choosePaymentMethod">
          <HeaderWithBorder header={'Choose payment method'} />

          <ClientProfilePaymentMethodSelection onMethodSelected={setPaymentMethod} />
        </div>

        <div style={{ marginBottom: '20px' }}>
          <HeaderWithBorder header={'Specify the number of months'} />

          <NumberInput value={paidPeriodInMonths} step={1} onChange={updatePaidPeriodInMonths} />
        </div>

        {!isAlreadySubscribed && (
          <>
            <HeaderWithBorder header={'Plan summary'} />
            <SignUpSummary plan={plan.value} paidPeriodInMonths={paidPeriodInMonths} joiningFeePaid={joiningFeePaid} />
          </>
        )}

        <div className="ss-modal__buttons-container">
          <Button box label="OK" secondary={true} onClick={signUpClient} data-cy="okBtn" />

          <Button box label="Cancel" primary onClick={handleModalClose} />
        </div>
      </Modal>

      {props.isAdmin && (
        <Button
          style={{
            display: 'block',
            maxWidth: '100%',
            width: '100%'
          }}
          label={isAlreadySubscribed ? 'Add membership payment' : 'Add gym membership'}
          secondary={true}
          onClick={() => setModalState(true)}
          data-cy="addGymMembershipBtn"
        />
      )}
    </>
  );
}
