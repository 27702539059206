import React from 'react';
import { RadioButton } from 'grommet';
import useSelectFilter from '../hooks/useSelectFilter';
import { MEMBERSHIP_STATUS_FILTERS } from '../../../constants';

const ClientMembershipTypeFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged, isSelectedFilter } = useSelectFilter(MEMBERSHIP_STATUS_FILTERS.ALL, onFilter);

  return (
    <div className="client__type-filter">
      <h4>Membership Status</h4>
      <RadioButton
        id={MEMBERSHIP_STATUS_FILTERS.ACTIVE}
        name="membership_status"
        type="radio"
        label="Active"
        value={MEMBERSHIP_STATUS_FILTERS.ACTIVE}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(MEMBERSHIP_STATUS_FILTERS.ACTIVE)}
      />

      <RadioButton
        id={MEMBERSHIP_STATUS_FILTERS.EXPIRED}
        name="membership_status"
        type="radio"
        label="Expired"
        value={MEMBERSHIP_STATUS_FILTERS.EXPIRED}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(MEMBERSHIP_STATUS_FILTERS.EXPIRED)}
      />
      <RadioButton
        id={MEMBERSHIP_STATUS_FILTERS.ALL}
        name="membership_status"
        type="radio"
        label="All"
        value={MEMBERSHIP_STATUS_FILTERS.ALL}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(MEMBERSHIP_STATUS_FILTERS.ALL)}
      />
    </div>
  );
};

export default ClientMembershipTypeFilter;
