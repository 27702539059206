import React from 'react';
import List from 'grommet/components/List';
import Notification from 'grommet/components/Notification';
import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import { SingleAdmin } from './SingleTrainer/SingleAdmin';

import classes from './ManageAdmins.module.scss';
import withManageAdmins from '../../../store/hoc/withManageAdmins';
import { isFullAccessAdminOrTrainer } from '../../../shared/userFunctions';

class ManageAdmins extends React.Component {
  state = {
    gymsList: {},
    hasFullAccess: false
  };

  componentDidMount() {
    this.setState({
      hasFullAccess: isFullAccessAdminOrTrainer(this.props.userData)
    });

    if (this.props.locations) {
      let gymsList = {};
      this.props.locations.forEach((elem) => {
        gymsList[elem._id] = false;
      });
      this.setState({
        gymsList
      });
    }
  }
  renderAdminList = () => {
    const deleteAdminFunction = (adminId) => {
      this.props.onDeleteAdmin(adminId);
      setTimeout(() => this.props.onGetAdmins(this.props.selectedGym._id), 1000);
    };
    if (this.props.adminsListError) {
      return <Notification message={'Something went wrong'} status="critical" />;
    }

    if (this.props.adminsList && this.props.adminsList.length > 0) {
      return this.props.adminsList.map((admin) => (
        <SingleAdmin
          adminId={this.props.userData._id}
          hasFullAccess={this.state.hasFullAccess}
          singleAdmin={admin}
          key={admin._id}
          gymsList={this.state.gymsList}
          history={this.props.history}
          match={this.props.match}
          deleteAdmin={() => deleteAdminFunction(admin._id)}
        />
      ));
    }

    if (this.props.adminsList && this.props.adminsList.length === 0) {
      return 'No admins';
    }
  };
  addAdminButton = (
    <div className={classes.addAdminButton}>
      <Button
        box
        label="Add new Admin"
        secondary={true}
        onClick={() => this.props.history.push(`/gym/${this.props.selectedGym._id}/admins/add`)}
      />
    </div>
  );

  render() {
    if (!this.props.locations) {
      return <Spinning size="xlarge" />;
    } else {
      return (
        <div>
          {this.addAdminButton}
          <br />
          <h4 className={classes.listHeader}>Admin list</h4>
          <List>{this.renderAdminList()}</List>
        </div>
      );
    }
  }
}

export default withManageAdmins(ManageAdmins);
