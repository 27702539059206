import React, { Component } from 'react';

import { Elements } from 'react-stripe-elements';

import ClientPayDescription from './ClientPayDescription/ClientPayDescription';
import ClientPaymentControls from './ClientPaymentControls/ClientPaymentControls';

import classes from './ClientPaymentPage.module.scss';

export default class ClientPaymentPage extends Component {
  render() {
    return (
      <div className={classes.container}>
        <ClientPayDescription
          membershipPlan={this.props.membershipPlan ? this.props.membershipPlan : false}
          joiningFeePaid={this.props.joiningFeePaid}
        />

        <Elements>
          <ClientPaymentControls membershipPlan={this.props.membershipPlan} />
        </Elements>
      </div>
    );
  }
}
