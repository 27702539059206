import React from 'react';

import Edit from 'grommet/components/icons/base/Edit';
import Close from 'grommet/components/icons/base/Close';
import Money from 'grommet/components/icons/base/Money';
import Group from 'grommet/components/icons/base/Group';

import classes from './Icons.module.scss';

const Icons = (props) => {
  let cointainerClasses = [classes.iconContainer];
  if (props.money && props.edit && props.delete) {
    cointainerClasses.push(classes.iconContainerHeigher);
  }

  if (props.horizontalMobile) {
    cointainerClasses = [classes.horizontalMobile];
  }

  return (
    <div className={cointainerClasses.join(' ')}>
      {props.money ? <Money className={classes.icon} onClick={props.moneyClick} /> : null}

      {props.people ? <Group className={classes.icon} onClick={props.peopleClick} /> : null}

      {props.edit ? <Edit className={classes.icon} onClick={props.editClick} /> : null}

      {props.delete ? <Close className={classes.icon} onClick={props.deleteClick} /> : null}
    </div>
  );
};
export default Icons;
