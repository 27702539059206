import React from 'react';
import { TextInput } from 'grommet';
import useSelectFilter from '../hooks/useSelectFilter';

const GymNameFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged } = useSelectFilter('', onFilter);

  return (
    <div className="client__gym-name-filter">
      <h4 className="client__gym-name-filter__gym">Filter by gym</h4>
      <TextInput name="client-name-filter" placeHolder="gym name" onDOMChange={onSelectedFilterChanged} />
    </div>
  );
};

export default GymNameFilter;
