import axios from 'axios';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_DEV_ENV_API}/api`
      : `${process.env.REACT_APP_PROD_ENV_API}/api`
});

export const setAuthHeader = (token) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export default instance;
