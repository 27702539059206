import React from 'react';

import CheckBox from 'grommet/components/CheckBox';

import Close from 'grommet/components/icons/base/Close';

import classes from './SingleSource.module.scss';

const SingleSource = (props) => {
  const onCheckboxChange = () => props.selectNewDefaultSource(props.source.id);
  const onSourceRemove = () => props.removeSource(props.source.id);

  return (
    <div className={classes.sourceContainer}>
      <div className={classes.source}>
        <CheckBox defaultChecked={props.default} disabled={props.disabledCheckbox} onChange={onCheckboxChange} />
        {props.source.card && props.source.card.brand ? props.source.card.brand : 'card'} ending
        {props.source.card && props.source.card.last4 ? props.source.card.last4 : 'N/A'}
      </div>

      {!props.closeInvisible ? <Close className={classes.icon} onClick={onSourceRemove} /> : null}
    </div>
  );
};
export default SingleSource;
