import { ALL_CURRENCIES } from '../../../constants';
import { getCurrencyDetail } from '../../../shared/utility';

const unEditableFields = ['cost', 'currency', 'expiryDate'];

export const returnPassFields = (classType, data = null) => {
  let result = {
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Class pass name',
      value: data ? data.name : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Class pass description',
      value: data ? data.description : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    places: {
      label: 'Places',
      type: 'text',
      placeholder: 'Places',
      value: data && data.places ? data.places : null,
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    duration: {
      label: 'Duration (in minutes)',
      type: 'text',
      placeholder: 'Enter duration',
      value: data && data.duration ? data.duration : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    classCount: {
      label: 'Classes included',
      type: 'text',
      placeholder: 'Classes included',
      value: data ? data.classCount : null,
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    currency: {
      label: 'Currency',
      type: 'select',
      options: ALL_CURRENCIES,
      placeholder: 'Select',
      value: getCurrencyDetail(data && data.currency),
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    cost: {
      label: 'Price',
      type: 'text',
      placeholder: 'Class pass price',
      value: data ? data.cost : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    expiryDate: {
      label: 'Optional - set date to hide for purchase by customers',
      type: 'date',
      value: data ? data.expiryDate : null,
      validation: {
        required: false,
        isDate: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    oneOff: {
      label: 'Available only once per user',
      type: 'checkbox',
      value: data ? data.oneOff : false,
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    active: {
      label: 'Active',
      type: 'checkbox',
      value: data ? data.active : true,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorText: null
    }
  };

  if (classType !== 'personalTraining') {
    delete result.places;
    delete result.duration;
  }

  if (data !== null && unEditableFields) {
    unEditableFields.forEach((elem) => delete result[elem]);
  }

  return result;
};
