import React, { useState } from 'react';
import classes from '../../../containers/AddEditSingleClass/AddEditSingleClass.module.scss';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';

const ClientSelector = ({ users, setSelectedClient, showLabel = true }) => {
  const [filteredUsers, setFilteredUsers] = useState(null);

  if (users.length === 0) {
    return <div className={classes.noData}>No clients.</div>;
  }
  const mapClientToSelect = (array) =>
    array.map((elem) => {
      return {
        ...elem,
        label: `${elem.name} ${elem.last_name}`,
        value: elem
      };
    });

  const onFilterClientList = (text) => {
    const filter = text.toLowerCase();
    const filteredClientList = users.filter(
      (x) => x.name.toLowerCase().includes(filter) || x.last_name.toLowerCase().includes(filter)
    );
    setFilteredUsers(filteredClientList);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {showLabel && <Label>Add client</Label>}
      <Select
        options={mapClientToSelect(filteredUsers || users)}
        value="Add client"
        onChange={(e) => setSelectedClient(e.option)}
        onSearch={(e) => onFilterClientList(e.target.value)}
      />
    </div>
  );
};

export default ClientSelector;
