import React from 'react';
import { RadioButton } from 'grommet';
import useSelectFilter from '../hooks/useSelectFilter';
import { CLIENTS_FILTERS } from '../../../constants';

const ClientTypeFilter = ({ onFilter }) => {
  const { onSelectedFilterChanged, isSelectedFilter } = useSelectFilter(CLIENTS_FILTERS.ALL_MEMBERS, onFilter);

  return (
    <div className="client__type-filter">
      <h4>Client Type</h4>
      <RadioButton
        id={CLIENTS_FILTERS.FULL_MEMBERS}
        name="client_type"
        type="radio"
        label="Gym Members"
        value={CLIENTS_FILTERS.FULL_MEMBERS}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_FILTERS.FULL_MEMBERS)}
      />

      <RadioButton
        id={CLIENTS_FILTERS.CLASS_PASS_MEMBERS}
        name="client_type"
        type="radio"
        label="Class Pass Members"
        value={CLIENTS_FILTERS.CLASS_PASS_MEMBERS}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_FILTERS.CLASS_PASS_MEMBERS)}
      />

      <RadioButton
        id={CLIENTS_FILTERS.ALL_MEMBERS}
        name="client_type"
        type="radio"
        label="All Members"
        value={CLIENTS_FILTERS.ALL_MEMBERS}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_FILTERS.ALL_MEMBERS)}
      />
    </div>
  );
};

export default ClientTypeFilter;
