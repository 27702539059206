import React from 'react';

import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import ProfilePlanStatus from '../../UI/ProfilePlanStatus/ProfilePlanStatus';

import './ClientProfilePlanStatus.scss';

import { returnFormattedDate } from '../../../shared/date-utility';

import { CLIENTS_TYPES } from '../../../constants';

const ClientProfilePlanStatus = (props) => {
  const returnUserPlanInfo = () => {
    if (props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER) {
      return props.selectedClient.currentMembershipPlan
        ? props.selectedClient.currentMembershipPlan.ssPlanId.name
        : "This user hasn't signed up for any plan yet";
    }
  };

  return (
    <>
      <div className="profile-details__container" style={{ marginTop: '10px' }}>
        {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER && (
          <h3 style={{ fontSize: '18px' }}>MEMBERSHIP PLAN DETAILS</h3>
        )}
        <h4 className="profile-details__header">{returnUserPlanInfo()}</h4>

        {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER && props.selectedClient.currentMembershipPlan && (
          <>
            <ProfileTextWithLabel
              label="Membership end date"
              text={<ProfilePlanStatus currentMembershipPlan={props.selectedClient.currentMembershipPlan} />}
            />

            <ProfileTextWithLabel
              label="Contract ends"
              text={returnFormattedDate(props.selectedClient.currentMembershipPlan.contractEnd)}
            />

            <ProfileTextWithLabel
              label="Minimum contract paid"
              text={props.selectedClient.currentMembershipPlan.isMinContractEndPaid ? 'Yes' : 'No'}
            />

            {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER && (
              <ProfileTextWithLabel
                label="Joining Fee"
                text={props.selectedClient.joiningFeePaid ? 'Paid' : 'Not Paid'}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
export default ClientProfilePlanStatus;
