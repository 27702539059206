import React, { useState } from 'react';
import Button from 'grommet/components/Button';
import Modal from 'react-modal';
import { HashLink as Link } from 'react-router-hash-link';
import classes from './PayButton.module.scss';
import { useMutation } from 'react-query';
import { delayFetchFunctions, payForClass } from '../../../apiFunctions/apiFunctions';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Loading from '../../../components/Loading/Loading';
import { returnErrorFromResponse } from '../../../shared/utility';

const PayButton = ({ attendanceId, stripeOrderId, classType }) => {
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState(null);

  const mutatePayForAttendance = useMutation(() => payForClass(attendanceId, stripeOrderId), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Successfully paid',
        show: true
      });
      setShowModal(false);
      delayFetchFunctions([['upcomingClasses', classType], ['clientUpcomingClasses', classType], ['userData']]);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  const paymentModal = (
    <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal">
      <div className="grommet">
        Do you want to pay for chosen class?
        <br />
        This class will be paid for using your default card payment. You can change it{' '}
        <Link to="/gym/settings#payments">here</Link> if you prefer.
        <div className="ss-modal__buttons-container">
          {mutatePayForAttendance.isLoading ? (
            <Loading />
          ) : (
            <>
              <Button box label="Yes" secondary onClick={mutatePayForAttendance.mutate} />
              <Button box label="Cancel" primary onClick={() => setShowModal(false)} />
            </>
          )}
        </div>
      </div>
    </Modal>
  );

  return (
    <div>
      <CustomToast toast={toast} />
      {paymentModal}
      <Button
        className={classes.nowrap}
        label="Pay for class"
        secondary={true}
        onClick={() => setShowModal(true)}
        data-cy="payForClass"
      />
    </div>
  );
};

export default PayButton;
