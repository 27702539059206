import React from 'react';

const WhiteBackground = (props) => {
  return (
    <div className="white-background">
      <div className="container">{props.children}</div>
    </div>
  );
};
export default WhiteBackground;
