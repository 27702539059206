import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './AddEditCategory.module.scss';
import Label from 'grommet/components/Label';
import Button from 'grommet/components/Button';
import TextInput from 'grommet/components/TextInput';
import Icons from '../../../../components/UI/Icons/Icons';
import FormField from 'grommet/components/FormField';
import axios from '../../../../axios-global';

export class AddEditCategory extends Component {
  state = {
    name: '',
    options: []
  };

  componentDidMount() {
    if (this.props.category) {
      this.setState({
        name: this.props.category.name,
        options: this.props.category.options
      });
    }
  }

  deleteTag = (index) => {
    let arrNewOptions = this.state.options;
    arrNewOptions.splice(index, 1);
    this.setState({ options: arrNewOptions });
  };

  addTag = () => {
    let arrNewOptions = this.state.options;
    arrNewOptions.push('');
    this.setState({ options: arrNewOptions });
  };

  handleChange(i, e) {
    let arrOptions = this.state.options;
    arrOptions[i] = e.target.value;
    this.setState({ options: arrOptions });
  }

  renderOptionFields = () => {
    return this.state.options.map((elem, index) => (
      <div className={classes.categoryOption} key={index}>
        <FormField className={classes.tagInput}>
          <TextInput value={elem} onDOMChange={(evt) => this.handleChange(index, evt)} placeHolder="Enter tag" />
        </FormField>

        <Icons delete deleteClick={() => this.deleteTag(index)} />
      </div>
    ));
  };

  handleCategoryChange = (evt) => {
    this.setState({ name: evt.target.value });
  };

  closeAndRefreshCategory = () => {
    this.props.refreshCategory();
    this.props.closeModal();
  };

  closeModal = () => {
    this.props.closeModal();
  };

  saveCategory = async () => {
    const baseUrl =
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_DEV_ENV_API}`
        : `${process.env.REACT_APP_PROD_ENV_API}`;
    if (this.props.category) {
      try {
        const response = await axios.patch(`${baseUrl}/api/video-config/${this.props.category._id}`, this.state);

        if (response.status === 200) {
          this.closeAndRefreshCategory();
        } else {
          this.props.showError('Failed to save category');
        }
      } catch (error) {
        this.props.showError('Failed to save category');
      }
    } else {
      try {
        const response = await axios.post(`${baseUrl}/api/video-config`, this.state);

        if (response.status === 201) {
          this.closeAndRefreshCategory();
        } else {
          this.props.showError('Failed to save category');
        }
      } catch (error) {
        this.props.showError('Failed to save category');
      }
    }
  };

  render = () => {
    return (
      <>
        <h4>Category</h4>

        <Label>Category name</Label>
        <FormField className={classes.input}>
          <TextInput
            value={this.state.name}
            onDOMChange={this.handleCategoryChange}
            placeHolder="Enter category name"
          />
        </FormField>

        <div className={classes.optionContainer}>
          <h5>Options</h5>
          {this.renderOptionFields()}

          <Button label="Add a tag" secondary={true} onClick={this.addTag} />
        </div>

        <div className={classes.btnContainer}>
          <Button className={classes.btnCancel} label="Cancel" secondary={false} onClick={this.closeModal} />
          <Button label="Save" secondary={true} onClick={this.saveCategory} />
        </div>
      </>
    );
  };
}
export default connect()(AddEditCategory);
