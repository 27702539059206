import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationLink.module.scss';

const NavigationLink = (props) => {
  let classesArray = [classes.link];

  if (props.light) {
    classesArray.push(classes.linkLight);
  }

  if (props.dark) {
    classesArray.push(classes.linkDark);
  }

  return (
    <NavLink className={classesArray.join(' ')} to={props.link} exact={props.exact}>
      {props.children}
    </NavLink>
  );
};
export default NavigationLink;
