import React from 'react';
import { Notification } from 'grommet';

const ClassStatus = ({ singleClass: { active, enabled } }) =>
  !active ? (
    <Notification message="This class is cancelled" status="critical" className="ss-top-notification" />
  ) : !enabled ? (
    <Notification
      message="Hidden on the timetable"
      status="warning"
      className="ss-top-notification"
      data-cy="notification"
    />
  ) : null;

export default ClassStatus;
