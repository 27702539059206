import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from '../../components/Navigation/Header/Header';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Footer from '../../brandConfig/components/Footer/Footer';

import classes from './Layout.module.scss';
import { menuItemsArray } from '../../components/Navigation/MenuNavigation/menuItems';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userData: state.auth.userData,
    selectedGym: state.admin.selectedGym,
    locations: state.location.locations
  };
};

class Layout extends Component {
  state = {
    showSideDrawer: false,
    gymId: null,
    menuItems: []
  };

  sideDrawerClosedHandler = () => {
    this.setState({ showSideDrawer: false });
  };

  sideDrawerToggleHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedGym !== this.props.selectedGym && this.props.userData) {
      const url = this.props.userData.role === 'user' ? '/gym' : `/gym/${this.props.selectedGym._id}`;
      const settings = this.props.selectedGym.settings;

      this.setState({
        menuItems: menuItemsArray(this.props.userData, settings, url, this.props.locations, 'showInMenu')
      });
    }
  }

  render() {
    let mainContainerClass = null;
    if (this.props.location.pathname !== '/') {
      mainContainerClass = classes.mainContainer;
    }

    let dashboardLink = '/';
    if (this.props.userData && this.props.userData.role !== 'user' && !this.props.selectedGym) {
      dashboardLink = '/gym';
    }

    if (this.props.userData && this.props.userData.role !== 'user' && this.props.selectedGym) {
      dashboardLink = `/gym/${this.props.selectedGym._id}`;
    }

    if (this.props.userData && this.props.userData.role === 'user') {
      dashboardLink = '/gym';
    }

    return (
      <>
        <Header
          menuItems={this.state.menuItems}
          isAuthenticated={this.props.isAuthenticated}
          dashboardLink={dashboardLink}
          drawerToggleClicked={this.sideDrawerToggleHandler}
          gym={this.props.selectedGym}
          userData={this.props.userData}
          location={this.props.locations}
        />

        <SideDrawer
          selectedGym={this.props.selectedGym}
          menuItems={this.state.menuItems}
          isAuthenticated={this.props.isAuthenticated}
          dashboardLink={dashboardLink}
          open={this.state.showSideDrawer}
          closed={this.sideDrawerClosedHandler}
        />

        <main className={mainContainerClass} onClick={this.sideDrawerClosedHandler}>
          {this.props.children}
        </main>

        <Footer />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Layout));
