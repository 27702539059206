import React, { useState, useEffect } from 'react';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import classes from './UpcomingClassesForAllGyms.module.scss';
import { ALL_CLASS_TYPES_WITH_DETAILS } from '../../../constants';
import { Tab, Tabs } from 'grommet';
import DefaultTimetable from '../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import { useQuery } from 'react-query';
import { delayFetchFunctions, fetchUpcomingClassesInAllGyms } from '../../../apiFunctions/apiFunctions';
import { returnClassConfig } from '../../../shared/gymFunctions';
import AddEditSingleClass from '../../AddEditSingleClass/AddEditSingleClass';
import Button from 'grommet/components/Button';
import GymSelector from '../../../components/Selectors/GymSelector/GymSelector';
import UpcomingClassesForAllGymsFilters from './UpcomingClassesForAllGymsFilters';

const returnClassesList = (array = []) => {
  let result = [];
  array.forEach(({ classes }) => {
    result = result.concat(classes);
    return;
  });
  result = result.map((elem) => {
    return {
      limit: elem.capacity,
      _id: elem.classId,
      name: elem.className,
      cost: elem.cost,
      currency: elem.currency,
      classDate: elem.date,
      classTime: elem.time,
      gymId: [
        {
          _id: elem.locationId,
          name: elem.locationName
        }
      ],
      gymName: [elem.locationName],
      joinedUsers: elem.totalNumberOfAttendees,
      trainer: {
        name: elem.trainerName
      },
      active: true,
      duration: 60
    };
  });

  return result;
};

const UpcomingClassesForAllGyms = ({ userGyms }) => {
  const classTypes = ALL_CLASS_TYPES_WITH_DETAILS();
  const [selectedClassType, setSelectedClassType] = useState(classTypes[0].type);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAddNew, setShowAddNew] = useState(false);
  const [gymSelector, setGymSelector] = useState(null);
  const [selectedClassConfig, setSelectedClassConfig] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  const { data, error, isLoading } = useQuery(['fetchUpcomingClassesInAllGyms', selectedClassType], () =>
    fetchUpcomingClassesInAllGyms(selectedClassType)
  );

  useEffect(() => {
    setSelectedClassType(classTypes[activeIndex].type);
  }, [activeIndex, classTypes]);

  useEffect(() => {
    setFilteredData(data !== undefined ? returnClassesList(data) : []);
  }, [data]);

  const renderTabs = (
    <Tabs justify="start" activeIndex={activeIndex} onActive={setActiveIndex}>
      {classTypes.map((elem) => (
        <Tab title={elem.title} key={elem.type}>
          <DefaultTimetable
            classesList={filteredData}
            classesListLoading={isLoading}
            classesListError={error}
            classesDetails={elem}
            classType={elem.type}
            // onFetchClasses={refetch}
            showAllGymsData={true}
            classConfig={{}}
          />
        </Tab>
      ))}
    </Tabs>
  );

  if (showAddNew) {
    const onSelect = (gym) => {
      setGymSelector(gym);
      setSelectedClassConfig(returnClassConfig(gym.settings.classConfig, selectedClassType));
    };

    return (
      <>
        <GymSelector classType={selectedClassType} onSelect={onSelect} />
        {gymSelector && (
          <AddEditSingleClass
            classType={selectedClassType}
            classConfig={selectedClassConfig}
            gymSelector={gymSelector}
            onClose={() => {
              setShowAddNew(false);
              delayFetchFunctions([['fetchUpcomingClassesInAllGyms', selectedClassType]], 0);
            }}
          />
        )}
      </>
    );
  }

  return (
    <div>
      <div className={classes.addNewButton}>
        <Button primary label="Filters" onClick={() => setShowFilters((showFilters) => !showFilters)} />
        <Button secondary label="Add New" onClick={() => setShowAddNew(true)} />
      </div>
      <UpcomingClassesForAllGymsFilters
        showFilters={showFilters}
        gyms={userGyms}
        onFilter={setFilteredData}
        data={returnClassesList(data)}
        hideFiters={() => setShowFilters(false)}
      />
      {renderTabs}
    </div>
  );
};

export default withAdminDashboard(UpcomingClassesForAllGyms);
