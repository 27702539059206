import React, { useState } from 'react';
import ProfilePicturePlaceholder from '../../../brandConfig/assets/profile-photo-placeholder.png';
import Spinning from 'grommet/components/icons/Spinning';

import classes from './ProfilePicture.module.scss';
import Toast from 'grommet/components/Toast';

const ProfilePicture = (props) => {
  const [toastMsg, setToastMsg] = useState(false);
  const uploadNewPhoto = (e) => {
    const files = Array.from(e.target.files);
    const formData = new FormData();
    let exceedFileSize = false;
    files.forEach((file) => {
      if (file.size / (1024 * 1024) > 5) {
        exceedFileSize = true;
      }
      formData.append('image', file);
    });
    if (exceedFileSize) {
      setToastMsg('File size should not be more than 5MB');
    } else {
      props.uploadNewPhoto(formData);
    }
  };

  let src = ProfilePicturePlaceholder;
  if (props.src) {
    src = props.src;
  }

  const closeToast = () => {
    setToastMsg(false);
  };

  const renderErrorToast = () => {
    if (toastMsg) {
      return (
        <Toast status={'critical'} onClose={closeToast}>
          {toastMsg}
        </Toast>
      );
    }
  };

  return (
    <>
      <div className={classes.imageUploadContainer}>
        {renderErrorToast()}
        <label htmlFor="single">
          {!props.profilePictureLoading ? (
            <img src={src} className={classes.profilePicture} alt="Profile" />
          ) : (
            <Spinning />
          )}
        </label>
        {props.editable ? <input type="file" name="image" id="single" onChange={uploadNewPhoto} /> : null}
      </div>
    </>
  );
};
export default ProfilePicture;
