import React, { useState } from 'react';

import Modal from 'react-modal';
import Button from 'grommet/components/Button';
import HealthStatement from '../../../../src/brandConfig/componentsTexts/HealthStatement';

import TermsOfService from '../../TermsOfService/TermsOfService';

import classes from './StepThird.module.scss';

Modal.setAppElement('#root');
Modal.defaultStyles.overlay.position = 'absolute';
const StepThird = ({ dataAccepted, termsUrl, history, locationHome }) => {
  const [showModal, setShowModal] = useState(true);
  const [termsError, setTermsError] = useState(true);
  const [healthStatementAccepted, setHealthStatementAccepted] = useState(false);
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const handlePrivacyCheckbox = () => {
    setTermsOfServiceAccepted(!termsOfServiceAccepted);
  };

  const handleHealthStatementCheckbox = () => {
    setHealthStatementAccepted(!healthStatementAccepted);
  };

  const cancelTermsInModal = () => {
    setShowModal(false);
    setTermsOfServiceAccepted(false);
  };

  const acceptTermsInModal = () => {
    setShowModal(false);
    setTermsOfServiceAccepted(true);
  };

  const renderButtonOrInfo = () => {
    if (healthStatementAccepted && termsOfServiceAccepted) {
      return (
        <Button className={classes.nextStepButton} box label="Next step" secondary={true} onClick={dataAccepted} />
      );
    }
  };
  return (
    <>
      <Modal isOpen={showModal} className="ss-modal">
        <div className={`grommet ${classes.textContainer}`} data-cy="signup_accept_tnc_modal">
          <TermsOfService termsUrl={termsUrl} termsLoadFailed={(error) => setTermsError(error)} />
        </div>
        {!termsError ? (
          <>
            <Button
              className={classes.nextStepButton}
              box
              label="Agree"
              secondary={true}
              onClick={acceptTermsInModal}
            />
            <Button className={classes.nextStepButton} box label="Cancel" primary onClick={cancelTermsInModal} />
          </>
        ) : (
          <Button
            className={classes.nextStepButton}
            box
            label="Ok"
            secondary={true}
            onClick={() => history.push(locationHome)}
          />
        )}
      </Modal>

      <HealthStatement
        healthStatementAccepted={healthStatementAccepted}
        termsOfServiceAccepted={termsOfServiceAccepted}
        handleHealthStatementCheckbox={handleHealthStatementCheckbox}
        handlePrivacyCheckbox={handlePrivacyCheckbox}
        openModal={openModal}
      />

      {renderButtonOrInfo()}
    </>
  );
};
export default StepThird;
