import React from 'react';
import classes from './UsersDayEventModal.module.scss';
import moment from 'moment';
import CloseIcon from 'grommet/components/icons/base/Close';

const UsersDayEventModal = ({ timetable, showClassDetail, onClose }) => {
  const classDate = moment(timetable.classes[0].date, 'YYYY.MM.DD');
  return (
    <div>
      <h1>
        {timetable.leader.name} {timetable.leader.last_name}
      </h1>
      <CloseIcon className={classes.close} onClick={onClose} />
      <p className={classes.date}>
        {classDate.format('DD MMMM YYYY')}
        <span className={classes.dayName}>{classDate.format('dddd')}</span>
      </p>
      <div className={classes.eventContainer}>
        {timetable.classes.map((event) => {
          return (
            <div key={event.classId} className={classes.event} onClick={() => showClassDetail(event)}>
              <p>
                {event.time} - {moment(event.time, 'h:mm A').add(event.duration, 'minutes').format('h:mm A')}
              </p>
              <h5>{event.className}</h5>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default UsersDayEventModal;
