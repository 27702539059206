import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, TableHeader, TableRow } from 'grommet';
import Button from 'grommet/components/Button';
import Modal from 'react-modal';
import AddEditCategory from '../AddEditCategory/AddEditCategory';
import Toast from 'grommet/components/Toast';
import axios from '../../../../axios-global';
import Icons from '../../../../components/UI/Icons/Icons';

export class MediaCategory extends Component {
  state = {
    showModal: false,
    allCategories: []
  };

  getAllCategory = async () => {
    try {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? `${process.env.REACT_APP_DEV_ENV_API}`
          : `${process.env.REACT_APP_PROD_ENV_API}`;
      const response = await axios.get(`${baseUrl}/api/video-config/active`);

      if (response.status === 200) {
        this.setState({
          allCategories: response.data
        });
      } else {
        this.showErrorToast('Failed to get categories');
      }
    } catch (error) {
      this.showErrorToast('Failed to get categories');
    }
  };

  componentDidMount() {
    this.getAllCategory();
  }

  handleModalClose = () => {
    this.setState({
      showModal: false,
      editCategory: undefined
    });
  };

  addEditCategory = () => {
    this.setState({
      showModal: true
    });
  };

  editCategory = (category) => {
    this.setState({
      editCategory: category,
      showModal: true
    });
  };

  handleDeleteCategoryClick = (categoryId) => {
    if (!this.state.showConfirmToast) {
      return this.setState({
        showConfirmToast: true
      });
    }

    this.setState(
      {
        showConfirmToast: false
      },
      () => this.deleteCategory(categoryId)
    );
  };

  handleConfirmToastClose = () => {
    this.setState({
      showConfirmToast: false
    });
  };

  handleToastClose = () => {
    this.setState({ errorMessage: false });
  };

  showErrorToast = (message) => {
    this.setState({ errorMessage: message });
  };

  renderNotification = () => {
    if (this.state.showConfirmToast) {
      return (
        <Toast status="warning" onClose={this.handleConfirmToastClose}>
          Click once again to confirm the deletion of the media category
        </Toast>
      );
    }
    if (this.state.errorMessage) {
      return (
        <Toast status="critical" onClose={this.handleToastClose}>
          {this.state.errorMessage}
        </Toast>
      );
    }
  };

  deleteCategory = async (categoryId) => {
    const baseUrl =
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_DEV_ENV_API}`
        : `${process.env.REACT_APP_PROD_ENV_API}`;

    try {
      const response = await axios.delete(`${baseUrl}/api/video-config/${categoryId}`, this.state);

      if (response.status === 200) {
        this.getAllCategory();
      }
    } catch (error) {
      this.showErrorToast('Failed to delete category');
    }
  };

  renderCategoryTable() {
    if (this.state.allCategories.length === 0) {
      return (
        <>
          <div>No media category</div>
        </>
      );
    }
    const rows = this.state.allCategories.map((element) => {
      return (
        <TableRow key={element._id}>
          <td>{element.name}</td>
          <td>
            {element.options.map((option, index) => {
              return `${option}${element.options.length !== index + 1 ? ', ' : ''}`;
            })}
          </td>
          <td width="20">
            <Icons
              edit
              delete
              deleteClick={() => this.handleDeleteCategoryClick(element._id)}
              editClick={() => this.editCategory(element)}
            />
          </td>
        </TableRow>
      );
    });

    return (
      <Table responsive={true}>
        <TableHeader style={{ color: 'black' }} labels={['Categroy', 'Options', 'Action']} />
        <tbody>{rows}</tbody>
      </Table>
    );
  }

  renderAddEditModal() {
    return (
      <Modal onRequestClose={this.handleModalClose} isOpen={this.state.showModal} className={'ss-modal'}>
        <div className="grommet">
          <AddEditCategory
            closeModal={this.handleModalClose}
            refreshCategory={this.getAllCategory}
            showError={this.showErrorToast}
            category={this.state.editCategory}
          />
        </div>
      </Modal>
    );
  }

  render = () => {
    return (
      <>
        {this.renderNotification()}
        <Button label="Add Category" secondary={true} onClick={this.addEditCategory} />
        {this.renderCategoryTable()}
        {this.renderAddEditModal()}
      </>
    );
  };
}
export default connect()(MediaCategory);
