import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Spinning from 'grommet/components/icons/Spinning';
import Toast from 'grommet/components/Toast';

export default (Component) => {
  const mapStateToProps = (state) => {
    return {
      selectedGym: state.admin.selectedGym,
      userData: state.auth.userData,

      gymsUsersFullData: state.admin.gymsUsersFullData,
      gymsUsersErrorData: state.admin.gymsUsersErrorData,
      gymsUsersFullDataLoading: state.admin.gymsUsersFullDataLoading,
      gymsUsersFullDataError: state.admin.gymsUsersFullDataError,
      gymUsersFullDataGymId: state.admin.gymUsersFullDataGymId,
      isAdmin: state.auth.userData.role === 'admin'
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      onFetchGymUsersFullData: (gymId) => dispatch(actions.fetchGymUsersFullData(gymId)),
      onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId))
    };
  };

  class Wrapper extends React.Component {
    componentDidMount() {
      if (this.props.gymsUsersFullData !== null) {
        if (this.props.gymUsersFullDataGymId === this.props.selectedGym._id) {
          return;
        }
      }
      this.props.onFetchGymUsersFullData(this.props.selectedGym._id);
    }

    render() {
      if (this.props.gymsUsersFullDataLoading || this.props.gymsUsersFullData == null) {
        return <Spinning />;
      }

      if (this.props.gymsUsersFullDataError) {
        return <Toast status="critical">Something went wrong. Try again.</Toast>;
      }
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
