import React, { useState } from 'react';
import ListItem from 'grommet/components/ListItem';
import Icons from '../../components/UI/Icons/Icons';
import { useMutation } from 'react-query';
import classes from './singleFAQ.module.scss';
import { deleteFAQ, delayFetchFunctions } from '../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../shared/utility';
import CustomToast from '../../components/CustomToast/CustomToast';

const SingleFAQ = ({ singleFAQ, editFAQ, isAdmin }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [toast, setToast] = useState(null);

  const mutateDeleteFAQ = useMutation(() => deleteFAQ(singleFAQ._id), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Successfully deleted',
        show: true
      });
      delayFetchFunctions([['faq']], 0);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });
  const handleFAQDelete = () => {
    if (!showConfirm) {
      return setShowConfirm(true);
    }
    mutateDeleteFAQ.mutate();
  };

  return (
    <>
      <CustomToast toast={toast} />
      <ListItem direction="column" align="start" separator="horizontal">
        <div className={classes.singleFAQ}>
          <div>
            <span className={classes.faq}>
              <h4>{singleFAQ.question}</h4>
              <p>{singleFAQ.answer}</p>
            </span>
          </div>

          {isAdmin && <Icons edit delete editClick={() => editFAQ(singleFAQ)} deleteClick={handleFAQDelete} />}
        </div>
      </ListItem>
    </>
  );
};

export default SingleFAQ;
