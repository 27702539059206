import React, { useState, useEffect } from 'react';
import CheckBox from 'grommet/components/CheckBox';
import FormField from 'grommet/components/FormField';
import Label from 'grommet/components/Label';
import TextInput from 'grommet/components/TextInput';
import classes from '../FormCreator.module.scss';

const OptionalFormElement = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (props.elementData.checkbox) {
      setIsChecked(props.elementData.checkbox.enabled);
    }
  }, [props.elementData.checkbox]);

  const checkBoxChangeHandler = (checkboxEvent) => {
    setIsChecked(checkboxEvent.target.checked);
    props.optionalElementChanged(checkboxEvent, props.elementData.identifier);
  };

  return (
    <div key={props.elementData.identifier}>
      <div className={classes.checkBoxWithBottomMargin}>
        <CheckBox
          checked={isChecked}
          onChange={(checkboxEvent) => checkBoxChangeHandler(checkboxEvent)}
          label={props.elementData.checkbox.label}
        />
      </div>
      {isChecked ? (
        <div>
          <Label className={classes.label}>{props.elementData.label}</Label>
          <FormField className={classes.input}>
            <TextInput
              value={props.elementData.value ? props.elementData.value.toString() : ''}
              placeHolder={props.elementData.placeholder}
              onDOMChange={(event) => props.valueChanged(event, props.elementData.identifier)}
            />
          </FormField>
          {props.warning}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default OptionalFormElement;
