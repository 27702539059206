import React from 'react';

import NextIcon from 'grommet/components/icons/base/Next';

import NavigationLink from '../NavigationLink/NavigationLink';
import classes from './SideDrawer.module.scss';

const sideDrawer = ({ open, isAuthenticated, dashboardLink, selectedGym, menuItems, userData, closed }) => {
  let attachedclasses = [classes.sideDrawer, classes.close];
  if (open) {
    attachedclasses = [classes.sideDrawer, classes.open];
  }

  let authSection = (
    <>
      <NavigationLink link="/login">Log in</NavigationLink>
    </>
  );
  let dashboard,
    navigationLinks = null;

  if (isAuthenticated) {
    dashboard = <NavigationLink link={dashboardLink}>Dashboard</NavigationLink>;
    authSection = <NavigationLink link="/logout">Logout</NavigationLink>;
    navigationLinks = (
      <>
        <div className={classes.space} />
        {selectedGym &&
          menuItems.map((elem) => (
            <NavigationLink key={elem.name} link={elem.link}>
              {elem.name}
            </NavigationLink>
          ))}
      </>
    );
  }

  const userLinks = (!isAuthenticated || (userData && userData.role === 'user')) && (
    <>
      <div className={classes.space} />
      <NavigationLink link="/contact">Contact</NavigationLink>
      <div className={classes.space} />
      <NavigationLink link="/privacy-policy">Privacy Policy</NavigationLink>
      <NavigationLink link="/terms-of-website">Terms of website</NavigationLink>
      <NavigationLink link="/terms-of-service">Terms of service</NavigationLink>
    </>
  );

  return (
    <>
      <div className={attachedclasses.join(' ')} onClick={closed}>
        <NextIcon className={classes.icon} />
        <nav className={classes.mobileMenu}>
          {authSection}
          <div className={classes.space} />
          {dashboard}

          {navigationLinks}

          {userLinks}
        </nav>
      </div>
    </>
  );
};

export default sideDrawer;
