const timetableData = [
  {
    title: 'Location',
    value: 'gymName',
    additionalDescription: null
  },
  {
    title: 'Name',
    value: 'name',
    additionalDescription: null
  },
  {
    title: 'Date',
    value: 'classDate',
    additionalDescription: null
  },
  {
    title: 'Time',
    value: 'classTime',
    additionalDescription: null
  },

  {
    title: 'Duration',
    value: 'duration',
    additionalDescription: 'min'
  },
  {
    title: 'Cost',
    value: 'cost',
    additionalDescription: null
  },
  {
    title: 'Trainer',
    value: 'trainer',
    additionalDescription: null
  },
  {
    title: 'Attendees',
    value: 'attendanceList',
    showNames: false,
    additionalDescription: null
  },
  {
    title: '',
    value: 'actionColumn',
    showNames: false,
    additionalDescription: null
  }
];

export const returnTimetableData = (classType, userRole, classConfig, showLocation = false) => {
  let result = timetableData;
  if (showLocation) {
    const fieldsToHide = ['Trainer'];
    return result.filter((elem) => !fieldsToHide.includes(elem.title));
  }

  result = result.filter((elem) => elem.title !== 'Location');
  result = result.filter((elem) => (classConfig.trainerRequired ? elem : elem.title !== 'Trainer'));
  result = result.filter((elem) => (classConfig.showClassCost ? elem : elem.title !== 'Cost'));
  result = result.filter((elem) => (classConfig.singleDayTimetable ? elem.title !== 'Date' : elem));

  switch (classType) {
    case 'class': {
      return result;
    }
    case 'personalTraining': {
      result = result.filter(({ title }) => title !== 'Name');
      if (userRole !== 'user') {
        const index = result.findIndex(({ title }) => title === 'Attendees');
        result[index].showNames = true;
      }
      return result;
    }

    case 'induction':
    case 'gymClass':
    case 'swimmingClass':
    case 'tennisClass':
    case 'massageClass': {
      result = result.filter(({ title }) => title !== 'Name');
      return result;
    }
    default: {
      return result;
    }
  }
};
