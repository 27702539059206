import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  token: null,
  userData: null,
  error: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return updateObject(state, {
        error: null,
        loading: true
      });
    case actionTypes.AUTH_RESET:
      return updateObject(state, {
        error: null,
        loading: false
      });
    case actionTypes.AUTH_SUCCESS:
      return updateObject(state, {
        token: action.token,
        error: null,
        loading: false
      });
    case actionTypes.AUTH_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false
      });
    case actionTypes.AUTH_LOGOUT:
      return updateObject(state, {
        token: null,
        userData: null
      });
    case actionTypes.AUTH_SET_USER_DATA:
      return updateObject(state, {
        userData: action.userData,
        loading: false
      });
    case actionTypes.AUTH_SET_USER_DATA_START:
      return updateObject(state, {
        loading: true
      });
    case actionTypes.CLIENT_CHANGE_CLASS_PASSES_LIMIT:
      if (action.nextMonth) {
        return updateObject(state, {
          userData: updateObject(state.userData, {
            classesLeftNextMonth: state.userData.classesLeftNextMonth + action.adjustment
          })
        });
      } else {
        return updateObject(state, {
          userData: updateObject(state.userData, {
            classesLeft: state.userData.classesLeft + action.adjustment
          })
        });
      }
    case actionTypes.CLIENT_CHANGE_PTS_LIMIT:
      return updateObject(state, {
        userData: updateObject(state.userData, {
          PTclassesLeft: state.userData.PTclassesLeft + action.adjustment
        })
      });

    case actionTypes.UPDATE_USER_DATA:
      return updateObject(state, {
        userData: action.payload.newData
      });

    default:
      return state;
  }
};

export default reducer;
