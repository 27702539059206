import React, { useState } from 'react';
import CheckBox from 'grommet/components/CheckBox';
import { returnLabelsForDataType } from '../constants';
import classes from './BulkModal.module.scss';

import moment from 'moment';
import Button from 'grommet/components/Button';
import BulkEditingForm from '../BulkEditingForm/BulkEditingForm';

const BulkTable = ({ data, closeModal, updatedData = null, classType }) => {
  const [selectedId, setSelectedId] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const fields = returnLabelsForDataType(classType);

  const handleFieldSelection = (fieldName) => {
    const index = selectedFields.indexOf(fieldName);
    if (fieldName === '_id') {
      setSelectedId(index < 0 ? data.map((elem) => elem._id) : []);
      setSelectedFields(index < 0 ? fields.map((elem) => elem.name) : []);
    } else {
      let newFields = selectedFields.filter((elem) => elem !== '_id');
      newFields = index === -1 ? [...newFields, fieldName] : newFields.filter((elem) => elem !== fieldName);
      newFields =
        newFields.length === fields.length - 1 && selectedId.length === data.length
          ? [...newFields, '_id']
          : newFields.filter((elem) => elem !== '_id');
      setSelectedFields(newFields);
    }
  };

  const handleIdSelection = (id) => {
    const index = selectedId.indexOf(id);
    let newIdArr = index === -1 ? [...selectedId, id] : selectedId.filter((elem) => elem !== id);
    setSelectedId(newIdArr);
    setSelectedFields((selectedFields) =>
      newIdArr.length === data.length && selectedFields.length === fields.length - 1
        ? [...selectedFields, '_id']
        : selectedFields.filter((elem) => elem !== '_id')
    );
  };

  const listHeader = () => (
    <thead className={classes.tableHeader}>
      <tr>
        {fields.map((elem) => (
          <th key={elem.name}>
            {elem.label}
            <div className={classes.checkboxContainer}>
              <CheckBox
                checked={selectedFields.indexOf(elem.name) >= 0}
                onChange={() => handleFieldSelection(elem.name)}
              />
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );

  const listBody = (data) => {
    const returnValue = (fieldName, value) => {
      if (value === undefined) {
        return null;
      }

      switch (fieldName) {
        case '_id': {
          return (
            <div className={classes.checkboxContainer}>
              <CheckBox onChange={() => handleIdSelection(value)} checked={selectedId.indexOf(value) >= 0} />
            </div>
          );
        }
        case 'waitListConfig': {
          return <div>{value.active === true ? 'true' : 'false'}</div>;
        }
        case 'minimalTimeCancellationInHours': {
          return <div>{value.toString()}</div>;
        }
        case 'classDate': {
          return <div>{moment(value).format('DD/MM')}</div>;
        }
        default:
          return <div>{value === true ? 'true' : value === false ? 'false' : value}</div>;
      }
    };

    return (
      <tbody>
        {data.map((elem, index) => (
          <tr key={elem.name + index}>
            {fields.map((field) => (
              <th key={elem._id + field.name} className={classes.headerItem}>
                {returnValue(field.name, elem[field.name])}
              </th>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  const returnButton = () =>
    selectedId.length !== 0 && selectedFields.length !== 0 ? (
      <Button
        label="Update data"
        primary
        onClick={() => setShowForm(true)}
        style={{ width: '100%', maxWidth: '100%' }}
      />
    ) : (
      <Button label="Update data" style={{ width: '100%', maxWidth: '100%' }} />
    );

  if (showForm) {
    return (
      <BulkEditingForm
        data={data.filter((elem) => selectedId.includes(elem._id))}
        fields={selectedFields}
        closeModal={closeModal}
        updatedData={updatedData}
        classType={classType}
      />
    );
  }
  return (
    <div className={classes.root}>
      {returnButton()}
      <table>
        {listHeader()}
        {listBody(data)}
      </table>
    </div>
  );
};

export default BulkTable;
