import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchTemplates } from '../../apiFunctions/apiFunctions';
import withUser from '../../store/hoc/withUser';
import { Button, Notification } from 'grommet';
import classes from './ManageTemplates.module.scss';

import Loading from '../../components/Loading/Loading';
import AddEditTemplate from './AddEditTemplate/AddEditTemplate';
import ShowTemplateList from './ShowTemplateList/ShowTemplateList';

const ManageTemplates = ({ classType, selectedGym }) => {
  const [showAddEditTemplate, setShowAddEditTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const { data, isLoading, error } = useQuery(['templates', classType], () =>
    fetchTemplates(selectedGym._id, classType)
  );

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message={error} status="critical" />;
  }

  const addNewTemplateButton = (
    <Button
      className={classes.addButton}
      label={'Add'}
      secondary={true}
      onClick={() => {
        setSelectedTemplate(null);
        setShowAddEditTemplate(true);
      }}
    />
  );

  const updateTemplate = (template) => {
    setSelectedTemplate(template);
    setShowAddEditTemplate(true);
  };

  if (showAddEditTemplate) {
    return (
      <AddEditTemplate
        classType={classType}
        selectedTemplate={selectedTemplate}
        onClose={() => setShowAddEditTemplate(false)}
      />
    );
  }

  const returnTemplates =
    data && data.length > 0 ? (
      <ShowTemplateList data={data} classType={classType} onUpdateTemplate={updateTemplate} />
    ) : (
      <h3>No templates</h3>
    );

  return (
    <>
      <div className={classes.buttonContainer}>{addNewTemplateButton}</div>
      {returnTemplates}
    </>
  );
};

export default withUser(ManageTemplates);
