import React, { useState } from 'react';
import classes from './classConfig.module.scss';
import CheckBox from 'grommet/components/CheckBox';
import { FormField, Label } from 'grommet';
import NumberInput from 'grommet/components/NumberInput';
import { returnClassConfig } from '../../../../shared/gymFunctions';

const ClassConfig = ({ classType, classConfig, handleConfigChange }) => {
  const gymSettingsConfig = () => {
    let result = returnClassConfig(classConfig, classType);
    delete result.schedule;
    delete result.template;
    return result;
  };
  const [config, setConfig] = useState(gymSettingsConfig());
  const optionLabels = {
    allowedToBuyPasses: 'Allowed to buy passes',
    trainerRequired: 'Trainer required',
    singleDayTimetable: 'Single day timetable',
    showClassCost: 'Show class cost',
    consecutiveBookingsEnabled: 'Allow consecutive bookings'
  };

  const handleChange = (value, keyName) => {
    let data = config;
    data[keyName] = value;
    handleConfigChange(data);
    setConfig(data);
  };

  return (
    <div className={classes.configContainer}>
      {Object.keys(config).map((key) => {
        if (key !== 'classType' && key !== '_id') {
          return (
            <>
              {key === 'seventhDayAvailabilityHour' ? (
                <>
                  <Label>Set a time for 7th day to be shown from</Label>
                  <FormField className={classes.input}>
                    <NumberInput
                      defaultValue={config[key] === true ? 1 : config[key]}
                      placeHolder={'Enter hours'}
                      onChange={(e) => handleChange(e.target.value, key)}
                    />
                  </FormField>
                </>
              ) : key === 'consecutiveBookingsCoolOff' ? (
                <>
                  {!config['consecutiveBookingsEnabled'] && (
                    <>
                      <Label>Min time between bookings (minutes)</Label>
                      <FormField className={classes.input}>
                        <NumberInput
                          defaultValue={config[key]}
                          placeHolder={'Enter hours'}
                          onChange={(e) => handleChange(e.target.value, key)}
                        />
                      </FormField>
                    </>
                  )}
                </>
              ) : (
                <CheckBox
                  key={config.classType + '-' + key}
                  label={optionLabels[key]}
                  toggle={true}
                  checked={config[key]}
                  className={classes.checkbox}
                  onChange={(e) => handleChange(e.target.checked, key)}
                />
              )}
            </>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ClassConfig;
