import React from 'react';

import Heading from 'grommet/components/Heading';

import ssRound from '../../brandConfig/logo-icons/brand-round.png';
import classes from './ThankYouScreen.module.scss';

const ThankYouScreen = (props) => (
  <div
    className={classes.thankyouContainer}
    style={props.small ? { height: '180px', minHeight: 'auto', textAlign: 'left' } : null}>
    <div className={classes.textIconContainer}>
      {!props.small ? <img src={ssRound} alt="success face" className={classes.ssRound} /> : null}

      <div className={classes.textContainer}>
        <Heading tag="h2" className={classes.thankyouHeader}>
          {props.header}
        </Heading>
        <p className={classes.thankyouParagraph}>{props.subheader}</p>
      </div>
    </div>
  </div>
);
export default ThankYouScreen;
