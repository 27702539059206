import React, { Component } from 'react';
import Button from 'grommet/components/Button';
import axios from '../../axios-global';
import classes from './KitList.module.scss';
import Modal from 'react-modal';
import TextInput from 'grommet/components/TextInput';
import { connect } from 'react-redux';
import Toast from 'grommet/components/Toast';
import { returnErrorFromResponse } from '../../shared/utility';
import Spinning from 'grommet/components/icons/Spinning';
import Notification from 'grommet/components/Notification';

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym,
    userData: state.auth.userData
  };
};

class KitList extends Component {
  state = {
    showEditableKitList: false,
    showAddNewToKitList: false,

    gymKitList: [],
    kitList: [],
    newKit: '',

    error: null,
    loading: true,
    toastShow: false,
    toastMessage: null,
    toastStatus: null
  };

  componentDidMount() {
    if (this.props.editableView) {
      axios
        .get(`/kit-list`)
        .then((response) => {
          this.setState({
            loading: false,
            gymKitList: this.props.selectedGym.kitList ? this.props.selectedGym.kitList : [],
            kitList: this.props.selectedGym.kitList.concat(
              this.getAllKits(response.data, this.props.selectedGym.kitList).map((elem) => ({
                ...elem,
                quantity: 0
              }))
            )
          });
        })
        .catch((err) =>
          this.setState({
            loading: false,
            error: err,
            toastShow: true,
            toastMessage: 'Something went wrong. Please try again',
            toastStatus: 'critical'
          })
        );
    } else {
      this.setState({ loading: false });
    }
  }

  getAllKits = (all, existingKitList) => all.filter((o) => !existingKitList.find((x) => x._id === o._id));

  increase = (index, kitList) => {
    let editedKitList = this.state[kitList];
    editedKitList[index].quantity = editedKitList[index].quantity + 1;
    this.setState({ [kitList]: editedKitList });
  };

  closeToast = () =>
    this.setState({
      toastShow: false,
      toastMessage: null,
      toastStatus: null
    });

  decrease = (index, kitList) => {
    let editedKitList = this.state[kitList];
    editedKitList[index].quantity = editedKitList[index].quantity - 1 >= 0 ? editedKitList[index].quantity - 1 : 0;
    this.setState({ [kitList]: editedKitList });
  };

  singleKit = (singleKit, index, editMode, kitListName) => (
    <div className={classes.singleKitBox} key={singleKit._id}>
      {editMode ? (
        <div className={classes.singleKit}>
          <Button
            label="-"
            secondary={false}
            className={classes.quantityButton}
            onClick={() => this.decrease(index, kitListName)}
          />
          <div className={classes.quantityValue}>{singleKit.quantity}</div>
          <Button
            label="+"
            secondary={true}
            className={classes.quantityButton}
            onClick={() => this.increase(index, kitListName)}
          />
        </div>
      ) : (
        singleKit.quantity + ' - '
      )}
      <div className={classes.kitName}>{singleKit.name}</div>
    </div>
  );

  saveKitList = () => {
    const kitList = this.state.kitList.filter((elem) => elem.quantity !== 0);

    axios
      .patch(`/kit-list/add/gym/${this.props.selectedGym._id}`, { kitList })
      .then((response) =>
        this.setState({
          gymKitList: response.data,
          showEditableKitList: false,
          toastShow: true,
          toastMessage: 'Successfully updated',
          toastStatus: 'ok'
        })
      )
      .catch(() =>
        this.setState({
          toastShow: true,
          toastMessage: 'Something went wrong. Please try again',
          toastStatus: 'critical'
        })
      );
  };

  saveNewKit = () => {
    axios
      .post(`/kit-list`, { name: this.state.newKit })
      .then((response) => {
        let newKitList = this.state.kitList;
        newKitList.push({ ...response.data, quantity: 0 });
        this.setState({
          kitList: newKitList,
          showAddNewToKitList: false,
          newKit: '',
          toastShow: true,
          toastMessage: 'Successfully added to the list',
          toastStatus: 'ok'
        });
      })
      .catch((err) =>
        this.setState({
          error: err,
          toastShow: true,
          toastMessage: returnErrorFromResponse(err),
          toastStatus: 'critical'
        })
      );
  };

  renderToast = () => {
    if (this.state.toastShow) {
      return (
        <Toast status={this.state.toastStatus} onClose={this.closeToast}>
          {this.state.toastMessage}
        </Toast>
      );
    }
  };

  render() {
    if (this.state.loading) {
      return <Spinning />;
    }

    if (this.state.error) {
      return <Notification message="Something went wrong, please try again" status="critical" />;
    }

    if (!this.state.loading && !this.props.editableView) {
      return (
        <div>
          {this.props.selectedGym ? (
            this.props.selectedGym.kitList.length > 0 ? (
              this.props.selectedGym.kitList.map((elem, index) => this.singleKit(elem, index, false, 'kitList'))
            ) : (
              <h4>Sorry, kit list is not available yet.</h4>
            )
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          {this.renderToast()}
          {this.state.showEditableKitList && (
            <Button
              label={'Add new kit to list'}
              secondary={true}
              style={{ float: 'right' }}
              onClick={() => this.setState({ showAddNewToKitList: true })}
            />
          )}
          <div style={{ display: 'flex' }}>
            <Button
              label={this.state.showEditableKitList ? 'Save kit list' : 'Edit kit list'}
              secondary={true}
              onClick={() =>
                this.state.showEditableKitList ? this.saveKitList() : this.setState({ showEditableKitList: true })
              }
            />
            {this.state.showEditableKitList && (
              <div>
                <Button
                  label={'Cancel'}
                  secondary={false}
                  onClick={() => this.setState({ showEditableKitList: false })}
                />
              </div>
            )}
          </div>
          <Modal
            isOpen={this.state.showAddNewToKitList}
            onRequestClose={() => this.setState({ showAddNewToKitList: false })}
            className="ss-modal">
            <div className={classes.modal}>
              <h3 style={{ margin: '5px' }}>Please enter new kit</h3>
              <TextInput
                name="newKit"
                value={this.state.newKit}
                onDOMChange={(e) => this.setState({ newKit: e.target.value })}
              />
              <Button label={'Save'} secondary={true} onClick={() => this.saveNewKit()} />
            </div>
          </Modal>
          {this.state.showEditableKitList
            ? this.state.kitList.map((elem, index) => this.singleKit(elem, index, true, 'kitList'))
            : this.state.gymKitList.map((elem, index) =>
                this.singleKit(elem, index, this.state.showEditableKitList, 'gymKitList')
              )}
        </div>
      );
    }
  }
}

export default connect(mapStateToProps)(KitList);
