import React from 'react';

import Status from 'grommet/components/icons/Status';

import moment from 'moment';

import classes from './ProfilePlanStatus.module.scss';

const ProfilePlanStatus = (props) => {
  if (!props.currentMembershipPlan || !props.currentMembershipPlan.current_period_end) {
    return (
      <>
        <Status className={classes.icon} value="critical" />
        Inactive
      </>
    );
  }

  if (
    props.currentMembershipPlan.current_period_end &&
    moment() > moment(props.currentMembershipPlan.current_period_end)
  ) {
    return (
      <>
        <Status className={classes.icon} value="warning" />
        Expired plan: {props.currentMembershipPlan.planName}
      </>
    );
  }

  if (
    props.currentMembershipPlan.current_period_end &&
    moment() < moment(props.currentMembershipPlan.current_period_end)
  ) {
    return (
      <>
        <div>
          <Status className={classes.icon} value="ok" />
          <span>
            {' '}
            {props.currentMembershipPlan.planName} Membership renews on:{' '}
            {moment(props.currentMembershipPlan.current_period_end).format('Do MMM h:mm A')}
          </span>
        </div>
      </>
    );
  }

  return null;
};
export default ProfilePlanStatus;
