import React from 'react';
import { withRouter } from 'react-router-dom';
import Table from 'grommet/components/Table';
import TableRow from 'grommet/components/TableRow';
import { TableHeader } from 'grommet';
import classes from './SingleDay.module.scss';
import { hasClassStarted, returnClassesDetails } from '../../../../shared/classFunctions';
import moment from 'moment';
import { returnClientDetails } from '../../../../shared/gymFunctions';
import ClassActionButtons from '../../../ActionButtons/ClassActionButtons/ClassActionButtons';
import { getCurrencySymbol } from '../../../../shared/utility';
import Notification from 'grommet/components/Notification';
import Spinning from 'grommet/components/icons/Spinning';
import { returnTimetableData } from './timetableFunctions';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';

const DefaultTimetable = ({
  classesList,
  classesListError,
  classesListLoading,
  userData,
  classType,
  classConfig,
  clientsList,
  showAllGymsData
}) => {
  if (classesListLoading) {
    return <Spinning />;
  }

  if (classesListError) {
    return <Notification message="Something went wrong. Please try again." status="critical" />;
  }

  if (classesList && classesList.length > 0) {
    const timetableData = returnTimetableData(classType, userData.role, classConfig, showAllGymsData);
    const tableLabels = timetableData.map((elem) => elem.title);
    const classesDetails = returnClassesDetails(classType);
    const renderRows = () => {
      const cancelledClass = {
        background: '#fcc9c0'
      };
      const classStarted = {
        background: '#dddddd'
      };

      return classesList.map((elem) => {
        const returnAttendeesNames = ({ attendanceList, joinedUsers, limit }, showNames = false) =>
          showNames ? (
            attendanceList.map((attendance) => {
              const attendee = returnClientDetails(attendance.user, clientsList);
              if (
                attendance.status === 'active' ||
                attendance.status === 'absent' ||
                attendance.status === 'attended'
              ) {
                return (
                  <div key={attendance._id}>
                    {attendee.name} {attendee.last_name}
                  </div>
                );
              } else {
                return '';
              }
            })
          ) : (
            <div>
              {joinedUsers} / {limit}
            </div>
          );

        return (
          <TableRow
            key={elem._id}
            style={elem.active ? (hasClassStarted(elem, 15) ? classStarted : {}) : cancelledClass}
            data-cy="classList">
            {timetableData.map(({ value, showNames = false, additionalDescription }) => {
              if (value === 'classTime') {
                return (
                  <td key={value} className={classes.nowrap}>
                    {moment(elem.classTime, 'hh:mm a').format('hh:mm a')}
                  </td>
                );
              }
              if (value === 'classDate') {
                return <td key={value}>{moment(elem.classDate, 'YYYY-MM-DD').format('DD/MM')}</td>;
              }
              if (value === 'cost') {
                return (
                  <td key={value} className={classes.nowrap}>{`${getCurrencySymbol(elem.currency)} ${elem.cost}`}</td>
                );
              }
              if (value === 'trainer') {
                if (elem.trainer) {
                  return (
                    <td key={value}>
                      {elem.trainer.name} {elem.trainer.last_name}
                    </td>
                  );
                }
                return <td key={value}></td>;
              }
              if (value === 'attendanceList') {
                return <td key={value}>{returnAttendeesNames(elem, showNames)}</td>;
              }
              if (value === 'gymName') {
                return <td key={value}>{elem.gymName[0]}</td>;
              }
              if (value === 'actionColumn') {
                return (
                  <td className={classes.actionColumn} key={value}>
                    <ClassActionButtons selectedClass={elem} classesDetails={classesDetails} />
                  </td>
                );
              }

              return (
                <td key={value}>
                  {elem[value]} {additionalDescription}
                </td>
              );
            })}
          </TableRow>
        );
      });
    };

    return (
      <div className={classes.root}>
        <Table responsive={false}>
          <TableHeader labels={tableLabels} />
          <tbody>{renderRows()}</tbody>
        </Table>
      </div>
    );
  }
  return <h3>No bookings available</h3>;
};

export default withRouter(withAdminDashboard(DefaultTimetable));
