import React from 'react';
import GymList from '../GymList/GymList';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';

import classes from './AdminSelectGym.module.scss';

export default ({ gymsList, adminFullAccess, history }) => {
  if (gymsList.length === 1) {
    history.push(`/gym/${gymsList[0]._id}`);
    return <div></div>;
  } else {
    return (
      <WhiteBackground>
        <div className={classes.dashboardContainer}>
          <GymList
            history={history}
            adminFullAccess={adminFullAccess}
            header="Manage gyms"
            gymSelected={(id) => history.push(`gym/${id}`)}
            gymsList={gymsList}
          />
        </div>
      </WhiteBackground>
    );
  }
};
