import React, { Component } from 'react';
import withManageAdmins from '../../../../store/hoc/withManageAdmins';
import withUser from '../../../../store/hoc/withUser';

import Modal from 'react-modal';
import ListItem from 'grommet/components/ListItem';
import Toast from 'grommet/components/Toast';

import Icons from '../../../../components/UI/Icons/Icons';
import AdminsAddEditAdmin from '../AddEditAdmin/AddEditAdmin';

import classes from './SingleAdmin.module.scss';
import { isFullAccessAdminOrTrainer } from '../../../../shared/userFunctions';

export class SingleAdmin extends Component {
  state = {
    showConfirm: false,
    showModal: false
  };

  handleAdminDelete = () => {
    if (!this.state.showConfirm) {
      return this.setState({
        showConfirm: true
      });
    }
    this.props.deleteAdmin();
  };

  handleAdminEdit = () => {
    this.setState({
      showModal: true
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false
    });
  };

  closeToast = () => {
    this.setState({
      showConfirm: false
    });
  };

  renderNotification = () => {
    if (this.state.showConfirm) {
      return (
        <Toast status="warning" onClose={this.closeToast}>
          Click once again to confirm the deletion of the admin in all gyms
        </Toast>
      );
    }
  };

  render() {
    return (
      <>
        {this.renderNotification()}

        <Modal onRequestClose={this.handleModalClose} isOpen={this.state.showModal} className="ss-modal">
          <div className="grommet">
            <AdminsAddEditAdmin
              hasFullAccess={this.props.hasFullAccess}
              editAdmin={this.props.singleAdmin}
              gymsList={this.props.gymsList}
            />
          </div>
        </Modal>

        <ListItem direction="column" align="start" separator="horizontal">
          <div className={classes.singleAdmin}>
            <div className={classes.adminStatus}>
              <span className={classes.adminName}>
                {this.props.singleAdmin.name} {this.props.singleAdmin.last_name}{' '}
                {isFullAccessAdminOrTrainer(this.props.singleAdmin) ? (
                  <span className={classes.gymAccess}> - Full access</span>
                ) : null}
              </span>
            </div>

            {this.props.adminId !== this.props.singleAdmin._id && (
              <Icons
                edit
                delete={this.props.hasFullAccess}
                editClick={this.handleAdminEdit}
                deleteClick={this.handleAdminDelete}
              />
            )}
          </div>
        </ListItem>
      </>
    );
  }
}

export default withUser(withManageAdmins(SingleAdmin));
