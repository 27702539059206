import React from 'react';
import QrCode from 'qrcode.react';
import Title from '../../../components/UI/Title/Title';

export default (props) => {
  if (props.isActive) {
    return (
      <>
        <Title header="Your QR code" subHeader="Scan the code below and get ready for a great workout" />

        <QrCode value={props.id} style={{ margin: '0 auto', display: 'block' }} size={200} />
      </>
    );
  } else {
    return (
      <>
        <Title header="Your QR code is inactive" subHeader="Please update your membership" />
      </>
    );
  }
};
