import React, { useState } from 'react';
import FormField from 'grommet/components/FormField';

import axios from '../../../axios-global';

const ClientProfilePaymentNotes = (props) => {
  const [paymentNotes, setPaymentNotes] = useState(props.selectedClient.paymentNotes);

  const updatePaymentNotes = (event) => setPaymentNotes(event.target.value);

  const saveNotes = async () => {
    try {
      await axios.patch(`/users/${props.selectedClient._id}`, {
        paymentNotes
      });
      props.onSuccessfulSave();
    } catch (error) {
      props.onFailedSave(error);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      {props.isAdmin && (
        <FormField label="Payment notes">
          <textarea value={paymentNotes} onChange={updatePaymentNotes} onBlur={saveNotes} />
        </FormField>
      )}
    </div>
  );
};
export default ClientProfilePaymentNotes;
