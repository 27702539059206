import React, { useState } from 'react';

import { List, ListItem, Button, Notification } from 'grommet';
import SinglePass from './SinglePass/SinglePass';
import { useQuery } from 'react-query';
import { fetchPasses } from '../../apiFunctions/apiFunctions';
import { returnClassesDetails } from '../../shared/classFunctions';
import withUser from '../../store/hoc/withUser';
import Title from '../../components/UI/Title/Title';
import AddEditPass from './AddEditPass/AddEditPass';
import Loading from '../../components/Loading/Loading';
import { withRouter } from 'react-router-dom';

const ManagePasses = ({ classType, userData, selectedGym, location, history }) => {
  const [showAddEditPass, setShowAddEditPass] = useState(false);
  const [selectedPass, setSelectedPass] = useState(null);
  const gymId = userData.role === 'user' ? userData.gymId : selectedGym._id;
  const { data, isLoading, error } = useQuery(['passes', classType], () => fetchPasses(classType, gymId));
  const classesDetails = returnClassesDetails(classType);
  const goBackToPreviousPage = () =>
    location && location.state && location.state.goBackToPreviousPage ? history.goBack() : null;

  if (isLoading) {
    return <Loading size={'medium'} />;
  }
  if (error) {
    return <Notification message={error} status="critical" />;
  }

  const addNewPassButton = userData.role === 'admin' && (
    <Button
      data-cy="add"
      style={{ margin: '0px 0px 0px auto' }}
      label={'Add'}
      secondary={true}
      onClick={() => setShowAddEditPass(true)}
    />
  );
  const title = userData.role === 'user' && (
    <Title
      header={classesDetails.title + ' Passes'}
      subHeader="Please purchase your personal training sessions below and contact your trainer to book your session."
    />
  );
  const returnPasses =
    data && data.length > 0 ? (
      <List>
        {data.map((classPass) => (
          <ListItem
            key={classPass._id}
            direction="column"
            align="start"
            separator="horizontal"
            style={!classPass.active ? { background: 'lightGrey' } : {}}>
            <SinglePass
              classPass={classPass}
              classType={classType}
              editPass={(classPass) => {
                setSelectedPass(classPass);
                setShowAddEditPass(true);
              }}
              goBackToPreviousPage={goBackToPreviousPage}
            />
          </ListItem>
        ))}
      </List>
    ) : (
      <h3>No passes</h3>
    );

  if (showAddEditPass) {
    return <AddEditPass classType={classType} selectedPass={selectedPass} onClose={() => setShowAddEditPass(false)} />;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {title}
        {addNewPassButton}
      </div>
      {returnPasses}
    </>
  );
};

export default withUser(withRouter(ManagePasses));
