import React from 'react';
import { withRouter } from 'react-router-dom';
import withGymsUsersFullData from '../../../../store/hoc/withGymsUsersFullData';
import Notification from 'grommet/components/Notification';
import UserDetailsLink from '../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import { CLASS_LEFT_NAMES } from '../../../../constants';

const styles = {
  display: 'flex',
  margin: '10px 0px'
};

const ClientListWithErrorData = ({ gymsUsersErrorData, isAdmin = false }) => {
  const returnErrorData = (user) => {
    const result = CLASS_LEFT_NAMES.filter((name) => {
      if (user[name] === 'error') {
        return name;
      }
      return false;
    });

    return result.toString();
  };

  const message = gymsUsersErrorData ? (
    <div>
      Problem with customer account data:
      {gymsUsersErrorData.map((user) => (
        <div style={styles} key={user._id}>
          <UserDetailsLink gymId={{ _id: user.gymId }} clientId={user._id} />
          <div style={{ margin: 'auto 0px', fontSize: '0.7em' }}>
            {user.name} {user.last_name} - {returnErrorData(user)}
          </div>
        </div>
      ))}
    </div>
  ) : null;

  if (isAdmin && gymsUsersErrorData && gymsUsersErrorData.length > 0) {
    return <Notification message={message} status="critical" />;
  }
  return null;
};
export default withGymsUsersFullData(withRouter(ClientListWithErrorData));
