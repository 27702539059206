import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { StripeProvider } from 'react-stripe-elements';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import axios from '../../axios-global';
import moment from 'moment';
import Title from '../../components/UI/Title/Title';

import Button from 'grommet/components/Button';
import Spinning from 'grommet/components/icons/Spinning';
import Modal from 'react-modal';
import Login from '../Login/Login';
import * as actions from '../../store/actions';
import Toast from 'grommet/components/Toast';
import classes from './publicClassPage.module.scss';
import Notification from 'grommet/components/Notification';
import { companyDetails } from '../../../src/brandConfig/companyDetails';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token,
    userData: state.auth.userData,

    gym: state.admin.selectedGym,
    gymDataLoading: state.admin.gymDataLoading,
    gymDataError: state.admin.gymDataError,

    sourcesList: state.client.sourcesList,
    sourcesListLoading: state.client.sourcesListLoading,
    sourcesListError: state.client.sourcesListError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUserDetails: () => dispatch(actions.fetchUserDetails()),
    onAuthCheckStatus: () => dispatch(actions.authCheckStatus()),
    onFetchSources: () => dispatch(actions.fetchSources()),
    onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId))
  };
};

class PublicClassPage extends React.Component {
  state = {
    loading: true,
    error: null,

    classDetails: null,
    bookedClasses: 0,
    userAttendance: null,

    showModal: false,
    showButton: true,

    toastShow: false,
    toastMessage: null,
    toastStatus: null
  };

  componentDidMount() {
    this.props.onAuthCheckStatus();
    this.props.onFetchUserDetails();
    this.props.onFetchSources();

    axios
      .get(`/class/public/${this.props.match.params.classId}`)
      .then((classDetails) => {
        this.props.onFetchGymDetails(classDetails.data.gymId._id);

        let userAttendance = this.props.userData
          ? classDetails.data.attendanceList.filter(
              (element) =>
                element.user === this.props.userData._id &&
                (element.status === 'pending' || element.status === 'active')
            )
          : [];

        this.setState({
          classDetails: classDetails.data,
          bookedClasses: classDetails.data.attendanceList.filter((element) => element.status === 'active').length,
          userAttendance: userAttendance,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.data
        });
      });
  }

  renderToast = () => {
    if (this.state.toastShow) {
      return (
        <Toast status={this.state.toastStatus} onClose={this.closeToast}>
          {this.state.toastMessage}
        </Toast>
      );
    }
  };

  closeToast = () => {
    this.setState({
      toastShow: false,
      toastMessage: null,
      toastStatus: null
    });
  };

  bookClassFunction = () => {
    if (this.props.userData) {
      axios
        .post(`/class/${this.props.match.params.classId}`, {
          userId: this.props.userData._id
        })
        .then(() => {
          this.setState({
            toastShow: true,
            toastMessage:
              this.state.classDetails.cost === 0
                ? 'Successfully added to this class.'
                : 'Successfully added to this class. Please pay for attendance.',
            toastStatus: 'ok'
          });
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch(() => () => {
          this.setState({
            toastShow: true,
            toastMessage: 'Something went wrong. Please try again',
            toastStatus: 'critical'
          });
        });
    } else {
      this.setState({
        showModal: true
      });
    }
  };

  payForClass = () => {
    axios
      .post(`/attendance/${this.state.userAttendance[0]._id}/payment`, {
        stripeOrderId: this.state.userAttendance[0].stripeOrderId,
        method: 'web'
      })
      .then(() => {
        this.setState({
          toastShow: true,
          toastMessage: 'Successfully booked and paid.',
          toastStatus: 'ok'
        });
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(() => {
        this.setState({
          toastShow: true,
          toastMessage: 'Something went wrong. Please try again',
          toastStatus: 'critical'
        });
      });
  };

  cancelAttendance = () => {
    axios
      .patch(`/attendance/${this.state.userAttendance[0]._id}/cancel`, {
        stripeOrderId: this.state.userAttendance[0].stripeOrderId
      })
      .then(() => {
        this.setState({
          toastShow: true,
          toastMessage: 'Attendance successfully cancelled',
          toastStatus: 'ok'
        });
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(() => {
        this.setState({
          toastShow: true,
          toastMessage: 'Something went wrong. Please try again',
          toastStatus: 'critical'
        });
      });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  renderButton = () => {
    if (this.state.bookedClasses < this.state.classDetails.limit) {
      if (this.props.userData !== null) {
        if (this.props.userData.gymId === this.state.classDetails.gymId._id) {
          if (this.props.userData.status === 'active') {
            if (this.props.sourcesList.length > 0 || this.state.classDetails.cost === 0) {
              if (!this.props.userData.joiningFeePaid && this.props.userData.customerType === 'full-member') {
                return (
                  <>
                    <h3> To get access to this gym you have to pay joining fee and subscribe to membership plan</h3>
                    <Button
                      style={{ minWidth: '300px' }}
                      label="Subscribe to membership plan"
                      onClick={() =>
                        this.props.history.push({
                          pathname: '/gym',
                          state: { currentUrl: `${this.props.match.url}` }
                        })
                      }
                    />
                  </>
                );
              }

              if (this.state.userAttendance.length > 0) {
                return this.state.userAttendance[0].status === 'pending' ? (
                  <Button style={{ minWidth: '300px' }} label="Pay" onClick={this.payForClass} />
                ) : (
                  <>
                    <h3 style={{ color: '#80B91E' }}>You have already joined this class</h3>
                    <p>
                      {' '}
                      {this.state.classDetails.cost === 0
                        ? null
                        : 'You can cancel your attendance but you can be charged if it is late cancellation'}{' '}
                    </p>
                    <Button style={{ minWidth: '300px' }} label="Cancel attendance" onClick={this.cancelAttendance} />
                  </>
                );
              }

              return <Button style={{ minWidth: '300px' }} label="Book class" onClick={this.bookClassFunction} />;
            }
            return (
              <>
                <h3> Before you join class you need to add card details</h3>
                <Button
                  style={{ minWidth: '300px' }}
                  label="Add card details"
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/gym/payment-methods`,
                      state: { currentUrl: `${this.props.match.url}` }
                    })
                  }
                />
              </>
            );
          }
          return (
            <Notification
              message={
                this.props.userData.status === 'waitingForApproval'
                  ? 'Your account has to be approved by admin.'
                  : `Your account is inactive. Please contact ${companyDetails.name} Team`
              }
              status={this.props.userData.status === 'waitingForApproval' ? 'warning' : 'critical'}
              className="ss-top-notification"
            />
          );
        }
        return <h3>This is not your home gym. You can't join this class </h3>;
      }
      return <Button style={{ minWidth: '300px' }} label="Book class" onClick={this.bookClassFunction} />;
    }
    return <h3> Full</h3>;
  };

  render() {
    if (this.state.loading || this.props.sourcesListLoading) {
      return <Spinning />;
    }
    if (this.state.classDetails && !this.state.loading) {
      return (
        <StripeProvider
          apiKey={
            process.env.NODE_ENV === 'development'
              ? process.env.REACT_APP_STRIPE_TEST_KEY_DEV
              : process.env.REACT_APP_STRIPE_TEST_KEY_PROD
          }>
          <WhiteBackground>
            {this.renderToast()}
            <div
              style={{
                float: 'left',
                overflow: 'hidden',
                minWidth: '300px',
                marginLeft: '40px',
                marginRight: '100px'
              }}>
              <Title header="Class" />
              <p>
                {' '}
                Date: {moment(this.state.classDetails.classDate).format('Do MMM')} <br />
                Time: {this.state.classDetails.classTime} <br />
                Duration: {this.state.classDetails.duration} minutes <br />
                Trainer: {this.state.classDetails.trainer.name} {this.state.classDetails.trainer.last_name}
                <br />
                Cost: {this.state.classDetails.cost} <span>&#163;</span>
                <br />
                Attendees: {this.state.bookedClasses} / {this.state.classDetails.limit} <br />
                Type: {this.state.classDetails.__t} <br />
                {this.state.classDetails.private ? 'Private class' : null}
              </p>
              <h5>Gym Details</h5>
              <p>
                {' '}
                Name: {this.state.classDetails.gymId.name} <br />
                Address: {this.state.classDetails.gymId.address} <br />
                City: {this.state.classDetails.gymId.city} minutes <br />
              </p>
              {this.renderButton()}
            </div>
            <Modal onRequestClose={this.closeModal} isOpen={this.state.showModal} className={classes.modalContainer}>
              <div className="container">
                <Login />
                <br />
                <h3> If you don't have an account please sign up</h3>

                <Button
                  label="Sign up"
                  style={{ maxWidth: 'none', width: '100%' }}
                  secondary={false}
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/gym/${this.state.classDetails.gymId._id}/signup`,
                      state: { currentUrl: `${this.props.match.url}` }
                    })
                  }
                />
              </div>
            </Modal>
          </WhiteBackground>
        </StripeProvider>
      );
    }
    return (
      <WhiteBackground>
        <h3> Not available for booking currently </h3>
      </WhiteBackground>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicClassPage));
