import React from 'react';

import withUser from '../../../store/hoc/withUser';
import { withRouter } from 'react-router-dom';
import { returnClassConfig } from '../../../shared/gymFunctions';
import { useQuery } from 'react-query';
import { fetchUpcomingClasses } from '../../../apiFunctions/apiFunctions';
import { returnClassesDetails } from '../../../shared/classFunctions';
import UserView from './UsersView/UserView';
import AdminView from './UsersView/AdminView';

const ManageTimetable = ({ userData, selectedGym, classType }) => {
  const gymId = userData.role === 'user' ? userData.gymId : selectedGym._id;
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);
  const classesDetails = returnClassesDetails(classType);

  const {
    data: classesList,
    error: classesListError,
    isLoading: classesListLoading,
    isFetching,
    refetch: classesListReFetch
  } = useQuery(['upcomingClasses', classType], () => fetchUpcomingClasses(classType, gymId));

  if (userData.role === 'user') {
    return (
      <UserView
        classesList={classesList}
        classesListLoading={classesListLoading || isFetching}
        classesListError={classesListError}
        onFetchClasses={classesListReFetch}
        classesDetails={classesDetails}
        classConfig={classConfig}
      />
    );
  }

  return (
    <AdminView
      classType={classType}
      classesList={classesList}
      classesListLoading={classesListLoading || isFetching}
      classesListError={classesListError}
      onFetchClasses={classesListReFetch}
      classesDetails={classesDetails}
      classConfig={classConfig}
    />
  );
};

export default withRouter(withUser(ManageTimetable));
