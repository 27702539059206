import { ALL_CURRENCIES } from '../../../constants';
import { getCurrencyDetail } from '../../../shared/utility';

const unEditableFields = [];
export const returnScheduleFields = (classType, data = null) => {
  let result = {
    classType: {
      label: 'Class type',
      type: 'disabled',
      placeholder: 'Enter class type',
      value: classType,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    name: {
      label: 'Class name',
      type: 'text',
      placeholder: 'Enter class name',
      value: data !== null ? data.name : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Enter description',
      value: data !== null ? (data.defaultDescription ? data.defaultDescription : data.description) : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    location: {
      label: 'Class location',
      type: 'text',
      placeholder: 'Enter class location',
      value: data !== null ? data.location : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    currency: {
      label: 'Currency',
      type: 'select',
      options: ALL_CURRENCIES,
      placeholder: 'Select',
      value: getCurrencyDetail(data && data.currency),
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    cost: {
      label: 'Cost',
      type: 'text',
      placeholder: 'Enter cost',
      value: data !== null ? (data.defaultCost ? data.defaultCost : data.cost) : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    membershipClassPrice: {
      label: 'Membership Class Price',
      type: 'text',
      placeholder: 'Enter Membership Class price',
      value: data !== null ? data.membershipClassPrice : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    limit: {
      label: 'Limit',
      type: 'text',
      placeholder: 'Enter limit',
      value: data !== null ? (data.defaultLimit ? data.defaultLimit : data.limit) : null,
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    duration: {
      label: 'Duration (in minutes)',
      type: 'text',
      placeholder: 'Enter duration',
      value: data !== null ? (data.defaultDuration ? data.defaultDuration : data.duration) : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    minimalTimeCancellationInHours: {
      label: 'Minimal cancellation time (in hours)',
      type: 'text',
      placeholder: 'Enter time',
      value:
        data !== null
          ? data.defaultMinimalTimeCancellationInHours
            ? data.defaultMinimalTimeCancellationInHours
            : data.minimalTimeCancellationInHours
          : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    monday: {
      label: 'Monday',
      type: 'text',
      placeholder: 'Enter class time',
      value: data && data.recurrentOn ? data.recurrentOn[1] : '',
      valid: true,
      validation: {
        required: false,
        isCommaSeparatedTime: true
      },
      touched: false,
      errorText: null
    },
    tuesday: {
      label: 'Tuesday',
      type: 'text',
      placeholder: 'Enter class time',
      value: data && data.recurrentOn ? data.recurrentOn[2] : '',
      valid: true,
      validation: {
        required: false,
        isCommaSeparatedTime: true
      },
      touched: false,
      errorText: null
    },
    wednesday: {
      label: 'Wednesday',
      type: 'text',
      placeholder: 'Enter class time',
      value: data && data.recurrentOn ? data.recurrentOn[3] : '',
      valid: true,
      validation: {
        required: false,
        isCommaSeparatedTime: true
      },
      touched: false,
      errorText: null
    },
    thursday: {
      label: 'Thursday',
      type: 'text',
      placeholder: 'Enter class time',
      value: data && data.recurrentOn ? data.recurrentOn[4] : '',
      valid: true,
      validation: {
        required: false,
        isCommaSeparatedTime: true
      },
      touched: false,
      errorText: null
    },
    friday: {
      label: 'Friday',
      type: 'text',
      placeholder: 'Enter class time',
      value: data && data.recurrentOn ? data.recurrentOn[5] : '',
      valid: true,
      validation: {
        required: false,
        isCommaSeparatedTime: true
      },
      touched: false,
      errorText: null
    },
    saturday: {
      label: 'Saturday',
      type: 'text',
      placeholder: 'Enter class time',
      value: data && data.recurrentOn ? data.recurrentOn[6] : '',
      valid: true,
      validation: {
        required: false,
        isCommaSeparatedTime: true
      },
      touched: false,
      errorText: null
    },
    sunday: {
      label: 'Sunday',
      type: 'text',
      placeholder: 'Enter class time',
      value: data && data.recurrentOn ? data.recurrentOn[0] : '',
      valid: true,
      validation: {
        required: false,
        isCommaSeparatedTime: true
      },
      touched: false,
      errorText: null
    },
    private: {
      label: 'Private',
      type: 'select',
      options: ['yes', 'no'],
      placeholder: 'Select',
      value: data && data.private ? (data.private ? 'yes' : 'no') : 'no',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    enabledForScheduling: {
      label: 'Enabled for scheduling',
      type: 'select',
      options: ['yes', 'no'],
      placeholder: 'Select',
      value: data && data.enabledForScheduling ? (data.enabledForScheduling ? 'yes' : 'no') : 'yes',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    }
  };

  if (
    classType === 'gymClass' ||
    classType === 'swimmingClass' ||
    classType === 'tennisClass' ||
    classType === 'massageClass'
  ) {
    delete result.name;
    delete result.description;
    delete result.location;
  }

  if (data && unEditableFields) {
    unEditableFields.forEach((elem) => delete result[elem]);
  }

  return result;
};
