import React from 'react';
import Accordion from 'grommet/components/Accordion';
import AccordionPanel from 'grommet/components/AccordionPanel';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';

export default function ClientProfileMoreDetails(props) {
  return (
    <>
      <Accordion>
        <AccordionPanel heading="More details">
          <ProfileTextWithLabel label={'Date of birth'} text={`${props.selectedClient.dateofbirth.substring(0, 10)}`} />

          <ProfileTextWithLabel label={'Address'} text={`${props.selectedClient.address_line1}`} />
          <ProfileTextWithLabel label={'City'} text={`${props.selectedClient.city}`} />
          <ProfileTextWithLabel label={'Postcode'} text={`${props.selectedClient.postcode}`} />
          <ProfileTextWithLabel
            label={'Emergency contact name'}
            text={`${props.selectedClient.emergency_contact_name}`}
          />
          <ProfileTextWithLabel
            label={'Emergency contact number'}
            text={`${props.selectedClient.emergency_contact_number}`}
          />
        </AccordionPanel>
      </Accordion>
    </>
  );
}
