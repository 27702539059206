import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import classes from './AddEditVideo.module.scss';
import Hls from 'hls.js';

const cloneDeep = require('lodash.clonedeep');

const UploadVideo = (props) => {
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileUploadDate, setFileUploadDate] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [disableChooseFileButton, setDisableChooseFileButton] = useState(false);
  const player = useRef(null);

  useEffect(() => {
    if (props.video) {
      const checkHlsIsSupported = () => {
        if (!Hls.isSupported()) {
          alert('Your browser does not support HLS so you will not be able to view our videos');
        }
      };
      const startVideoPlayer = () => {
        const hls = new Hls();
        Hls.DefaultConfig.capLevelToPlayerSize = true;
        const hlsMedia = props.video.media.filter((media) => media.type === 'HLS')[0];
        hls.loadSource(hlsMedia.playlistUrl);
        hls.attachMedia(player.current);
      };

      const showExistingData = () => {
        setFileName(props.video.file.name);
        setFileType(props.video.file.type);
        setFileSize(`${props.video.file.size} MB`);
        setFileUploadDate(props.video.createdDate);
      };

      checkHlsIsSupported();
      startVideoPlayer();
      showExistingData();
      setEditMode(true);
    }
  }, [props.video]);

  const uploadVideo = async (event) => {
    const files = Array.from(event.target.files);

    const formData = new FormData();

    formData.append('video', files[0]);
    setFileName(files[0].name);
    setFileType(files[0].type);
    const size = `${(files[0].size / 1000000).toFixed(1)}`;
    setFileSize(size + ' MB');
    const uploadDate = new Date();
    setFileUploadDate(uploadDate);
    const clonedFileData = cloneDeep(props.state.file);
    clonedFileData.name = files[0].name;
    clonedFileData.type = files[0].type;
    clonedFileData.size = `${size}`;
    clonedFileData.createdDate = uploadDate;
    props.state.file = clonedFileData;
    setDisableChooseFileButton(true);

    await props.uploadVideo(formData);
  };

  const getFormattedDate = () => {
    let formattedDate = '';
    if (fileUploadDate !== '') {
      formattedDate = moment(fileUploadDate).format('Do MMMM YYYY, h:mm:ss A');
    }
    return formattedDate;
  };

  return (
    <>
      <div className={classes.videoDetailContainer}>
        <h4>Video File Data</h4>
        {props.video && (
          <div>
            <video controls className={classes.videoContainer} ref={player} />
          </div>
        )}
        <div>
          <span className={classes.videoDetailLabel}>Name:</span> {fileName}
        </div>

        <div>
          <span className={classes.videoDetailLabel}>Type:</span> {fileType}
        </div>

        <div>
          <span className={classes.videoDetailLabel}>Size:</span> {fileSize}
        </div>

        <div>
          <span className={classes.videoDetailLabel}>Uploader:</span> Erimus fitness
        </div>

        <div>
          <span className={classes.videoDetailLabel}>Uploaded:</span> {getFormattedDate()}
        </div>
      </div>
      {!editMode && (
        <div className={classes.videoUploadContainer}>
          <label disabled={disableChooseFileButton} htmlFor="single" className={classes.chooseFileButton}>
            Choose File
          </label>
          <input
            disabled={disableChooseFileButton}
            type="file"
            name="video"
            accept="video/*"
            id="single"
            onChange={uploadVideo}
          />
        </div>
      )}
    </>
  );
};
export default UploadVideo;
