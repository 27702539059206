import React, { useState, useEffect } from 'react';
import classes from './WeekView.module.scss';
import moment from 'moment';
import UserIcon from 'grommet/components/icons/base/User';

const WeekView = ({ timetable, openDayInModal }) => {
  const [weekDays, setWeekDays] = useState(moment.weekdaysShort());

  useEffect(
    () => {
      let tmpWeekDays = weekDays;
      tmpWeekDays.push(tmpWeekDays.shift());
      setWeekDays(tmpWeekDays);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const viewDay = (leader, classes) => {
    if (classes.length) {
      openDayInModal({ leader, classes });
    }
  };

  const renderCell = (rowIndex, cellIndex, day, event) => {
    const thisDayEvents = event.classes.filter((evt) => moment(evt.date, 'YYYY-MM-DD').format('ddd') === day);
    return (
      <td
        key={`${rowIndex}_${cellIndex}`}
        className={classes.eventCell}
        onClick={() => viewDay(event.leader, thisDayEvents)}>
        {thisDayEvents.map((dayEvent, index) => (
          <p
            className={`
                                ${classes.event}
                                ${dayEvent.type === 'beth_interview' ? classes.bethInterview : ''}
                            `}
            key={`${index}_${rowIndex}_${cellIndex}`}></p>
        ))}
      </td>
    );
  };

  const renderRow = (event, rowIndex) => (
    <tr key={rowIndex}>
      <td className={classes.titleColumn}>
        <UserIcon className={classes.xsHidden} />{' '}
        <span className={classes.xsOnly}>
          {event.leader.name.charAt(0)} {event.leader.last_name}
        </span>
        <span className={classes.xsHidden}>
          {event.leader.name} {event.leader.last_name}
        </span>
      </td>
      {weekDays.map((day, index) => renderCell(rowIndex, index, day, event))}
    </tr>
  );

  return (
    <>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={`${classes.weekNumber} ${classes.personColumn} ${classes.titleColumn}`}>
                <div className={classes.xsHidden}>Names</div>
              </th>
              {weekDays.map((day, index) => (
                <th key={index}>{window.innerWidth < 600 ? day.substring(0, 1) : day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timetable.length > 0 ? (
              timetable.map((userEvents, index) => renderRow(userEvents, index))
            ) : (
              <tr>
                <td colSpan="8">No record found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WeekView;
