import React, { useState, useEffect } from 'react';
import { Toast } from 'grommet';

const CustomToast = ({ toast }) => {
  const [showToast, setShowToast] = useState(false);
  const [msg, setMsg] = useState('Success');
  const [status, setStatus] = useState('ok');

  useEffect(() => {
    setShowToast(toast && toast.show ? toast.show : false);
    setMsg(toast && toast.msg ? toast.msg : 'Success');
    setStatus(toast && toast.status ? toast.status : 'ok');
  }, [toast]);

  if (showToast) {
    return (
      <Toast status={status} onClose={() => setShowToast(false)}>
        {msg}
      </Toast>
    );
  }
  return null;
};

export default CustomToast;
