import React from 'react';
import Heading from 'grommet/components/Heading';
import moment from 'moment';
import classes from './SinglePass.module.scss';
import { getCurrencySymbol } from '../../../shared/utility';
import withUser from '../../../store/hoc/withUser';
import RenderPassActionButtons from './RenderPassActionButtons';

const SinglePass = ({ classType, classPass, isAdmin, editPass, goBackToPreviousPage }) => {
  const { name, places, description, classCount, duration, cost, currency, oneOff, active, expiryDate } = classPass;

  const renderName = (
    <Heading tag="h4" strong={false} style={{ marginBottom: '16px' }}>
      {name}
    </Heading>
  );

  const renderLabel = (label, value) =>
    value && (
      <div>
        <span className={classes.singleClassPassLabel}>{label}:</span> {value}
      </div>
    );
  const renderDescription = <div className={classes.singleClassPassDescription}>{description}</div>;

  return (
    <div className={classes.singleClassPass} data-cy="singleClassPass">
      <div className={classes.description}>
        {renderName}
        {renderLabel('Places', places)}
        {renderDescription}
        {renderLabel('Included', classCount)}
        {renderLabel('Duration', duration)}
        {renderLabel('Price', `${getCurrencySymbol(currency)} ${cost}`)}
        {isAdmin && renderLabel('One time offer', `${oneOff ? 'Yes' : 'No'}`)}
        {isAdmin && renderLabel('Active', `${active ? 'Yes' : 'No'}`)}
        {renderLabel('Expiry date', expiryDate ? moment(expiryDate).format('DD/MM/YYYY') : 'None')}
      </div>
      <RenderPassActionButtons
        classPass={classPass}
        classType={classType}
        editPass={editPass}
        goBackToPreviousPage={goBackToPreviousPage}
      />
    </div>
  );
};
export default withUser(SinglePass);
