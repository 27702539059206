import React, { useState } from 'react';
import Spinning from 'grommet/components/icons/Spinning';
import { Notification, List, Button } from 'grommet';
import SingleFAQ from './SingleFAQ';
import classes from './manageFAQ.module.scss';
import { withRouter } from 'react-router-dom';
import { delayFetchFunctions, fetchFAQs, uploadFAQJsonFile } from '../../apiFunctions/apiFunctions';
import { useQuery, useMutation } from 'react-query';
import AddEditFAQ from './AddEditFAQ/AddEditFAQ';
import withUser from '../../store/hoc/withUser';
import { returnErrorFromResponse } from '../../shared/utility';
import CustomToast from '../../components/CustomToast/CustomToast';

const ManageFAQ = ({ isAdmin = false, selectedGym, selectedGymId }) => {
  const [showAddEditFaq, setShowAddEditFAQ] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  const [toast, setToast] = useState(null);

  const gymId = selectedGymId ? selectedGymId : selectedGym._id;

  const { data, isLoading, error } = useQuery('faq', () => fetchFAQs(gymId));

  const mutateSaveJSONFAQ = useMutation((data) => uploadFAQJsonFile(selectedGym._id, data), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'JSON file successfully uploaded.',
        show: true
      });
      delayFetchFunctions([['faq']], 0);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  const renderFAQList =
    data && data.length > 0 ? (
      <List>
        {data.map((element) => (
          <SingleFAQ
            isAdmin={isAdmin}
            key={element._id}
            singleFAQ={element}
            selectedGym={selectedGym}
            editFAQ={(element) => {
              setSelectedFAQ(element);
              setShowAddEditFAQ(true);
            }}
          />
        ))}
      </List>
    ) : (
      <h3>No FAQ</h3>
    );

  const uploadFAQfromJSON = () => {
    const uploadFileFunction = (event) => {
      let file = event.target.files[0];
      let reader = new FileReader();

      reader.onload = () => {
        let content = reader.result;
        mutateSaveJSONFAQ.mutate(JSON.parse(content));
      };
      reader.readAsText(file);
    };

    if (isAdmin) {
      return (
        <div className={classes.alignToRight}>
          <a
            href={`${process.env.PUBLIC_URL}/assets/faqExample.json`}
            download="faqExample"
            className={classes.jsonExample}>
            Get JSON template
          </a>

          <label htmlFor="file" className={classes.uploadFileButton}>
            Upload JSON
          </label>
          <input type="file" name="file" id="file" accept=".json" onChange={uploadFileFunction} />

          <Button
            label="Add new"
            secondary={true}
            onClick={() => {
              setSelectedFAQ(null);
              setShowAddEditFAQ(true);
            }}
          />
        </div>
      );
    }
    return null;
  };

  if (isLoading) {
    return <Spinning />;
  }
  if (error) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  if (showAddEditFaq) {
    return <AddEditFAQ selectedGym={selectedGym} selectedFAQ={selectedFAQ} onClose={() => setShowAddEditFAQ(false)} />;
  }
  return (
    <div className={classes.root}>
      <CustomToast toast={toast} />
      {uploadFAQfromJSON()}
      {renderFAQList}
    </div>
  );
};

export default withRouter(withUser(ManageFAQ));
