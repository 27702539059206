import React, { useEffect, useState } from 'react';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';
import NumberInput from 'grommet/components/NumberInput';
import classes from './WaitListForm.module.scss';

/**
 To fully implement form you have to add/update to main component:

 1. STATE:
        waitListConfig: false,
        waitListFormValid: true,
 2. FUNCTION:
        checkWaitList = (valid, waitListConfig) =>
            this.setState({
                waitListFormValid: valid,
                waitListConfig,
            });
 3. UPDATE FORM VALIDATION - "checkFormValidity"
        this.setState({ formIsValid: true });
        if (this.state.waitListFormValid) {
            this.addOneOffClass();
        }
 4. UPDATE ERROR NOTIFICATION:
    if (!this.state.formIsValid && this.state.formValidated || !this.state.waitListFormValid)

 5. UPDATE SAVE FUNCTION:
        oneOffClassFormData.waitListConfig = this.state.waitListConfig;
 6. UPDATE "componentDidMount" with "class.waitListConfig" - to enable editing class details
 */
const defaultValue = {
  value: 2,
  valid: true
};

export const WaitListForm = ({ waitListConfig = null, checkWaitList }) => {
  const [isActiveWaitList, setActiveWaitList] = useState(waitListConfig && waitListConfig.active ? 'yes' : 'no');
  const [
    minimalTimeBeforeClassStartsToNotifyWaitListUsers,
    setMinimalTimeBeforeClassStartsToNotifyWaitListUsers
  ] = useState(
    waitListConfig && waitListConfig.minimalTimeBeforeClassStartsToNotifyUsers
      ? {
          value: waitListConfig.minimalTimeBeforeClassStartsToNotifyUsers,
          valid: true
        }
      : defaultValue
  );
  const [timeDelayForSendingNotifications, setTimeDelayForSendingNotifications] = useState(
    waitListConfig.timeDelayForSendingNotifications
      ? {
          value: waitListConfig.timeDelayForSendingNotifications,
          valid: true
        }
      : defaultValue
  );

  const validationMsg = <p className={classes.validationMsg}>Value has to be a number greater than zero</p>;

  const returnWaitListConfig = () => {
    return {
      active: isActiveWaitList === 'yes',
      minimalTimeBeforeClassStartsToNotifyUsers: parseInt(minimalTimeBeforeClassStartsToNotifyWaitListUsers.value),
      timeDelayForSendingNotifications: parseInt(timeDelayForSendingNotifications.value)
    };
  };

  useEffect(
    () => {
      checkWaitList(
        minimalTimeBeforeClassStartsToNotifyWaitListUsers.valid && timeDelayForSendingNotifications.valid,
        returnWaitListConfig()
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [minimalTimeBeforeClassStartsToNotifyWaitListUsers, timeDelayForSendingNotifications, isActiveWaitList]
  );

  const renderWaitListConfig = () =>
    isActiveWaitList === 'yes' ? (
      <div className={classes.configContainer}>
        <h5>Waiting list configuration:</h5>
        <Label>Cut off before the start time to tell waiting list there is a space (minutes)</Label>
        <NumberInput
          value={minimalTimeBeforeClassStartsToNotifyWaitListUsers.value}
          onChange={(e) =>
            setMinimalTimeBeforeClassStartsToNotifyWaitListUsers({
              value: e.target.value,
              valid: e.target.value > 0
            })
          }
        />

        {!minimalTimeBeforeClassStartsToNotifyWaitListUsers.valid && validationMsg}

        <Label>Minutes to delay sending notifications</Label>
        <NumberInput
          value={timeDelayForSendingNotifications.value}
          onChange={(e) => {
            return setTimeDelayForSendingNotifications({
              value: e.target.value,
              valid: e.target.value > 0
            });
          }}
        />
        {!timeDelayForSendingNotifications.valid && validationMsg}
      </div>
    ) : null;

  return (
    <div className={classes.root}>
      <Label>Show wait list?</Label>
      <Select options={['yes', 'no']} value={isActiveWaitList} onChange={(e) => setActiveWaitList(e.value)} />
      {renderWaitListConfig()}
    </div>
  );
};
