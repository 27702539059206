import React from 'react';

import classes from './DrawerToggle.module.scss';

const drawerToggle = (props) => {
  let classesArray = [classes.drawerToggle];

  if (props.dark) {
    classesArray.push(classes.drawerDark);
  }

  return (
    <div className={classesArray.join(' ')} onClick={props.clicked}>
      <div />
      <div />
      <div />
    </div>
  );
};

export default drawerToggle;
