import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import Select from 'grommet/components/Select';
import { isFullAccessAdminOrTrainer, returnGymsByAccess } from '../../../shared/userFunctions';
import { menuItemsArray } from '../MenuNavigation/menuItems';

const AdminTrainerSubHeader = ({
  userData,
  locations,
  selectedGym,
  history,
  match,
  onFetchGymUsersList,
  onFetchGymDetails,
  onFetchTrainersList,
  onFetchPlansList,
  onFetchClassesTemplatesList,
  onFetchGymAdmins
}) => {
  const gymListOptions = returnGymsByAccess(locations, userData).map((value) => {
    return {
      label: value.name,
      id: value._id
    };
  });
  const [gymSelected, setGymSelected] = useState({ label: selectedGym.name, id: selectedGym._id });
  const [filteredGyms, setFilteredGyms] = useState(gymListOptions);

  const menuItems = menuItemsArray(userData, selectedGym.settings, match.url, locations, 'showInMenu');
  const page = menuItems.find((elem) => elem.link === history.location.pathname);

  useEffect(
    () => {
      const updatedGlobalState = (gymSelectedId, currentGymId) => {
        if (gymSelectedId !== currentGymId) {
          const gymId = gymSelectedId;
          onFetchGymUsersList(gymId);
          onFetchGymDetails(gymId);

          if (userData.role === 'admin') {
            if (isFullAccessAdminOrTrainer(userData)) {
              onFetchTrainersList('all');
            }
            onFetchTrainersList(gymId);
            onFetchPlansList(gymId);
            onFetchClassesTemplatesList(gymId);
            onFetchGymAdmins(gymId);
          }
        }
      };
      updatedGlobalState(gymSelected.id, selectedGym._id);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [gymSelected.id, selectedGym._id]
  );

  const onSearch = (event) =>
    setFilteredGyms(
      gymListOptions.filter((elem) => elem.label.toLowerCase().includes(event.target.value.toLowerCase()))
    );

  const onChange = (event) => {
    setGymSelected(event.option);
    const currentPath = window.location.pathname.split(selectedGym._id)[1];
    history.push(`/gym/${event.option.id}${currentPath}`);
  };

  const pageName = (
    <h1 style={{ margin: '20px 0px 0px 0px' }} data-cy="pageHeader">
      {page && page.name}
    </h1>
  );
  const gymSelector = gymListOptions.length > 1 && (
    <Select
      placeHolder={'Please select gym name'}
      options={filteredGyms}
      value={selectedGym.name}
      onSearch={onSearch}
      onChange={onChange}
      style={{ margin: '10px 0px' }}
    />
  );

  return (
    <div className="container" data-cy="selectGym">
      {gymSelector}
      {pageName}
    </div>
  );
};

export default withRouter(withAdminDashboard(AdminTrainerSubHeader));
