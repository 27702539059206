import React from 'react';
import UserDetailsIcon from 'grommet/components/icons/base/DocumentUser';
import { withRouter } from 'react-router-dom';

const RedirectToUserDetails = ({ history, clientId, gymId }) => (
  <UserDetailsIcon
    size={'large'}
    type={'status'}
    style={{ padding: '0 10px', cursor: 'pointer' }}
    onClick={() => history.push(`/gym/${gymId}/clients/${clientId}`)}
  />
);

export default withRouter(RedirectToUserDetails);
